import { FunctionComponent } from "react";
import { CreateEventFlowRespondBlockDto, EventFlowBlockDto, EventFlowRespondBlockDto, UpdateEventFlowRespondBlockDto } from "../../../api/models";
import React from "react";
import { EventFlowCheckListItemType } from "@murphy-frontend/common/enums";
import TextInputComponent from "../CheckListComponents/TextInputComponent";
import Button from "@murphy-frontend/web-core/components/Button";
import TableCellActionDropDownButton from "@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton";
import { useCreateRespondBlock, useDeleteRespondBlock, useUpdateBlock, useUpdateRespondBlock } from "../../../api/mutations";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import CheckboxInputComponent from "../CheckListComponents/CheckBoxInputComponent";
import TitleWithControls from "./TitleWithControls";
import UpdateEventFlowRespondBlock from "../CheckListComponents/UpdateEventFlowRespondBlock";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";

interface CheckListComponentProps {
    eventFlowBlock: EventFlowBlockDto;
    templateId: number;
    onUpdate: (eventFlowBlock: EventFlowBlockDto) => void;
    onDelete: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    maxRanking: number;
}

const CheckListComponent: FunctionComponent<CheckListComponentProps> = (
    {
        eventFlowBlock,
        templateId,
        onUpdate,
        onDelete,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking
    }) => {

    const { mutate: addRespondBlock, isPending: addRespondBlockIsLoading } = useCreateRespondBlock(templateId);
    const { mutate: deleteRespondBlock, isPending: deleteRespondBlockIsLoading } = useDeleteRespondBlock(templateId);
    const { mutate: updateRespondBlock, isPending: updateBlockIsPending } = useUpdateRespondBlock(templateId);
    const { openModal, closeModal } = useModal();

    const generateActionDropDownButton = () => {
        const addComponentButton = <Button>Add CheckList Component +</Button>
        return <TableCellActionDropDownButton
            component={addComponentButton}
            listItems={generateDropDownListItems()}
            columnSize="column-large"
            onClickRow={() => { }}
        />
    }

    const onClickAddComponent = (type: number, typeName: string) => {
        const title = `New ${typeName} Title`;
        const text = `New ${typeName} Component`;
        const maxRanking = eventFlowBlock.RespondBlocks.reduce((prev, current) => (prev.Ranking > current.Ranking) ? prev : current, { Ranking: 0 }).Ranking;

        const dto: CreateEventFlowRespondBlockDto = {
            BlockType: type,
            Text: text,
            Title: title,
            PlaceholderText: "Enter text here",
            EventFlowBlocksId: eventFlowBlock.Id,
            SelectGroup: 0,
            Ranking: maxRanking + 1
        }

        addRespondBlock(dto)
    }

    const generateDropDownListItems = () => {
        const ddlItems = Object.values(EventFlowCheckListItemType)
            .filter(p => p !== EventFlowCheckListItemType.Select) // SELECT is not supported yet
            .filter(value => typeof value === "number").map((componentType) => {
                // TypeScript needs to be assured that componentType is a number here.
                const typeName = EventFlowCheckListItemType[componentType as keyof typeof EventFlowCheckListItemType];
                return (
                    <li key={typeName} onClick={() => onClickAddComponent(componentType as number, typeName)}>
                        <div className="vertical-aligner">
                            <span>{typeName}</span>
                        </div>
                    </li>
                );
            });

        return ddlItems;
    };

    const onEditRespondBlock = (respondBlock: EventFlowRespondBlockDto) => {
        const updateBlockComponent = <UpdateEventFlowRespondBlock eventFlowRespondBlock={respondBlock}
            onSuccess={closeModal}
            templateId={templateId} />

        const modal: ModalConfiguration = {
            title: "Edit CheckList Item",
            body: updateBlockComponent,
            hideButton: true,
        }

        openModal(modal);
    }

    const onDeleteRespondBlock = (respondBlock: EventFlowRespondBlockDto) => {
        deleteRespondBlock(respondBlock.Id);
    }

    const onClickIncrementRespondBlockRanking = (respondBlock: EventFlowRespondBlockDto) => {
        const dto: UpdateEventFlowRespondBlockDto = {
            ...respondBlock,
            Ranking: respondBlock.Ranking + 1
        }
        updateRespondBlock(dto)
    }

    const onClickDecrementRespondBlockRanking = (respondBlock: EventFlowRespondBlockDto) => {
        const dto: UpdateEventFlowRespondBlockDto = {
            ...respondBlock,
            Ranking: respondBlock.Ranking - 1
        }
        updateRespondBlock(dto)
    }


    const getCheckListComponentBasedOnType = (respondBlock: EventFlowRespondBlockDto, maxRank: number) => {

        switch (respondBlock.BlockType) {
            case EventFlowCheckListItemType.TextInput:
                return <TextInputComponent
                    eventFlowRespondBlock={respondBlock}
                    onDelete={onDeleteRespondBlock}
                    onUpdate={onEditRespondBlock}
                    onClickDecrementRanking={onClickDecrementRespondBlockRanking}
                    onClickIncrementRanking={onClickIncrementRespondBlockRanking}
                    maxRanking={maxRank}
                />;
            case EventFlowCheckListItemType.Checkbox:
                return <CheckboxInputComponent eventFlowRespondBlock={respondBlock}
                    onDelete={onDeleteRespondBlock}
                    onUpdate={onEditRespondBlock}
                    onClickDecrementRanking={onClickDecrementRespondBlockRanking}
                    onClickIncrementRanking={onClickIncrementRespondBlockRanking}
                    maxRanking={maxRank}

                />;
            default:
                return <input type="text" />;
        }
    }

    if (addRespondBlockIsLoading || deleteRespondBlockIsLoading || updateBlockIsPending) {
        return <Spinner isGlobal />
    }

    const content = <span>
        {eventFlowBlock.Title}
    </span>;

    const maxRankRespondBlocks = eventFlowBlock.RespondBlocks ? eventFlowBlock.RespondBlocks.reduce((prev, current) => (prev.Ranking > current.Ranking) ? prev : current, { Ranking: 0 }).Ranking : 0;
    const respondBlocks = eventFlowBlock.RespondBlocks
        .sort((a, b) => { return a.Ranking - b.Ranking; })
        .map((item) => {
            return (
                <React.Fragment key={item.Id}>
                    {getCheckListComponentBasedOnType(item, maxRankRespondBlocks)}
                </React.Fragment>
            )
        }
        );

    return (
        <>
            <div className="m-spacing"></div>
            <div className="m-text">
                <TitleWithControls
                    content={content}
                    eventFlowBlock={eventFlowBlock}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRanking}
                />
            </div>
            <div className="component-add-button">
                {generateActionDropDownButton()}
            </div>
            {respondBlocks}
        </>

    );
}

export default CheckListComponent;