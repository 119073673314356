import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { toast } from 'react-toastify';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { UserTrainingSessionsApi } from './UserTrainingSessionsApi';
import { CreateCustomerUserTrainingSessionRequest, UpdateCustomerUserTrainingSessionsRequest } from './models';
import queryKeys from './queryKeys';

export const useCreateUserTrainingSession = () => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new UserTrainingSessionsApi(apiService);

  return useMutation({
    mutationFn: (createUserTrainingSessionRequest: CreateCustomerUserTrainingSessionRequest) => api.createUserTrainingSession(createUserTrainingSessionRequest),
    onError: (err) => {
      console.log(err);
      // Error handling logic here
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
    },
  });
};

export const useEditUserTrainingSession = () => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new UserTrainingSessionsApi(apiService);

  return useMutation({
    mutationFn: (editUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest) => api.updateUserTrainingSession(editUserTrainingSessionRequest),
    onError: (err) => {
      console.log(err);
      // Error handling logic here
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
    },
  });
};

export const useDeleteUserTrainingSession = (translations: Record<string, string>) => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new UserTrainingSessionsApi(apiService);

  return useMutation({
    mutationFn: (id: number) => api.deleteUserTrainingSession(id),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success(translations.usertrainingsessiondeleted);
    },
  });
};
