import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { LocalizationApi } from './LocalizationApi';
import { UpsertLocalizationDTO } from './models';

export const useUpsertLocalization = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const localizationApi = new LocalizationApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dto: UpsertLocalizationDTO) => localizationApi.upsertLocalization(dto),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            const entityId = variables.EntityId;
            const entityType = variables.EntityType;
            queryClient.invalidateQueries({ queryKey: queryKeys.list({ entityId, entityType }) });
            toast.success("Localization update suceeded");
        },
    });
};