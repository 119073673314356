
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { TrainingSessionApi, CreateTrainingSessionRequest, UpdateTrainingSessionRequest, InviteToTrainingSessionRequest } from './TrainingSessionsApi';


export const useCreateTrainingSession = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new TrainingSessionApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (createTrainingSessionRequest: CreateTrainingSessionRequest) => api.createTrainingSession(createTrainingSessionRequest),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success('Course session created');
        },
    });
};

export const useEditTrainingSession = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new TrainingSessionApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (updateTrainingSessionRequest: UpdateTrainingSessionRequest) => api.editTrainingSession(updateTrainingSessionRequest),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success('Course session edited');
        },
    });
};

export const useDeleteTrainingSession = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new TrainingSessionApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => api.deleteTrainingSession(id),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success('Course session deleted');
        },
    });
};

export const useInviteToTrainingSession = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new TrainingSessionApi(apiService);

    return useMutation({
        mutationFn: (inviteToTrainingSessionRequest: InviteToTrainingSessionRequest) => api.inviteToTrainingSession(inviteToTrainingSessionRequest),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            toast.success('Invitation sent successfully');
        },
    });
}