import React, { FunctionComponent, useEffect, useState } from 'react';
import { RiPencilFill, RiDeleteBin7Fill } from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import { createColumnGroup, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import TableCellActionDropDownButton from '@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton';
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from '@murphy-frontend/web-core/components/ColumnGroupTable/Models';
import { getLargeColSizeBasedOnScreen } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { EducatorDto } from '../../api/Educator/models';

interface EducatorsTableProps {
    educators: EducatorDto[];
    onClickEdit: (educator: EducatorDto) => void;
    onClickDelete: (educator: EducatorDto) => void;
    currentScreenSize: string;
}

const EducatorsTable: FunctionComponent<EducatorsTableProps> = ({
    educators,
    onClickEdit,
    onClickDelete,
    currentScreenSize,
}) => {
    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [maxColumns, setMaxColumns] = useState<number>(0);
    const [currentSortingColumn, setCurrentSortingColumn] = useState<string>('Name');
    const [sortedAscending, setSortedAscending] = useState<boolean>(true);

    useEffect(() => {
        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (educator: EducatorDto) => {
            const ddlItems = [];

            ddlItems.push(
                <li key="edit" onClick={() => onClickEdit(educator)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <RiPencilFill />
                        </IconContext.Provider>
                        <span>Edit</span>
                    </div>
                </li>
            );

            ddlItems.push(
                <li key="delete" onClick={() => onClickDelete(educator)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <RiDeleteBin7Fill />
                        </IconContext.Provider>
                        <span>Delete</span>
                    </div>
                </li>
            );

            return ddlItems;
        };

        const createCustomerGroupColumnGroup = (educatorsArray: EducatorDto[]) => {
            const customerGroupNameColumns: ColumnGroupColumnCell[] = [
                {
                    columnKey: 'Name',
                    displayName: "Name",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'Email',
                    displayName: "Email",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
            ];

            const customerGroupNameRows: ColumnGroupRowCell[][] = educatorsArray.map((row) => [
                {
                    id: `name-${row.Id}`,
                    rowId: row.Id,
                    title: row.Name,
                    displayValue: (
                        <TableCellActionDropDownButton
                            displayText={row.Name}
                            listItems={generateDropDownListItems(row)}
                            columnSize="column-large"
                            onClickRow={() => onClickEdit(row)}
                        />
                    ),
                    columnKey: 'Name',
                },
                {
                    id: `email-${row.Id}`,
                    rowId: row.Id,
                    title: row.Email,
                    displayValue: row.Email,
                    columnKey: 'Email',
                },
            ]);

            const customerGroupNameColumnGroup = createColumnGroup(
                "EDUCATORS",
                customerGroupNameColumns,
                customerGroupNameRows
            );

            return customerGroupNameColumnGroup;
        };

        if (educators) {
            const allColumnGroups = [];
            const EducatorsCopy = [...educators];

            if (currentSortingColumn) {
                EducatorsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const customerGroupNameColGroup = createCustomerGroupColumnGroup(EducatorsCopy);
            allColumnGroups.push(customerGroupNameColGroup);

            setMaxColumns(customerGroupNameColGroup.columns.length);
            setColumnGroups(allColumnGroups);
        }
    }, [educators, sortedAscending, currentSortingColumn, currentScreenSize, onClickEdit, onClickDelete]);

    return (
        <ColumnGroupTable
            maxColumns={maxColumns}
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
        />
    );
};

export default EducatorsTable;
