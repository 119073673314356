import { useQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { CustomersApi } from './CustomersApi';

export function useGetCustomers(enabled: boolean, retry: boolean) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const customerApi = new CustomersApi(apiService);
  return useQuery({
    queryKey: queryKeys.details(),
    queryFn: () => customerApi.getCustomers(),
    enabled: enabled && customerApi != null,
    retry: retry && customerApi != null ? 3 : false
  });
}

export function useGetCustomersBasic(enabled: boolean, retry: boolean) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const customerApi = new CustomersApi(apiService);
  return useQuery({
    queryKey: queryKeys.lists(),
    queryFn: () => customerApi.getCustomersBasic(),
    enabled: enabled && customerApi != null,
    retry: retry && customerApi != null ? 3 : false
  });
}

export function useGetCustomer(id: string | null | undefined) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const customerApi = new CustomersApi(apiService);
  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => customerApi.getCustomer(id),
    staleTime: 60 * 60 * 1000
  });
}
