
import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import PersistenceType, { IPersistenceService, LocalStorageKeys } from "@murphy-frontend/common/interfaces/IPersistenceService";
import { useAuth as useOidcAuth } from "react-oidc-context";
import { useAuth } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import { BasicCustomerDto } from "../features/CustomerAdmin/api/models";
import { useGetCustomersBasic } from "../features/CustomerAdmin/api/queries";

interface CustomerContextType {
    customer: BasicCustomerDto,
    customers: BasicCustomerDto[],
    selectedCustomerId: string,
    setSelectedCustomerId: (customerId: string) => void,
}

const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export function CustomerProvider({ children }: { children: React.ReactNode }) {
    const [customer, setCustomer] = useState<BasicCustomerDto>();
    const [selectedCustomerId, setSelCustomerId] = useState<string>();

    const navigate = useNavigate();
    const auth = useAuth();
    const oidcAuth = useOidcAuth();

    const persistenceService = useInjection<IPersistenceService>(PersistenceType.IPersistenceService);

    const onChangeSelectedCustomerId = (newSelectedCustomerId: string) => {
        if (newSelectedCustomerId) {
            setSelCustomerId(newSelectedCustomerId);
            persistenceService.setSelectedCustomerId(newSelectedCustomerId);
            // navigate('/portal', { replace: true })
        }
    };

    const updateSelectedCustomerId = () => {
        const initialSelectedCustomerId = persistenceService.getSelectedCustomerId();
        if (initialSelectedCustomerId) {
            setSelCustomerId(initialSelectedCustomerId);
        }
    };

    const {
        isLoading: isGetCustomersLoading,
        isError: isGetCustomersError,
        data: customersData,
        error: getCustomersError,
    } = useGetCustomersBasic(!!oidcAuth.user && !oidcAuth.isLoading && oidcAuth.isAuthenticated, false);

    useEffect(() => {
        if (isGetCustomersError === true) {
            if (getCustomersError) {
                auth.signOut();
            }
        }
    }, [auth, getCustomersError, isGetCustomersError])

    useEffect(() => {
        if (isGetCustomersLoading === false && customersData) {
            if (selectedCustomerId && customersData.map((p) => p.Id).includes(selectedCustomerId)) {
                const matchingCustomer1 = customersData.find((p) => p.Id === selectedCustomerId);
                if (matchingCustomer1) {
                    setCustomer(matchingCustomer1);
                } else {
                    setCustomer(customersData[0]);
                }
            }
            else {
                const matchingCustomer2 = customersData[0];
                persistenceService.setSelectedCustomerId(matchingCustomer2.Id);
                setCustomer(matchingCustomer2);
            }
        }

        return () => {
            setCustomer(undefined);
        }
    }, [isGetCustomersLoading, customersData, selectedCustomerId, persistenceService]);

    useEffect(() => {
        updateSelectedCustomerId();

        // Respond to the `storage` event
        function storageEventHandler(event: any) {
            if (event.key === LocalStorageKeys.SelectedCustomerId) {
                updateSelectedCustomerId();
            }
        }
        // Hook up the event handler
        window.addEventListener('storage', storageEventHandler);
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener('storage', storageEventHandler);
        };
    }, []);

    const value = {
        customer: customer,
        customers: customersData || [],
        selectedCustomerId,
        setSelectedCustomerId: onChangeSelectedCustomerId,
    };
    return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useCustomer = () => {
    const context = useContext(CustomerContext);
    if (!context) throw new Error("CustomerContext Provider not found");
    return context as CustomerContextType;
}