import { FunctionComponent } from "react";
import { useCreateCustomerFileTypeRequirement } from "../api/mutations";
import CustomerFileTypeRequirementForm, { CustomerFileTypeRequirementFormDto } from "./CustomerFileTypeRequirementForm";
import { CreateCustomerFileTypeRequirementDTO, Discriminators } from "../api/models";
import { useGetCustomerFileTypeRequiredRequirement } from "../api/queries";

interface UpsertRequirementProps {
    onSuccess: () => void,
    requirementBlockId: number,
    requirementId?: number,
}

const UpsertCustomerFileTypeRequirement: FunctionComponent<UpsertRequirementProps> = (
    { requirementBlockId, requirementId, onSuccess }
) => {
    const { mutate: createRequirement, isPending: createIsLoading } = useCreateCustomerFileTypeRequirement();
    const { data, isLoading } = useGetCustomerFileTypeRequiredRequirement(requirementId);

    const onSubmitHandler = (dto: CustomerFileTypeRequirementFormDto) => {

        const reqBlockRequest: CreateCustomerFileTypeRequirementDTO = {
            RequirementBlockId: requirementBlockId,
            CustomerFileTypeId: dto.CustomerFileTypeId,
            Name: (new Date()).toString(),
            IsPeriodic: false,
            RequirementStandardId: 1,
            Discriminator: dto.Type === 'reviewed' ? Discriminators.CustomerFileTypeReviewedRequirement : Discriminators.CustomerFileTypeRequiredRequirement,
            Description: ""
        }

        createRequirement(reqBlockRequest, {
            onSuccess: onSuccess
        });
    }

    if (requirementId && requirementId > 0 && isLoading) return <div>Loading...</div>

    const requirement = data ? {
        CustomerFileTypeId: data?.CustomerFileTypeId ?? 0,
        Type: data?.Discriminator === Discriminators.CustomerFileTypeRequiredRequirement ? 'required' : 'reviewed',
    } : undefined;

    return (<div>
        <CustomerFileTypeRequirementForm
            isLoading={createIsLoading}
            onSubmit={onSubmitHandler}
            requirement={requirement}
        />
    </div>);
}

export default UpsertCustomerFileTypeRequirement;