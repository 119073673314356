import { FunctionComponent } from "react";
import LocalizationForm, { LocalizationFormDto } from "./LocalizationForm";
import { useUpsertLocalization } from "../api/mutations";
import { UpsertLocalizationDTO } from "../api/models";
import HtmlLocalizationForm from "./HtmlLocalizationForm";

interface UpsertLocalizationProps {
    entityType: string,
    entityId: number,
    entityFieldName: string,
    languageId: number,
    currentLocalizationValue?: string,
    isHtml?: boolean,
}

const UpsertLocalization: FunctionComponent<UpsertLocalizationProps> = (
    {
        entityType,
        entityId,
        entityFieldName,
        languageId,
        currentLocalizationValue,
        isHtml,
    }
) => {

    const { mutate: upsertLocalization, isPending } = useUpsertLocalization();

    const onSubmitHandler = (localization: LocalizationFormDto) => {

        const dto: UpsertLocalizationDTO = {
            EntityType: entityType,
            EntityId: entityId,
            FieldName: entityFieldName,
            LanguageId: languageId,
            LocalizationValue: localization.Value || "",
            IsHtml: isHtml || false,
        };

        upsertLocalization(dto, {
            onSuccess: () => {
                // onSuccess();
            }
        });
    }

    const currentLocalization: LocalizationFormDto | undefined = currentLocalizationValue ? {
        Value: currentLocalizationValue,
    } : undefined;

    return (<div>
        {isHtml ?
            <HtmlLocalizationForm
                isLoading={isPending}
                onSubmit={onSubmitHandler}
                currentLocalization={currentLocalization}
            /> :
            <LocalizationForm
                isLoading={isPending}
                onSubmit={onSubmitHandler}
                currentLocalization={currentLocalization}
            />}
    </div>);
}

export default UpsertLocalization;