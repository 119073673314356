import Button from "@murphy-frontend/web-core/components/Button";
import { RequirementCategories } from "@murphy-frontend/web-core/constants";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LessonList from "../../OnlineEducation/components/LessonList";
import { useGetBaseLessons } from "../../OnlineEducation/api/queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import { CreateBaseLessonDTO } from "../../OnlineEducation/api/models";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import { useCreateBaseLesson } from "../../OnlineEducation/api/mutations";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import LocalizedLessonList from "../../OnlineEducation/components/LocalizedLessonList";
import FieldLocalizationList from "../../LocalizationAdmin/components/FieldLocalizationList";
import { IconContext } from "react-icons/lib";
import { IoLanguage } from "react-icons/io5";

interface EditRequirementCategoryPageProps {

}

const EditRequirementCategoryPage: FunctionComponent<EditRequirementCategoryPageProps> = () => {

    const { openModal } = useModal();

    const navigate = useNavigate();
    const { category } = useParams<{ category: string }>();
    const cat = category ? parseInt(category, 10) : 0;

    const currentCategory = RequirementCategories.find(c => c.id === cat);
    const { data: lessons, isLoading: lessonsIsLoading } = useGetBaseLessons("RequirementCategory", cat);
    const { mutate: createBaseLesson, isPending: createBaseLessonIsLoading } = useCreateBaseLesson();

    const onClickBack = () => {
        navigate(-1);
    };

    const onClickAddLesson = () => {
        const modalConf: ModalConfiguration = {
            title: 'Add lesson?',
            body: <p>
                Are you sure you want to add a new lesson to this requirement category?
            </p>,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
            okCallback: () => {
                const baseLessonRequest: CreateBaseLessonDTO = {
                    EntityId: cat,
                    EntityType: "RequirementCategory"
                }
                createBaseLesson(baseLessonRequest);
            }
        };
        openModal(modalConf);
    }

    const onClickEditLesson = (baseLessonId: number) => {
        const localizedLessonList = <LocalizedLessonList
            baseLessonId={baseLessonId}
        />;

        const modalConf: ModalConfiguration = {
            title: `Administrate lesson ${baseLessonId}`,
            body: localizedLessonList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const onClickDeleteLesson = (baseLessonId: number) => {
        const modalConf: ModalConfiguration = {
            title: 'Delete lesson?',
            body: <p>
                Are you sure you want to delete this lesson?
            </p>,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
            okCallback: () => {
                // deleteBaseLesson(baseLessonId);
            }
        };
        openModal(modalConf);
    }

    const onClickDescriptionTranslation = (e) => {
        e.preventDefault();

        const localizationList = <FieldLocalizationList
            fieldName={"Description"}
            entityName={"RequirementCategory"}
            entityId={cat}
            isHtml={true} />;

        const modalConf: ModalConfiguration = {
            title: 'Translations for Description',
            body: localizationList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const descriptionTranslationButton =
        <div className="vertical-aligner btn-no-width gap-10">
            <div><b>Edit Description</b></div>
            <Button isIconButton icon={
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoLanguage />
                </IconContext.Provider>
            } onClick={onClickDescriptionTranslation}>
            </Button>
        </div>

    const onClickNameTranslation = (e) => {
        e.preventDefault();

        const localizationList = <FieldLocalizationList
            fieldName={"Name"}
            entityName={"RequirementCategory"}
            entityId={cat} />;

        const modalConf: ModalConfiguration = {
            title: 'Translations for Name',
            body: localizationList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const nameTranslationButton =
        <div className="vertical-aligner btn-no-width gap-10">
            <div><b>Edit Name</b></div>
            <Button isIconButton icon={
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoLanguage />
                </IconContext.Provider>
            } onClick={onClickNameTranslation}>
            </Button>
        </div>

    if (lessonsIsLoading || createBaseLessonIsLoading)
        return <div className="spinner-container-global-center"><Spinner /></div>;

    return (<div className="generalwrapper padding-bottom-60">
        <section className="header-container vertical-aligner">
            <div className="one">
                <h4>Requirement Category: {currentCategory?.value}</h4>
            </div>
            <div className="two controls-container">
                <div>
                    <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                </div>
            </div>
            <div className="three" />
        </section>
        <section className="container-with-background">
            <div className="form-container">
                <div className="req-flex-container">
                    <div className="generalwrapper">
                        {nameTranslationButton}
                        {descriptionTranslationButton}
                    </div>

                    <div className="margin-botton-20">
                        <LessonList
                            lessons={lessons}
                            onClickAddLesson={onClickAddLesson}
                            onClickEditLesson={onClickEditLesson}
                            onClickDeleteLesson={onClickDeleteLesson}
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>);

}

export default EditRequirementCategoryPage;