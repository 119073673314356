import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import { useAuth as useOidcAuth } from "react-oidc-context";
import { useGetMe } from "../features/UserAdmin/api/queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import { useAuth } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import { UserInfoModel } from "../features/UserAdmin/api/models";

interface UserContextProps {

}

interface UserContextType {
    isError: boolean,
    user: UserInfoModel,
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: React.ReactNode }) {
    const auth = useAuth();
    const oidcAuth = useOidcAuth();
    const [triggerFetch, setTriggerFetch] = useState(!!oidcAuth.user?.profile.sub);

    // useEffect to watch oidcAuth.user changes
    useEffect(() => {
        if (oidcAuth.user?.profile.sub) {
            setTriggerFetch(prev => !prev); // toggle to trigger re-fetch
        }
    }, [oidcAuth.user, oidcAuth.isLoading, oidcAuth.isAuthenticated, auth]);

    // Modify useGetMe to depend on the triggerFetch state
    const { data: me, isLoading, isError } = useGetMe(triggerFetch);

    useEffect(() => {
        if (isError) {
            auth.signOut();
        }
    }, [auth, isError]);

    const value = {
        isError,
        user: me,
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) throw new Error("UserContext Provider not found");
    return context as UserContextType;
}
