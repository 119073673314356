import { FunctionComponent } from "react";

interface AdminPortalProps {

}

const AdminPortal: FunctionComponent<AdminPortalProps> = () => {
    return (
        <div className="pbs-grid">
            <div>
                <h2>Welcome to the Admin Portal</h2>
            </div>
        </div>
    );
}

export default AdminPortal;