import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export interface TrainingModel {
    Id: number,
    TrainingTypeId: number,
    Name: string,
    Description: string,
    ImageUrl: string,
    HtmlDescription: string,
    TrainingLanguageId?: number,
    CustomerId?: string,
    MaxAttendees?: number,
    PublicSector?: boolean,
    Educator?: string;
}

export interface CreateTrainingRequest {
    TrainingTypeId: number,
    Name: string,
    Description: string,
    HtmlDescription: string,
    CustomerId?: string,
    Language?: number,
    MaxAttendees?: number,
    Sector?: number,
}

export interface UpdateTrainingRequest {
    Id: number,
    TrainingTypeId?: number,
    Name?: string,
    Description?: string,
    HtmlDescription?: string,
    Language?: number,
    MaxAttendees?: number,
    CustomerId?: string,
    Sector?: number,
}

export class TrainingApi {

    constructor(
        private apiService: IApiService,
    ) { }


    getAllTrainings = async (): Promise<TrainingModel[]> => {
        const url = 'api/Training';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getTraining = async (id: number): Promise<TrainingModel> => {
        const url = `api/Training/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data
    };

    createTraining = (createTrainingRequest: CreateTrainingRequest) => {
        const url = 'api/Training';
        return this.apiService.callApi(url, 'POST', createTrainingRequest);
    };

    deleteTraining = (id: number) => {
        const url = `api/Training/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    editTraining = (updateTrainingRequest: UpdateTrainingRequest) => {
        const url = `api/Training/${updateTrainingRequest.Id}`;
        return this.apiService.callApi(url, 'PUT', updateTrainingRequest);
    };
}