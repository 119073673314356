import { FunctionComponent } from "react";
import { useCustomer } from "../../contexts/CustomerContext";
import { useGetTemplates } from "./api/queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import Button from "@murphy-frontend/web-core/components/Button";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { EventFlowTemplateDto } from "./api/models";
import EventFlowTemplatesTable from "./components/EventFlowTemplatesTable";
import { useDeleteTemplate } from "./api/mutations";

interface EventFlowTemplatesProps {

}

const EventFlowTemplates: FunctionComponent<EventFlowTemplatesProps> = () => {
    const { customer } = useCustomer();
    const { currentSize } = useWindowDimensions();
    const navigate = useNavigate();

    const { data: templates, isLoading: templatesIsLoading } = useGetTemplates(customer?.Id);
    const { mutate: deleteTemplate, isPending: deleteIsLoading } = useDeleteTemplate(customer?.Id);

    const onClickNew = () => {
        navigate('/eventflowtemplates/new');
    };

    const onClickEdit = (template: EventFlowTemplateDto) => {
        const url = `/eventflowtemplates/${template.Id}`;
        navigate(url);
    };

    const onClickEditConfiguration = (template: EventFlowTemplateDto) => {
        const url = `/eventflowtemplates/${template.Id}/config`;
        navigate(url);
    }

    const onClickDelete = (template: EventFlowTemplateDto) => {
        deleteTemplate(template.Id);
        navigate('/eventflowtemplates');
    };

    if (templatesIsLoading || deleteIsLoading) {
        return (<Spinner isGlobal />);
    }

    return (
        <div className="generalwrapper">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>
                        TEMPLATES
                    </h4>
                </div>
                <div className="two">
                    <Button
                        isIconButton
                        buttonStyle="btn--add"
                        buttonSize="btn-with-icon"
                        onClick={onClickNew}
                    >
                        <span className="btn-with-icon-text">
                            New Template
                        </span>
                    </Button>
                </div>
            </section>
            <section className="user-table-container">
                <EventFlowTemplatesTable
                    templates={templates ?? []}
                    onClickEdit={onClickEdit}
                    onClickEditConfiguration={onClickEditConfiguration}
                    onClickDelete={onClickDelete}
                    currentScreenSize={currentSize}
                />
            </section>
        </div>
    );
}

export default EventFlowTemplates;