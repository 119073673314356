import React, { FunctionComponent, useEffect, useState } from 'react';
import { RiPencilFill, RiDeleteBin7Fill } from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import { createColumnGroup, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import TableCellActionDropDownButton from '@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton';
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from '@murphy-frontend/web-core/components/ColumnGroupTable/Models';
import { getLargeColSizeBasedOnScreen } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { CustomerGroupModel } from '../api/CustomerGroupsApi';

interface CustomerGroupsTableProps {
    customerGroups: CustomerGroupModel[];
    onClickEditCustomerGroup: (customerGroup: CustomerGroupModel) => void;
    onClickDeleteCustomerGroup: (customerGroup: CustomerGroupModel) => void;
    currentScreenSize: string;
}

const CustomerGroupsTable: FunctionComponent<CustomerGroupsTableProps> = ({
    customerGroups,
    onClickEditCustomerGroup,
    onClickDeleteCustomerGroup,
    currentScreenSize,
}) => {
    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [maxColumns, setMaxColumns] = useState<number>(0);
    const [currentSortingColumn, setCurrentSortingColumn] = useState<string>('Name');
    const [sortedAscending, setSortedAscending] = useState<boolean>(true);

    useEffect(() => {
        const createCustomerGroupColumnGroup = (customerGroupsArray: CustomerGroupModel[]) => {
            const customerGroupNameColumns: ColumnGroupColumnCell[] = [
                {
                    columnKey: 'Name',
                    displayName: "Name",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
            ];

            const customerGroupNameRows: ColumnGroupRowCell[][] = customerGroupsArray.map((row) => [
                {
                    id: `name-${row.ID}`,
                    rowId: row.ID,
                    title: row.Name,
                    displayValue: (
                        <TableCellActionDropDownButton
                            displayText={row.Name}
                            listItems={generateDropDownListItems(row)}
                            columnSize="column-large"
                            onClickRow={() => onClickEditCustomerGroup(row)}
                        />
                    ),
                    columnKey: 'Name',
                },
            ]);

            const customerGroupNameColumnGroup = createColumnGroup(
                "CUSTOMER GROUPS",
                customerGroupNameColumns,
                customerGroupNameRows
            );

            return customerGroupNameColumnGroup;
        };

        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (customerGroup: CustomerGroupModel) => {
            const ddlItems = [];

            ddlItems.push(
                <li key="edit" onClick={() => onClickEditCustomerGroup(customerGroup)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <RiPencilFill />
                        </IconContext.Provider>
                        <span>Edit</span>
                    </div>
                </li>
            );

            ddlItems.push(
                <li key="delete" onClick={() => onClickDeleteCustomerGroup(customerGroup)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <RiDeleteBin7Fill />
                        </IconContext.Provider>
                        <span>Delete</span>
                    </div>
                </li>
            );
            return ddlItems;
        };

        if (customerGroups) {
            const allColumnGroups = [];
            const customerGroupsCopy = [...customerGroups];

            if (currentSortingColumn) {
                customerGroupsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const customerGroupNameColGroup = createCustomerGroupColumnGroup(customerGroupsCopy);
            allColumnGroups.push(customerGroupNameColGroup);

            setMaxColumns(customerGroupNameColGroup.columns.length);
            setColumnGroups(allColumnGroups);
        }
    }, [customerGroups, sortedAscending, currentSortingColumn, currentScreenSize, onClickEditCustomerGroup, onClickDeleteCustomerGroup]);

    return (
        <ColumnGroupTable
            maxColumns={maxColumns}
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
        />
    );
};

export default CustomerGroupsTable;
