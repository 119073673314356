import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { EventFlowTemplatesApi } from './EventFlowTemplatesApi';
import { CreateEventFlowBlockDto, CreateEventFlowRespondBlockDto, CreateEventFlowTabDto, CreateEventFlowTemplateDto, CreateSendAlertActionDto, UpdateEventFlowBlockDto, UpdateEventFlowRespondBlockDto, UpdateEventFlowTabDto, UpdateEventFlowTemplateDto, UpdateSendAlertActionDto } from './models';

export function useCreateTemplate(customerId: string) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (createTemplate: CreateEventFlowTemplateDto) => templateApi.createTemplate(createTemplate),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
            toast.success("Template created");
        },
    });
}

export function useUpdateTemplate(customerId: string) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (updateTemplate: UpdateEventFlowTemplateDto) => templateApi.updateTemplate(updateTemplate),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
            toast.success("Template updated");
        },
    });
}

export function useDeleteTemplate(customerId: string) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (id: number) => templateApi.deleteTemplate(id),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
            toast.success("Template deleted");
        },
    });
}

export function useCreateTab(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (createTab: CreateEventFlowTabDto) => templateApi.createTab(createTab),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useUpdateTab(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (updateTab: UpdateEventFlowTabDto) => templateApi.updateTab(updateTab),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useDeleteTab(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (id: number) => templateApi.deleteTab(id),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useCreateBlock(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (createBlock: CreateEventFlowBlockDto) => templateApi.createBlock(createBlock),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useUpdateBlock(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (updateBlock: UpdateEventFlowBlockDto) => templateApi.updateBlock(updateBlock),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useDeleteBlock(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (id: number) => templateApi.deleteBlock(id),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useCreateRespondBlock(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (createBlock: CreateEventFlowRespondBlockDto) => templateApi.createRespondBlock(createBlock),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useUpdateRespondBlock(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (updateBlock: UpdateEventFlowRespondBlockDto) => templateApi.updateRespondBlock(updateBlock),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useDeleteRespondBlock(templateId: number) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (id: number) => templateApi.deleteRespondBlock(id),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(templateId) });
        },
    });
}

export function useCreateSendAlertAction() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (createBlock: CreateSendAlertActionDto) => templateApi.createSendAlertAction(createBlock),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(variables.EventFlowTemplateId) });
        },
    });
}

export function useUpdateSendAlertAction() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useMutation({
        mutationFn: (updateBlock: UpdateSendAlertActionDto) => templateApi.updateSendAlertAction(updateBlock),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.detail(variables.EventFlowTemplateId) });
        },
    });
}