import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '@murphy-frontend/web-core/components/Input';
import Button from '@murphy-frontend/web-core/components/Button';
import Textarea from '@murphy-frontend/web-core/components/TextArea';
import CommunicationTypeFilter from "@murphy-frontend/web-core/components/CommunicationTypeFilter";
import { MBSRecipient } from '@murphy-frontend/web-core/features/Alert/models';
import MBSSearchRecipients from '@murphy-frontend/web-core/features/Alert/components/MBSSearchRecipients';
import MBSSelectedRecipients from '@murphy-frontend/web-core/features/Alert/components/MBSSelectedRecipients';

const schema = yup.object().shape({
    Name: yup.string().required(),
    Subject: yup.string().nullable().notRequired(),
    Body: yup.string().nullable().notRequired(),
    Question: yup.string().nullable().notRequired(),
    ResponseAlternatives: yup.array().nullable().notRequired(),
    CriticalAlertEnabled: yup.boolean().nullable().notRequired(),
    GeoLocationEnabled: yup.boolean().nullable().notRequired(),
    EventFlowEnabled: yup.boolean().nullable().notRequired(),
    Enabled: yup.boolean().nullable().notRequired(),
    IncludeSenderEmailInBody: yup.boolean().nullable().notRequired(),
    IncludeSenderUserNameInBody: yup.boolean().nullable().notRequired(),
});

export interface AlertTemplateFormDto {
    Name: string;
    Subject: string;
    Body: string;
    Question: string;
    ResponseRequired: boolean;
    ResponseAlternatives: string[];
    CriticalAlertEnabled: boolean;
    GeoLocationEnabled: boolean;
    SMSEnabled?: boolean,
    EmailEnabled?: boolean,
    PushNotificationEnabled?: boolean,
    EventFlowEnabled: boolean,
    Enabled: boolean,
    IncludeSenderEmailInBody: boolean,
    IncludeSenderUserNameInBody: boolean,
}

interface AlertTemplateFormProps {
    isLoading?: boolean;
    alertTemplate?: AlertTemplateFormDto;
    onSubmit: (data: AlertTemplateFormDto) => void;
    selectedRecipients: MBSRecipient[];
    notSelectedRecipients: MBSRecipient[];
    addRecipient: (recipient: MBSRecipient) => void;
    removeRecipient: (recipient: MBSRecipient) => void;
    onClickRecipient: (recipient: MBSRecipient) => void;
}

const AlertTemplateForm: React.FC<AlertTemplateFormProps> = ({
    isLoading,
    alertTemplate,
    onSubmit,
    selectedRecipients,
    notSelectedRecipients,
    addRecipient,
    removeRecipient,
    onClickRecipient,
}) => {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        register,
        reset,
        setValue,
    } = useForm<AlertTemplateFormDto>({
        resolver: yupResolver(schema),
        defaultValues: {
            Name: '',
        },
    });

    const [mobileAllChecked, setMobileAllChecked] = useState(false);
    const [emailAllChecked, setEmailAllChecked] = useState(false);
    const [notificationAllChecked, setNotificationAllChecked] = useState(false);

    const watchResponseRequired = watch('ResponseRequired', alertTemplate?.ResponseRequired ?? false);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "ResponseAlternatives"
    });

    useEffect(() => {
        if (alertTemplate) {
            reset(alertTemplate);  // Resets the form with new default values
            // if (alertTemplate.ResponseAlternatives && alertTemplate.ResponseAlternatives.length > 0) {
            //     alertTemplate.ResponseAlternatives.forEach(alt => append(alt));
            // }
            setEmailAllChecked(alertTemplate.EmailEnabled ?? false);
            setMobileAllChecked(alertTemplate.SMSEnabled ?? false);
            setNotificationAllChecked(alertTemplate.PushNotificationEnabled ?? false);
        }
    }, [alertTemplate, reset, append]);

    const onSubmitHandler = (data: AlertTemplateFormDto) => {
        data.EmailEnabled = emailAllChecked;
        data.SMSEnabled = mobileAllChecked;
        data.PushNotificationEnabled = notificationAllChecked;

        onSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)} className="alert-template-form">
            <div className="row">
                <div className="column">
                    <div className="form-section">
                        <Input
                            isControlled
                            register={register}
                            name="Name"
                            placeholder="Name"
                            title="Name"
                            errorMessage={errors.Name?.message}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="Enabled"
                            title="Enabled"
                            errorMessage={errors.Enabled?.message}
                        />

                        <label>Message Modes</label>
                        <div>
                            <CommunicationTypeFilter
                                onCheckAllMobile={() => setMobileAllChecked(!mobileAllChecked)}
                                onCheckAllEmail={() => setEmailAllChecked(!emailAllChecked)}
                                onCheckAllNotification={() =>
                                    setNotificationAllChecked(!notificationAllChecked)
                                }
                                checkAllMobileChecked={mobileAllChecked}
                                checkAllEmailChecked={emailAllChecked}
                                checkAllNotificationChecked={notificationAllChecked}
                                smsDisabled={false}
                                size="small"
                            />
                        </div>

                        <Input
                            isControlled
                            register={register}
                            name="Subject"
                            placeholder="Subject"
                            title="Subject"
                            errorMessage={errors.Subject?.message}
                        />
                        <Textarea
                            isControlled
                            register={register}
                            name="Body"
                            placeholder="Body"
                            title="Body"
                            errorMessage={errors.Body?.message}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="ResponseRequired"
                            title="Response Required"
                            errorMessage={errors.ResponseRequired?.message}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="IncludeSenderEmailInBody"
                            title="Include Sender Email In Body"
                            errorMessage={errors.IncludeSenderEmailInBody?.message}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="IncludeSenderUserNameInBody"
                            title="Include Sender UserName In Body"
                            errorMessage={errors.IncludeSenderUserNameInBody?.message}
                        />

                        {
                            watchResponseRequired &&
                            <><Textarea
                                isControlled
                                register={register}
                                name="Question"
                                placeholder="Question"
                                title="Question"
                                errorMessage={errors.Question?.message}
                            />

                                <div>
                                    <div className="response-alternative-label-container">
                                        <label>Response Alternatives</label>
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                append('');
                                            }
                                            }
                                            isIconButton
                                            buttonStyle="btn--add"
                                        />
                                    </div>

                                    <ul>
                                        {fields.map((item, index) => {
                                            return (
                                                <li key={item.id}>
                                                    <div className="response-alternative-container">
                                                        <Controller
                                                            control={control}
                                                            name={`ResponseAlternatives.${index}`}
                                                            render={({ field }) =>
                                                            (
                                                                <Input
                                                                    handleChange={(e) => field.onChange(e.target.value)}
                                                                    val={field.value}
                                                                    name="Alternative"
                                                                    placeholder="Alternative"
                                                                />
                                                            )}
                                                        />
                                                        <Button
                                                            buttonStyle="btn--remove"
                                                            isIconButton
                                                            isNegativeButton
                                                            onClick={() => remove(index)}>
                                                        </Button>
                                                    </div>

                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div className="margin-top">

                                    </div>
                                </div>
                            </>}

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="CriticalAlertEnabled"
                            title="Critical Alert Enabled"
                            errorMessage={errors.CriticalAlertEnabled?.message}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="GeoLocationEnabled"
                            title="GeoLocation Enabled"
                            errorMessage={errors.GeoLocationEnabled?.message}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="EventFlowEnabled"
                            title="Event Flow Enabled"
                            errorMessage={errors.EventFlowEnabled?.message}
                        />

                    </div>
                </div>
                <div className="column">
                    <div className="form-section">
                        <div className="alert-template-column">
                            <div className="contacts-container">
                                <div className="mbs-users-container">
                                    <div className="form-section-header">
                                        Selected Recipients
                                    </div>
                                    <div className="horizontal-aligner">
                                        {selectedRecipients && selectedRecipients.length > 0 && (
                                            <MBSSelectedRecipients
                                                recipients={selectedRecipients}
                                                removeRecipient={removeRecipient}
                                                clickRecipient={onClickRecipient}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="mbs-users-container">
                                    <div className="form-section-header">
                                        Search Recipients
                                    </div>
                                    <div className="horizontal-aligner">
                                        <MBSSearchRecipients
                                            recipients={notSelectedRecipients}
                                            addRecipient={addRecipient}
                                            clickRecipient={onClickRecipient}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <div className="form-section">
                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AlertTemplateForm;
