import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '@murphy-frontend/web-core/components/Input';
import Button from '@murphy-frontend/web-core/components/Button';
import Textarea from '@murphy-frontend/web-core/components/TextArea';

const EventFlowBlockSchema = yup.object().shape({
    Title: yup.string().nullable().notRequired(),
    TextValue: yup.string().nullable().notRequired(),
});

export interface EventFlowBlockFormDto {
    Title: string;
    TextValue: string;
}

interface EventFlowBlockFormProps {
    isLoading?: boolean;
    eventFlowBlock?: EventFlowBlockFormDto;
    onSubmit: (data: EventFlowBlockFormDto) => void;
    visibleFields: string[];
}

const EventFlowBlockForm: React.FC<EventFlowBlockFormProps> = ({
    isLoading,
    eventFlowBlock,
    onSubmit,
    visibleFields,
}) => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
    } = useForm<EventFlowBlockFormDto>({
        resolver: yupResolver(EventFlowBlockSchema),
    });

    useEffect(() => {
        if (eventFlowBlock) {
            reset(eventFlowBlock);
        }
    }, [eventFlowBlock, reset]);

    const onSubmitHandler = (data: EventFlowBlockFormDto) => {
        onSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section">
                        {visibleFields?.includes("Title") && <Input
                            isControlled
                            register={register}
                            name="Title"
                            placeholder="Title"
                            title="Title"
                            errorMessage={errors.Title?.message}
                        />}
                        {visibleFields?.includes("TextValue") && <Textarea
                            isControlled
                            register={register}
                            name="TextValue"
                            placeholder="Text"
                            title="Text"
                            rows={10}
                            errorMessage={errors.TextValue?.message}
                        />}
                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EventFlowBlockForm;
