import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { AxiosResponse } from "axios";
import { BaseLessonDTO, Chapters, CourseModel, CreateBaseLessonDTO, CreateLocalizedLessonDTO, LocalizedLessonDTO, UpdateLocalizedLessonDTO } from "./models";

export class OnlineEducationApi {
    constructor(
        private apiService: IApiService,
    ) { }

    getBaseLessons = async (entityType: string, entityId: number): Promise<BaseLessonDTO[]> => {
        const url = `api/BaseLesson/${entityType}/${entityId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createBaseLesson = async (dto: CreateBaseLessonDTO): Promise<AxiosResponse> => {
        const url = `api/BaseLesson`;
        const { data } = await this.apiService.callApi(url, 'POST', dto);
        return data;
    }

    deleteBaseLesson = async (baseLessonId: number): Promise<AxiosResponse> => {
        const url = `api/BaseLesson/${baseLessonId}`;
        const { data } = await this.apiService.callApi(url, 'DELETE');
        return data;
    }

    getLocalizedLessons = async (baseLessonId: number): Promise<LocalizedLessonDTO[]> => {
        const url = `api/BaseLesson/${baseLessonId}/LocalizedLesson`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createLocalizedLesson = async (createLocalizedLessonDTO: CreateLocalizedLessonDTO): Promise<AxiosResponse> => {
        const url = `api/BaseLesson/${createLocalizedLessonDTO.BaseLessonId}/LocalizedLesson`;
        const { data } = await this.apiService.callApi(url, 'POST', createLocalizedLessonDTO);
        return data;
    }

    updateLocalizedLesson = async (dto: UpdateLocalizedLessonDTO): Promise<AxiosResponse> => {
        const url = `api/BaseLesson/${dto.BaseLessonId}/LocalizedLesson/${dto.Id}`;
        const { data } = await this.apiService.callApi(url, 'PUT', dto);
        return data;
    }

    getCourses = async (): Promise<CourseModel[]> => {
        const url = `api/Course`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getCourseChapters = async (courseId: number): Promise<Chapters[]> => {
        const url = `api/Chapter?courseId=${courseId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getChapterLessons = async (chapterId: number): Promise<Chapters[]> => {
        const url = `api/Lesson?chapterId=${chapterId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }
}