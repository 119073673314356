import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '@murphy-frontend/web-core/components/Input';
import Button from '@murphy-frontend/web-core/components/Button';
import ControlledFormSelect from '@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect';
import Textarea from '@murphy-frontend/web-core/components/TextArea';

const EventFlowTemplateSchema = yup.object().shape({
    Name: yup.string().required(),
    Description: yup.string().required(),
    Type: yup.number().required(),
    GeoLocationEnabled: yup.boolean().required(),
    ConfirmBeforeStart: yup.boolean().required(),
    isPermissionActive: yup.boolean().required(),
});

export interface EventFlowTemplateFormDto {
    Name: string;
    Description: string;
    Type: number;
    GeoLocationEnabled: boolean,
    ConfirmBeforeStart: boolean,
    AlertTemplateId?: number,
    isPermissionActive: boolean;
}

interface EventFlowTemplateFormProps {
    isLoading?: boolean;
    eventFlowTemplate?: EventFlowTemplateFormDto;
    onSubmit: (data: EventFlowTemplateFormDto) => void;
    alertTemplateOptions: { value: number; label: string }[];
}

const EventFlowTemplateForm: React.FC<EventFlowTemplateFormProps> = ({
    isLoading,
    eventFlowTemplate,
    onSubmit,
    alertTemplateOptions
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        reset,
        setValue,
    } = useForm<EventFlowTemplateFormDto>({
        resolver: yupResolver(EventFlowTemplateSchema),
        defaultValues: {
            Name: '',
            Type: 1,
            ConfirmBeforeStart: true,
            isPermissionActive: false,
        },
    });

    useEffect(() => {
        if (eventFlowTemplate) {
            reset(eventFlowTemplate);
        }
    }, [eventFlowTemplate, reset]);

    const onSubmitHandler = (data: EventFlowTemplateFormDto) => {
        onSubmit(data);
    };

    const colors = [
        { value: 1, label: 'Red' },
        { value: 2, label: 'Blue' },
        { value: 3, label: 'Green' },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section">
                        <Input
                            isControlled
                            register={register}
                            name="Name"
                            placeholder="Name"
                            title="Name"
                            errorMessage={errors.Name?.message}
                        />
                        <Textarea
                            isControlled
                            register={register}
                            name="Description"
                            placeholder="Description"
                            title="Description"
                            errorMessage={errors.Description?.message}
                        />
                        <ControlledFormSelect
                            control={control}
                            title="Color"
                            name="Type"
                            label="Color"
                            options={colors}
                            isMulti={false}
                        />

                        <ControlledFormSelect
                            control={control}
                            title="Alert Template On Start Event"
                            name="AlertTemplateId"
                            label="Alert Template"
                            options={alertTemplateOptions}
                            isMulti={false}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="GeoLocationEnabled"
                            title="GeoLocation Enabled"
                            errorMessage={errors.GeoLocationEnabled?.message}
                        />

                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="ConfirmBeforeStart"
                            title="Confirm Before Start"
                            errorMessage={errors.ConfirmBeforeStart?.message}
                        />
                        <Input
                            isControlled
                            direction="column"
                            register={register}
                            type="checkbox"
                            name="isPermissionActive"
                            title="Add Permissions"
                            errorMessage={errors.AddPermissions?.message}
                        />

                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EventFlowTemplateForm;
