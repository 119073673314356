import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { AxiosResponse } from "axios";
import {
    AdminCreateRequirementBlockRequest, AdminRequirementBlockDto, AdminUpdateRequirementBlockRequest,
    BaseCreateRequirementDTO, BaseRequirementDTO, BaseUpdateRequirementDTO,
    CreateCustomerFileTypeRequirementDTO, CustomerFileTypeRequiredRequirementDTO, RequirementLevelDTO, UpdateRequirementLevelDTO
} from "./models";

export class RequirementApi {
    constructor(
        private apiService: IApiService,
    ) { }

    getRequirements = async (): Promise<BaseRequirementDTO[]> => {
        const url = 'api/Requirement';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getRequirement = async (requirementId: number): Promise<BaseRequirementDTO> => {
        const url = `api/Requirement/${requirementId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getCustomerFileTypeRequiredRequirement = async (requirementId: number): Promise<CustomerFileTypeRequiredRequirementDTO> => {
        const url = `api/CustomerFileTypeRequirement/${requirementId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getRequirementsByFilter = async (blockId?: number, requirementCategoryId?: number, requirementStandardId?: number): Promise<BaseRequirementDTO[]> => {
        const queryParams = new URLSearchParams();

        if (blockId !== undefined) {
            queryParams.append('blockId', blockId.toString());
        }
        if (requirementCategoryId !== undefined) {
            queryParams.append('requirementCategoryId', requirementCategoryId.toString());
        }
        if (requirementStandardId !== undefined) {
            queryParams.append('requirementStandardId', requirementStandardId.toString());
        }

        const url = `api/Requirement?${queryParams.toString()}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createRequirement = (dto: BaseCreateRequirementDTO): Promise<AxiosResponse<any, any>> => {
        const url = 'api/Requirement';
        return this.apiService.callApi(url, 'POST', dto);
    }

    deleteRequirement = (requirementId: number): Promise<AxiosResponse<any, any>> => {
        const url = `api/Requirement/${requirementId}`;
        return this.apiService.callApi(url, 'DELETE');
    }

    updateRequirement = (dto: BaseUpdateRequirementDTO): Promise<AxiosResponse<any, any>> => {
        const url = `api/Requirement/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    }

    createCustomerFileTypeRequirement = (dto: CreateCustomerFileTypeRequirementDTO): Promise<AxiosResponse<any, any>> => {
        const url = 'api/CustomerFileTypeRequirement';
        return this.apiService.callApi(url, 'POST', dto);
    }

    createRequirementBlock = (dto: AdminCreateRequirementBlockRequest): Promise<AxiosResponse<any, any>> => {
        const url = 'api/RequirementBlock';
        return this.apiService.callApi(url, 'POST', dto);
    }

    getRequirementBlocks = async (requirementCategoryId: number): Promise<AdminRequirementBlockDto[]> => {
        const queryParams = new URLSearchParams();

        if (requirementCategoryId !== undefined) {
            queryParams.append('requirementCategoryId', requirementCategoryId.toString());
        }

        const url = `api/RequirementBlock?${queryParams.toString()}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getRequirementBlock = async (blockId: number): Promise<AdminRequirementBlockDto> => {
        const url = `api/RequirementBlock/${blockId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateRequirementBlock = (dto: AdminUpdateRequirementBlockRequest): Promise<AxiosResponse<any, any>> => {
        const url = `api/RequirementBlock/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    }

    deleteRequirementBlock = (blockId: number): Promise<AxiosResponse<any, any>> => {
        const url = `api/RequirementBlock/${blockId}`;
        return this.apiService.callApi(url, 'DELETE');
    }

    getRequirementLevels = async (): Promise<RequirementLevelDTO[]> => {
        const url = 'api/RequirementLevel';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getRequirementLevel = async (level: number): Promise<RequirementLevelDTO> => {
        const url = `api/RequirementLevel/${level}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateRequirementLevel = (dto: UpdateRequirementLevelDTO): Promise<AxiosResponse<any, any>> => {
        const url = `api/RequirementLevel/${dto.Level}`;
        return this.apiService.callApi(url, 'PUT', dto);
    }
}