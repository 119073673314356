import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { CreateTrainingRequest, TrainingApi, UpdateTrainingRequest } from './TrainingApi';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export const useCreateTraining = () => {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createTrainingRequest: CreateTrainingRequest) => api.createTraining(createTrainingRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success('Course created');
    },
  });
};

export const useEditTraining = () => {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateTrainingRequest: UpdateTrainingRequest) => api.editTraining(updateTrainingRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success('Course edited');
    },
  });
};

export const useDeleteTraining = () => {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => api.deleteTraining(id),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success('Course deleted');
    },
  });
};

