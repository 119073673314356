import { FunctionComponent } from "react";
import ExpandableRow from '@murphy-frontend/web-core/components/DashboardTable/ExpandableRow';
import Button from "@murphy-frontend/web-core/components/Button";
import { BaseLessonDTO } from "../api/models";

interface lessonListRowProps {
    lesson: BaseLessonDTO;
    onClickEditLesson?: (baseLessonId: number) => void;
    onClickDeleteLesson?: (baseLessonId: number) => void;
}

const lessonListRow: FunctionComponent<lessonListRowProps> = (
    { lesson, onClickEditLesson, onClickDeleteLesson }
) => {

    const header = <>
        <div className="dashboard-table-row-text">
            <b>{lesson.Id}</b>&nbsp;
        </div>
        <div className="right-icon">
            {/* {icon} */}
        </div>
    </>

    const handleClickEdit = () => {
        if (onClickEditLesson) {
            onClickEditLesson(lesson.Id);
        }
    }

    const handleClickDelete = () => {
        if (onClickDeleteLesson) {
            onClickDeleteLesson(lesson.Id);
        }
    }

    const expandedContent = <div className="formatted-card">
        <>
            <p>
                <strong>Id:</strong>&nbsp; {lesson.Id}
            </p>
            <br />
            <div className='card-footer'>
                <div className='left-footer-container'>

                </div>
                <div className='right-footer-container'>
                    <>
                        <Button
                            isNegativeButton
                            onClick={handleClickDelete}>
                            Delete
                        </Button>
                        <Button
                            onClick={handleClickEdit}>
                            Edit
                        </Button>
                    </>
                </div>
            </div>
        </>
    </div >

    return (<>
        <ExpandableRow
            headerContent={header}
            expandedContent={expandedContent} />
    </>);
}

export default lessonListRow;