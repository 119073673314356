import { FunctionComponent } from "react";
import { useCreateLocalizedLesson, useUpdateLocalizedLesson } from "../api/mutations";
import { CourseModel, CreateLocalizedLessonDTO, LocalizedLessonDTO, UpdateLocalizedLessonDTO } from "../api/models";
import LocalizedLessonForm, { LocalizedLessonFormDto } from "./LocalizedLessonForm";
import { useGetChapterLessons, useGetCourseChapters } from "../api/queries";

interface UpsertLocalizedLessonProps {
    baseLessonId: number,
    languageId: number,
    currentLocalizedLesson?: LocalizedLessonDTO,
    courses: CourseModel[],
    selectedCourseId: number | null,
    selectedChapterId: number | null,
    setSelectedCourseId: (id: number) => void,
    setSelectedChapterId: (id: number) => void,
}

const UpsertLocalization: FunctionComponent<UpsertLocalizedLessonProps> = (
    {
        baseLessonId,
        languageId,
        currentLocalizedLesson,
        courses,
        selectedCourseId,
        selectedChapterId,
        setSelectedCourseId,
        setSelectedChapterId,
    }
) => {

    const { mutate: createLocalization, isPending: createIsLoading } = useCreateLocalizedLesson();
    const { mutate: updateLocalization, isPending: updateIsLoading } = useUpdateLocalizedLesson();

    const { data: courseChapters, isLoading: courseChaptersIsLoading } = useGetCourseChapters(selectedCourseId);
    const { data: chapterLessons, isLoading: chapterLessonsIsLoading } = useGetChapterLessons(selectedChapterId);

    const onSubmitHandler = (localizedLesson: LocalizedLessonFormDto) => {

        if (!currentLocalizedLesson) {
            const dto: CreateLocalizedLessonDTO = {
                ExtLessonId: localizedLesson.ExtLessonId,
                ExtLessonUrl: localizedLesson.ExtLessonUrl,
                BaseLessonId: baseLessonId,
                LanguageId: languageId,
            };
            createLocalization(dto);
        } else {
            const dto: UpdateLocalizedLessonDTO = {
                Id: currentLocalizedLesson.Id,
                ExtLessonId: localizedLesson.ExtLessonId,
                ExtLessonUrl: localizedLesson.ExtLessonUrl,
                BaseLessonId: baseLessonId,
            };
            updateLocalization(dto);
        }
    }

    if (createIsLoading || chapterLessonsIsLoading || courseChaptersIsLoading || updateIsLoading) {
        return <div>Loading...</div>
    }

    const currentLocalizedLessonFormDto: LocalizedLessonFormDto = currentLocalizedLesson ? {
        ExtLessonId: currentLocalizedLesson.ExtLessonId,
        ExtLessonUrl: currentLocalizedLesson.ExtLessonUrl,
    } : undefined;

    return (<div>
        <LocalizedLessonForm
            isLoading={createIsLoading || updateIsLoading}
            onSubmit={onSubmitHandler}
            currentLocalizedLesson={currentLocalizedLessonFormDto}
            courses={courses}
            selectedCourseChapters={courseChapters}
            selectedChapterLessons={chapterLessons}
            setSelectedCourseId={setSelectedCourseId}
            setSelectedChapterId={setSelectedChapterId}
            selectedCourseId={selectedCourseId}
            selectedChapterId={selectedChapterId}
        />
    </div>);
}

export default UpsertLocalization;