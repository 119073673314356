import { emailIsValid } from "@murphy-frontend/common/validation";
import Button from "@murphy-frontend/web-core/components/Button";
import Input from "@murphy-frontend/web-core/components/Input";
import { FunctionComponent, useState } from "react";

interface EmailInvitationFormProps {
    onSubmit: (email: string) => void
}

const EmailInvitationForm: FunctionComponent<EmailInvitationFormProps> = ({ onSubmit }) => {
    const [email, setEmail] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const onChangeEmail = (event) => {
        event.preventDefault();

        const newVal = event.target.value;
        if (!newVal) {
            setEmailErrorMessage('');
        } else if (!emailIsValid(newVal)) {
            setEmailErrorMessage('Invalid email');
        } else {
            setEmailErrorMessage('');
        }
        setEmail(newVal);
    }

    return (
        <div className='margin-10-all-children'>
            <Input handleChange={onChangeEmail} type="email" placeholder="Email" errorMessage={emailErrorMessage} />
            <div className='pull-right'>
                <Button type="submit" onClick={() => onSubmit(email)}>Send invite</Button>
            </div>
        </div>
    );
}

export default EmailInvitationForm;
