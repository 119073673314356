import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { CreateCustomerUserTrainingSessionRequest, UpdateCustomerUserTrainingSessionsRequest } from "./models";

export class UserTrainingSessionsApi {

    constructor(
        private apiService: IApiService,
    ) { }

    getUserTrainingSessionsByCustomerUserId = async (customerUserId: string) => {
        const url = `api/UserTrainingSession?customerUserId=${customerUserId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getUserTrainingSessionsByTrainingSessionId = async (trainingSessionId: number) => {
        const url = `api/UserTrainingSession?trainingSessionId=${trainingSessionId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateUserTrainingSession = (updateUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest) => {
        const url = `api/UserTrainingSession/${updateUserTrainingSessionRequest.Id}`;
        return this.apiService.callApi(url, 'PUT', updateUserTrainingSessionRequest);
    }

    createUserTrainingSession = (createUserTrainingSessionRequest: CreateCustomerUserTrainingSessionRequest) => {
        const url = 'api/UserTrainingSession';
        return this.apiService.callApi(url, 'POST', createUserTrainingSessionRequest);
    }

    deleteUserTrainingSession = (id: number) => {
        const url = `api/UserTrainingSession/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    }
}