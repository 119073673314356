import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export interface OptionModel {
    ID: number,
    Value: string,
    OptionSetId: number,
    OptionSetName: string,
}

export interface OptionSetModel {
    ID: number,
    Name: string,
    CustomerId: string,
    EnabledForContacts: boolean,
    Options: OptionModel[],
}

export interface SelectedOptionSetViewModel {
    id: number,
    value: any,
    optionSetId: number,
    optionSetName: string,
}

export interface OptionViewModel {
    id: number,
    value: any,
}

export interface OptionSetViewModel {
    name: string,
    id: number,
    options: OptionViewModel[],
}

export interface ContactModel {
    ID?: number,
    uniqueKey?: string,
    Name: string,
    Email?: string,
    MobileNr?: string,
    DeviceToken?: string,
    CustomerId: string,
    type?: string,
    SelectedOptions?: OptionModel[],
}

export interface ContactViewModel {
    id: number,
    name: string,
    email?: string,
    mobile?: string,
    selectedOptions?: SelectedOptionSetViewModel[],
}

export class ContactsApi {
    apiService: IApiService;

    constructor(apiService: IApiService) {
        this.apiService = apiService;
    }

    getContacts = async (customerId?: string, contactGroupId?: number) => {

        const queryParams = new URLSearchParams();

        if (customerId) {
            queryParams.append('customerId', customerId);
        }
        if (contactGroupId) {
            queryParams.append('contactGroupId', contactGroupId.toString());
        }

        const url = `/api/Contact?${queryParams.toString()}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getContactsByContactGrpId = async (contactGroupId: number) => {
        const url = `/api/Contact?contactGroupId=${contactGroupId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };


    getCustomerContactsOptionSets = (customerId: string) => {
        const url = `/api/OptionSet?customerId=${customerId}&isContactsEnabled=true`;
        return this.apiService.callApi(url, 'GET');
    };

}