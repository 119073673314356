import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { RequirementApi } from './RequirementApi';
import { AdminCreateRequirementBlockRequest, AdminUpdateRequirementBlockRequest, BaseCreateRequirementDTO, BaseUpdateRequirementDTO, CreateCustomerFileTypeRequirementDTO, UpdateRequirementLevelDTO } from './models';

export function useCreateRequirement() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerFileApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (createRequirement: BaseCreateRequirementDTO) => customerFileApi.createRequirement(createRequirement),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("Requirement created");
        },
    });
}

export function useCreateCustomerFileTypeRequirement() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerFileApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (createRequirement: CreateCustomerFileTypeRequirementDTO) => customerFileApi.createCustomerFileTypeRequirement(createRequirement),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("CustomerFileTypeRequirement created");
        },
    });
}

export function useCreateRequirementBlock() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (createBlockReq: AdminCreateRequirementBlockRequest) => requirementApi.createRequirementBlock(createBlockReq),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.blocks(variables.RequirementCategoryId) });
            toast.success("Requirement Block created");
        },
    });
}

export function useUpdateRequirement() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (updateReq: BaseUpdateRequirementDTO) => requirementApi.updateRequirement(updateReq),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("Requirement updated");
        },
    });
}

export function useDeleteRequirement() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (requirementId: number) => requirementApi.deleteRequirement(requirementId),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("Requirement deleted");
        },
    });
}

export function useUpdateRequirementBlock(requirementCategoryId: number | undefined) {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (updateBlockReq: AdminUpdateRequirementBlockRequest) => requirementApi.updateRequirementBlock(updateBlockReq),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.block(variables.Id) });
            queryClient.invalidateQueries({ queryKey: queryKeys.blocks(requirementCategoryId) });
            toast.success("Requirement Block updated");
        },
    });
}

export function useDeleteRequirementBlock() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (blockId: number) => requirementApi.deleteRequirementBlock(blockId),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("Requirement Block deleted");
        },
    });
}

export function useUpdateRequirementLevel() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (updateReq: UpdateRequirementLevelDTO) => requirementApi.updateRequirementLevel(updateReq),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.levels() });
            toast.success("Requirement level updated");
        },
    });
}
