import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import { useCustomer } from '../../../contexts/CustomerContext';
import { useCreateUserMutation } from '../api/mutations';
import { CreateUserRequest } from '../api/models';
import UserForm from '@murphy-frontend/web-core/features/UsersAdmin/components/UserForm';
import { products } from '@murphy-frontend/web-core/constants';
import { Constant } from '@murphy-frontend/web-core/models/Constant';

const AddUser: FunctionComponent = () => {
  const navigate = useNavigate();
  const { customer } = useCustomer();
  const [customerProducts, setCustomerProducts] = useState<Constant[]>([]);

  const onClickBack = () => {
    navigate(-1);
  };

  const { mutate: createMutate, isPending: isLoading } = useCreateUserMutation();

  const onSubmitHandler = (createData: any) => {
    const createUserRequest: CreateUserRequest = {
      CustomerId: customer.Id,
    };

    if (createData.selectedproductids) {
      createUserRequest.InitialProducts = createData.selectedproductids;
    }

    if (createData.phonenumber) {
      createUserRequest.Phonenumber = createData.phonenumber;
    }

    if (createData.email) {
      createUserRequest.Email = createData.email;
    }

    if (createData.username) {
      createUserRequest.Username = createData.username;
    }

    if (createData.role >= 0) {
      createUserRequest.Role = createData.role;
    }

    if (Object.keys(createUserRequest).length > 0) {
      createMutate(createUserRequest, {
        onSuccess: () => {
          navigate(-1);
        },
      });
    }
  };

  useEffect(() => {
    if (customer?.ActiveProductIds) {
      const customerProducts = products.filter((p) => customer.ActiveProductIds.includes(p.id));
      setCustomerProducts(customerProducts);
    }
  }, [customer]);

  return (
    <div className="generalwrapper padding-bottom-60">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            NEW USER
          </h4>
        </div>
        <div className="two">
          <div>
            <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
          </div>
        </div>
        <div className="three" />
      </section>
      <section className="container-with-background">
        <div className="form-container">
          <UserForm
            allProducts={customerProducts}
            showResetPassword={false}
            onSubmit={onSubmitHandler}
            isSetPermissionsReadonly={false}
            isSetRoleReadonly={false}
            isLoading={isLoading}
            isUpsertUserEnabled={true}
            isUserNameDisabled={false}
            isEmailDisabled={false}
            isPhoneNumberDisabled={false}
          />
        </div>
      </section>
    </div>
  );
}

export default AddUser;
