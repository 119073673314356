import { UserFormViewModel } from "@murphy-frontend/web-core/features/UsersAdmin/components/UserForm";
import { MurphyUserAdmin } from "./api/models";

export const mapUserViewModel = (user: MurphyUserAdmin): UserFormViewModel => {
    return {
        id: user.CustomerUserId,
        username: user.Username,
        email: user.Email,
        phonenumber: user.Phonenumber,
        role: user.Role,
        permissions: user.Permissions,
        userTrainingSessions: user.UserTrainingSessions,
        deviceToken: user.DeviceToken,
    }
}