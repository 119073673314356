import { FunctionComponent } from "react";
import ExpandableRow from '@murphy-frontend/web-core/components/DashboardTable/ExpandableRow';
import { BaseRequirementDTO, Discriminators } from "../api/models";
import Button from "@murphy-frontend/web-core/components/Button";
import { RequirementStandards } from "@murphy-frontend/web-core/constants";

interface RequirementListRowProps {
    requirement: BaseRequirementDTO;
    onClickEditRequirement?: (requirementId: number) => void;
    onClickDeleteRequirement?: (requirementId: number) => void;
}

const RequirementListRow: FunctionComponent<RequirementListRowProps> = (
    { requirement, onClickEditRequirement, onClickDeleteRequirement }
) => {

    const header = <>
        <div className="dashboard-table-row-text">
            <b>{requirement.Discriminator}</b>&nbsp;
        </div>
        <div className="right-icon">
            {/* {icon} */}
        </div>
    </>

    const handleClickEdit = () => {
        if (onClickEditRequirement) {
            onClickEditRequirement(requirement.Id);
        }
    }

    const handleClickDelete = () => {
        if (onClickDeleteRequirement) {
            onClickDeleteRequirement(requirement.Id);
        }
    }

    const expandedContent = <div className="formatted-card">
        <>
            <p>
                <strong>Name:</strong>&nbsp; {requirement.Name}
            </p>
            {requirement.Description && <p>
                <strong>Description:</strong>&nbsp; {requirement.Description}
            </p>}
            <p>
                <strong>Standard:</strong>&nbsp; {RequirementStandards.find(p => p.id == requirement.RequirementStandardId)?.value}
            </p>
            <br />
            <hr></hr>
            <br />
            <div className='card-footer'>
                <div className='left-footer-container'>

                </div>
                <div className='right-footer-container'>
                    {requirement.Discriminator == Discriminators.BasicTypeRequirement &&
                        <>
                            <Button
                                isNegativeButton
                                onClick={handleClickDelete}>
                                Delete
                            </Button>
                            <Button
                                onClick={handleClickEdit}>
                                Edit
                            </Button>
                        </>
                    }
                    {requirement.Discriminator == Discriminators.CustomerFileTypeRequiredRequirement &&
                        <Button
                            isNegativeButton
                            onClick={handleClickDelete}>
                            Delete
                        </Button>

                    }
                    {requirement.Discriminator == Discriminators.CustomerFileTypeReviewedRequirement &&
                        <Button
                            isNegativeButton
                            onClick={handleClickDelete}>
                            Delete
                        </Button>

                    }
                </div>
            </div>
        </>
    </div >

    return (<>
        <ExpandableRow
            headerContent={header}
            expandedContent={expandedContent} />
    </>);
}

export default RequirementListRow;