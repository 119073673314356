import { FunctionComponent } from "react";
import RequirementLevelForm, { RequirementLevelFormDto } from "./RequirementLevelForm";
import {
    IoLanguage,
} from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';
import Button from "@murphy-frontend/web-core/components/Button";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import FieldLocalizationList from "../../LocalizationAdmin/components/FieldLocalizationList";
import { RequirementLevelDTO, UpdateRequirementLevelDTO } from "../api/models";
import { useUpdateRequirementLevel } from "../api/mutations";

interface UpdateRequirementLevelProps {
    onSuccess: () => void,
    requirementLevel: RequirementLevelDTO,
}

const UpdateRequirementLevel: FunctionComponent<UpdateRequirementLevelProps> = (
    { onSuccess, requirementLevel }
) => {
    const { mutate: updateRequirementLevel, isPending: updateIsLoading } = useUpdateRequirementLevel();
    const { openModal } = useModal();

    const onSubmitHandler = (dto: RequirementLevelFormDto) => {
        const updateReqLevelDto: UpdateRequirementLevelDTO = {
            Level: requirementLevel.Level,
            Name: dto.Name,
            Description: dto.Description
        }

        updateRequirementLevel(updateReqLevelDto, {
            onSuccess: onSuccess
        });

    }

    const onClickNameTranslation = (e) => {
        e.preventDefault();
        if (!requirementLevel)
            return;

        const localizationList = <FieldLocalizationList
            fieldName={"Name"}
            entityName={"RequirementLevel"}
            entityId={requirementLevel.Id} />;

        const modalConf: ModalConfiguration = {
            title: 'Translations for Name',
            body: localizationList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const onClickDescriptionTranslation = (e) => {
        e.preventDefault();
        if (!requirementLevel)
            return;

        const localizationList = <FieldLocalizationList
            fieldName={"Description"}
            entityName={"RequirementLevel"}
            entityId={requirementLevel.Id}
            isHtml={true} />;

        const modalConf: ModalConfiguration = {
            title: 'Translations for Description',
            body: localizationList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const nameTranslationButton =
        <div className="vertical-aligner btn-no-width gap-10">
            <div>Name</div>
            <Button isIconButton icon={
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoLanguage />
                </IconContext.Provider>
            } onClick={onClickNameTranslation}>
            </Button>
        </div>

    const descriptionTranslationButton =
        <div className="vertical-aligner btn-no-width gap-10">
            <div>Description</div>
            <Button isIconButton icon={
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoLanguage />
                </IconContext.Provider>
            } onClick={onClickDescriptionTranslation}>
            </Button>
        </div>


    const formDto = requirementLevel ? {
        Name: requirementLevel.Name,
        Description: requirementLevel.Description,
    } : undefined;

    return (<div>
        <RequirementLevelForm
            isLoading={updateIsLoading}
            onSubmit={onSubmitHandler}
            requirementLevel={formDto}
            nameTranslationButton={nameTranslationButton}
            descriptionTranslationButton={descriptionTranslationButton} />
    </div>);
}

export default UpdateRequirementLevel;