import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import CustomerForm, { ICustomerFormData } from '../components/CustomerForm';
import Button from '@murphy-frontend/web-core/components/Button';
import { useGetCustomers } from '../api/queries';
import { CreateCustomerRequest } from '../api/models';
import { useGetCustomerGroups } from '../../CustomerGroups/api/queries';
import { useCreateCustomer } from '../api/mutations';
import { products } from '@murphy-frontend/web-core/constants';
import { useCustomer } from '../../../contexts/CustomerContext';

const AddCustomer: FunctionComponent = () => {
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate('/customers');
  };

  const { setSelectedCustomerId } = useCustomer();

  const { mutate: createUser, isPending: createIsLoading } = useCreateCustomer();
  const { data: customerGroups, isLoading: isCustomerGroupsLoading } = useGetCustomerGroups();
  const {
    data: customers, isLoading: isCustomersLoading, isError: isCustomersError,
  } = useGetCustomers(true, true);

  const onSubmitHandler = (createData: ICustomerFormData) => {
    const createCustomerRequest: CreateCustomerRequest = {};

    if (createData.name) {
      createCustomerRequest.Name = createData.name;
    }
    if (createData.allowedusers) {
      createCustomerRequest.AllowedUsers = createData.allowedusers;
    }
    if (createData.mfa === false || createData.mfa === true) {
      createCustomerRequest.MFAEnabled = createData.mfa;
    }
    if (createData.appmfa === false || createData.appmfa === true) {
      createCustomerRequest.AppMFAEnabled = createData.appmfa;
    }
    if (createData.smssender) {
      createCustomerRequest.SMSsender = createData.smssender;
    }
    if (createData.externalcontactsenabled === false || createData.externalcontactsenabled === true) {
      createCustomerRequest.ExternalContactsEnabled = createData.externalcontactsenabled;
    }
    if (createData.activecustomerproducts) {
      createCustomerRequest.InitialProducts = createData.activecustomerproducts;
    }
    if (createData.allowedusers) {
      createCustomerRequest.AllowedUsers = createData.allowedusers;
    }
    if (createData.statustypeid) {
      createCustomerRequest.StatusTypeId = createData.statustypeid;
    }
    if (createData.customergroupid) {
      createCustomerRequest.CustomerGroupId = createData.customergroupid;
    }
    if (createData.parentcustomerid) {
      createCustomerRequest.ParentCustomerId = createData.parentcustomerid;
    }
    if (createData.isroot) {
      createCustomerRequest.SetAsHierarchyRoot = createData.isroot;
    }
    if (createData.publicSector !== undefined) {
      createCustomerRequest.PublicSector = createData.publicSector;
    }

    if (Object.keys(createCustomerRequest).length > 0) {
      createUser(createCustomerRequest, {
        onSuccess: (response) => {
          setSelectedCustomerId(response.data.Id);
          navigate('/customers');
        },
      });
    }
  };

  if (isCustomerGroupsLoading || isCustomersLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="generalwrapper padding-bottom-60">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            New license
          </h4>
        </div>
        <div className="two">
          <div>
            <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
          </div>
        </div>
        <div className="three" />
      </section>
      <section className="container-with-background">
        <div className="form-container">
          <CustomerForm
            onSubmit={onSubmitHandler}
            allProducts={products}
            isLoading={createIsLoading}
            customers={customers.filter((cust) => cust.HierarchyId) || []}
            customerGroups={customerGroups || []}
          />
        </div>
      </section>
    </div>
  );
}

export default AddCustomer;
