import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '@murphy-frontend/web-core/components/Input';
import PermissionsSelector from '@murphy-frontend/web-core/components/PermissionsSelector';
import Button from '@murphy-frontend/web-core/components/Button';
import { Products } from '@murphy-frontend/common/enums';
import { areArraysEqualSets } from '@murphy-frontend/common/utils';
import { statusTypes } from '@murphy-frontend/web-core/constants';
import OptionSet from '@murphy-frontend/web-core/components/OptionSet';
import { Constant } from '@murphy-frontend/web-core/models/Constant';
import { CustomerProduct, MurphyCustomer } from '@murphy-frontend/common/interfaces/ICustomersApi';
import { CustomerGroupModel } from '../../CustomerGroups/api/CustomerGroupsApi';
import ControlledFormSelect, { OptionType } from '@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect';

const customerSchema = yup.object().shape(
  {
    name: yup.string().min(3).required(),
    mfa: yup.boolean(),
    appmfa: yup.boolean(),
    statusTypeId: yup.number().nullable().notRequired(),
    publicSector: yup.boolean().notRequired(),
    smssender: yup
      .string()
      .nullable()
      .notRequired()
      .when('smssender', {
        is: (value) => value?.length,
        then: (rule) => rule.min(3).max(11),
      }),
    externalcontactsenabled: yup.boolean(),
    allowedusers: yup.number().min(1),

  },
  [
    // Add Cyclic deps here because when require itself
    ['smssender', 'smssender'],
  ],
);

export interface CustomerFormDto {
  id: string,
  name: string,
  allowedusers: number,
  activeusers: number,
  smssender: string,
  externalcontactsenabled: boolean,
  mfa: boolean,
  appmfa: boolean,
  activecustomerproducts: CustomerProduct[],
  statustypeid: number,
  customergroupid?: number,
  parentcustomerid?: string,
  isroot?: boolean,
  publicSector?: boolean,
}

interface CustomerFormProps {
  isLoading?: boolean;
  onSubmit: (data: ICustomerFormData) => void,
  allProducts: Constant[],
  customerToBeUpdated?: CustomerFormDto,
  customerGroups: CustomerGroupModel[];
  customers?: MurphyCustomer[];
}

export interface ICustomerFormData {
  name?: string,
  allowedusers?: number,
  activeusers?: number,
  smssender?: string,
  externalcontactsenabled?: boolean,
  mfa?: boolean,
  appmfa?: boolean,
  activecustomerproducts?: number[],
  statustypeid?: number,
  customergroupid?: number,
  parentcustomerid?: string,
  removefromhierarchy?: boolean,
  isroot?: boolean,
  publicSector?: boolean,
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  onSubmit, allProducts,
  customerToBeUpdated,
  customerGroups,
  customers,
  isLoading,
}) => {
  const {
    control, watch, register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, getValues,
  } = useForm<ICustomerFormData>({
    resolver: yupResolver(customerSchema),
    defaultValues: {
      name: '',
      mfa: false,
      appmfa: false,
      smssender: '',
      externalcontactsenabled: false,
      activecustomerproducts: [],
      allowedusers: 25,
      statustypeid: 1,
      publicSector: false,
    },
  });

  const [selectedProductIds, setSelectedProductIds] = useState<Array<number>>([]);
  const [isReallyDirty, setIsReallyDirty] = useState(false);
  const [selectedStatusTypeId, setSelectedStatusTypeId] = useState(1);
  const [selectedCustomerGroupCustomers, setSelectedCustomerGroupCustomers] = useState<OptionType[]>([]);
  const initialIsRoot = customerToBeUpdated?.isroot ?? false;
  const [isRootInitiallyTrue, setIsRootInitiallyTrue] = useState(initialIsRoot);

  const watchCustomerGroup = watch('customergroupid');
  const watchIsRoot = watch('isroot', initialIsRoot);
  const watchRemoveFromHierarchy = watch('removefromhierarchy');

  // Set 'isRootInitiallyTrue' to true if 'initialIsRoot' changes to true
  useEffect(() => {
    if (initialIsRoot) {
      setIsRootInitiallyTrue(true);
    }
  }, [initialIsRoot]);

  useEffect(() => {
    const initalProducts = getValues('activecustomerproducts') ? getValues('activecustomerproducts') : [];
    let productsDirty = false;
    if (!areArraysEqualSets(initalProducts, selectedProductIds)) {
      productsDirty = true;
    }

    const initialStatusTypeId = getValues('statustypeid') ? getValues('statustypeid') : null;
    if (initialStatusTypeId) {
      if (selectedStatusTypeId !== initialStatusTypeId) {
        productsDirty = true;
      }
    }

    setIsReallyDirty(productsDirty || (isDirty || false));
  }, [getValues, isDirty, selectedProductIds, selectedStatusTypeId]);

  useEffect(() => {
    if (customerToBeUpdated) {
      const defaults = {
        name: customerToBeUpdated?.name,
        mfa: customerToBeUpdated?.mfa,
        appmfa: customerToBeUpdated?.appmfa,
        smssender: customerToBeUpdated?.smssender,
        externalcontactsenabled: customerToBeUpdated?.externalcontactsenabled,
        activecustomerproducts: customerToBeUpdated?.activecustomerproducts?.map((p) => p.ProductId) ?? [],
        allowedusers: customerToBeUpdated?.allowedusers,
        statustypeid: customerToBeUpdated?.statustypeid,
        customergroupid: customerToBeUpdated?.customergroupid,
        parentcustomerid: customerToBeUpdated?.parentcustomerid,
        isroot: customerToBeUpdated?.isroot,
        publicSector: customerToBeUpdated?.publicSector,
      };

      setSelectedProductIds(customerToBeUpdated?.activecustomerproducts?.map((p) => p.ProductId) ?? []);
      setSelectedStatusTypeId(customerToBeUpdated?.statustypeid);
      reset(defaults);
    }
  }, [customerToBeUpdated, reset]);

  const onSubmitHandler = (data: ICustomerFormData) => {
    const allDirtyFields = Object.entries(dirtyFields).filter(([key, value]) => value === true).map((item) => item[0]);
    const dataToSubmit: ICustomerFormData = {};

    const isCreateMode = !customerToBeUpdated;
    Object.entries(data).forEach(([key, value]) => {
      if (isCreateMode) {
        dataToSubmit[key] = value;
      } else {
        const fieldIsDirty = allDirtyFields.includes(key);
        if (fieldIsDirty) {
          dataToSubmit[key] = value;
        }
      }
    });

    if (!areArraysEqualSets(data.activecustomerproducts, selectedProductIds)) {
      dataToSubmit.activecustomerproducts = selectedProductIds;
    }

    dataToSubmit.statustypeid = selectedStatusTypeId;

    onSubmit(dataToSubmit);
  };

  const onSelectProduct = (productId: number) => {
    let updatedProductIds = [...selectedProductIds];
    if (updatedProductIds.includes(productId)) {
      updatedProductIds = updatedProductIds.filter((p) => p !== productId);
    } else {
      updatedProductIds.push(productId);
    }
    setSelectedProductIds(updatedProductIds);
  };

  const onChangeStatusTypeId = (event) => {
    const parsedTypeId = Number.parseInt(event.target.value, 10);
    setSelectedStatusTypeId(parsedTypeId);
  };

  const customerGroupsOptions: OptionType[] = customerGroups.map((group) => ({
    value: group.ID,
    label: group.Name,
  }));

  useEffect(() => {
    if (watchCustomerGroup && customers) {
      const selectedGroupCustomers = customers.filter((customer) => customer.CustomerGroupId === watchCustomerGroup);
      if (selectedGroupCustomers) {
        const selectedCustomers = selectedGroupCustomers.map((customer) => ({
          value: customer.Id,
          label: customer.Name,
        }));
        setSelectedCustomerGroupCustomers(selectedCustomers);
      }
    }
  }, [watchCustomerGroup, customers]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="row">
        <div className="column">
          <div className="form-section">
            <span className="form-section-header">INFO</span>

            <Input
              isControlled
              register={register}
              name="name"
              placeholder="Name"
              isRequired
              title="Name"
              errorMessage={errors.name?.message}
            />
            <Input
              isControlled
              direction="column"
              register={register}
              type="checkbox"
              name="mfa"
              title="MFA"
              errorMessage={errors.mfa?.message}
            />
            <Input
              isControlled
              direction="column"
              register={register}
              type="checkbox"
              name="appmfa"
              title="MFA (App)"
              errorMessage={errors.appmfa?.message}
            />
            <Input
              isControlled
              register={register}
              type="number"
              name="allowedusers"
              title="Max users"
              errorMessage={errors.allowedusers?.message}
            />
            <Input
              isControlled
              direction="column"
              register={register}
              type="checkbox"
              name="publicSector"
              title="Public Sector"
              errorMessage={errors.publicSector?.message}
            />
            <OptionSet
              optionSetName={"StatusType"}
              direction="row"
              key={1}
              optionSetId={1}
              options={statusTypes}
              selectedOption={selectedStatusTypeId}
              handleChange={onChangeStatusTypeId}
            />
            <ControlledFormSelect
              control={control}
              title="License Group"
              name="customergroupid"
              label="License Group"
              options={customerGroupsOptions}
              isMulti={false}
            />
            {watchCustomerGroup && <>

              <Input
                isControlled
                disabled={isRootInitiallyTrue}
                direction="column"
                register={register}
                type="checkbox"
                name="isroot"
                title="Set as Hierarchy Root"
                errorMessage={errors.isroot?.message}
              />
              {watchIsRoot === false && watchRemoveFromHierarchy === false && <ControlledFormSelect
                control={control}
                title="Parent Customer"
                name="parentcustomerid"
                label="Parent Customer"
                options={selectedCustomerGroupCustomers}
                isMulti={false}
              />
              }

              {watchIsRoot === false && <Input isControlled
                register={register}
                name="removefromhierarchy"
                type="checkbox"
                direction="column"
                title="Remove from Hierarchy"
                errorMessage={errors.removefromhierarchy?.message}
              />

              }
            </>}

          </div>
        </div>
        <div className="column">
          <div className="form-section">
            <span className="form-section-header">PERMISSIONS</span>
            <div>
              <PermissionsSelector
                isReadonly={false}
                allProducts={allProducts}
                selectedProductIds={selectedProductIds}
                onSelectProduct={onSelectProduct}
              />
            </div>

          </div>

        </div>

      </div>
      {selectedProductIds && selectedProductIds.includes(Products.MBS) && (
        <div className="row">
          <div className="column">
            <div className="form-section">
              <span className="form-section-header">ALERT</span>
              <Input
                isControlled
                register={register}
                name="smssender"
                placeholder="Sender"
                title="Sender"
                errorMessage={errors.smssender?.message}
              />
              <Input
                isControlled
                direction="column"
                register={register}
                type="checkbox"
                name="externalcontactsenabled"
                title="Contacts"
                errorMessage={errors.externalcontactsenabled?.message}
              />
            </div>
          </div>

        </div>
      )}
      <div className="row">
        <div className="column">
          <div className="form-section">
            <Button isLoading={isLoading} disabled={!isReallyDirty || (errors && errors.length > 0)} type="submit" buttonStyle="btn--primary" buttonSize="btn--medium">Save</Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CustomerForm;
