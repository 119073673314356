import { FunctionComponent, useState } from "react";
import TitleWithControls from "./TitleWithControls";
import { EventFlowBlockDto } from "../../../api/models";
import { IconContext } from "react-icons";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

interface AccordionComponentProps {
    eventFlowBlock: EventFlowBlockDto;
    onUpdate: (eventFlowBlock: EventFlowBlockDto) => void;
    onDelete: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    maxRanking: number;
}

const AccordionComponent: FunctionComponent<AccordionComponentProps> = (
    {
        eventFlowBlock,
        onUpdate,
        onDelete,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking
    }
) => {

    const [showDropDown, setShowDropDown] = useState(false);

    const onPressHandler = () => {
        setShowDropDown(!showDropDown);
    };

    const content = <span>
        {eventFlowBlock.Title}
    </span>;

    return (<div className="m-spacing drone-checklist-item-card">
        <div onClick={onPressHandler} className="drone-accordion-button-container">
            <div className="drone-accordion-button-title">
                <TitleWithControls
                    content={content}
                    eventFlowBlock={eventFlowBlock}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRanking}
                />
            </div>
            {showDropDown === true ? (

                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoChevronUp />
                </IconContext.Provider>
            ) : (
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoChevronDown />
                </IconContext.Provider>
            )}

        </div>
        {showDropDown && <div className="drone-accordion-button-title">{eventFlowBlock.TextValue}</div>}
    </div>);
}

export default AccordionComponent;