import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { CreateEducatorDto, EducatorDto, UpdateEducatorDto } from "./models";

export class EducatorApi {
    constructor(
        private apiService: IApiService,
    ) { }

    getEducators = async (): Promise<EducatorDto[]> => {
        const url = 'api/Educator';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getEducator = async (id: number): Promise<EducatorDto> => {
        const url = `api/Educator/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createEducator = (educator: CreateEducatorDto) => {
        const url = 'api/Educator';
        return this.apiService.callApi(url, 'POST', educator);
    }

    updateEducator = (dto: UpdateEducatorDto) => {
        const url = `api/Educator/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    }
}