import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import UpdateUser from '../components/UpdateUser';
import Button from '@murphy-frontend/web-core/components/Button';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { mapUserViewModel } from '../mappers';
import { useCustomer } from '../../../contexts/CustomerContext';
import { useDeleteUserMutation } from '../api/mutations';
import { useGetUser } from '../api/queries';
import { Constant } from '@murphy-frontend/web-core/models/Constant';
import { products } from '@murphy-frontend/web-core/constants';

const EditUser: FunctionComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { openModal } = useModal();

  const { customer } = useCustomer();
  const { mutate: mutateDel, isPending: deleteUserIsLoading } = useDeleteUserMutation();
  const [customerProducts, setCustomerProducts] = useState<Constant[]>([]);

  const {
    isLoading: isLoadingUser, isError: isErrorUser, data: dataUser, error: errorUser,
  } = useGetUser(id);

  const onClickNewUser = () => {
    navigate('/usernew');
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onSubmitDeleteUser = (customerUserId: string) => {
    mutateDel(customerUserId, {
      onSuccess: () => {
        navigate('/users');
      },
    });
  };

  const onUpdateSuccess = () => {
    navigate('/users');
  };

  const DeleteConfirmationBody = () => (
    <>
      <p>
        Delete
        {' '}
        <strong>{dataUser.Username}</strong>
        {' '}
        ?
      </p>
    </>
  );

  const onClickDeleteUser = () => {
    const modalConf: ModalConfiguration = {
      title: `Delete user`,
      body: <DeleteConfirmationBody />,
      hideButton: false,
      buttonText: "Delete",
      type: 'warning',

      okCallback: () => onSubmitDeleteUser(id),
    };

    openModal(modalConf);
  };

  useEffect(() => {
    if (customer?.ActiveProductIds) {
      const customerProducts = products.filter((p) => customer.ActiveProductIds.includes(p.id));
      setCustomerProducts(customerProducts);
    }
  }, [customer]);

  if (!dataUser || !customer || isLoadingUser || deleteUserIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  const userViewModel = mapUserViewModel(dataUser);

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            EDIT USER
          </h4>
        </div>
        <div className="two controls-container">
          <>
            <div>
              <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
            </div>
            <div>
              {<Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewUser} />}
            </div>
            <div>
              {<Button isIconButton isNegativeButton buttonStyle="btn--remove" onClick={onClickDeleteUser} buttonSize="btn-with-icon" />}
            </div>
          </>
        </div>
        <div className="three" />
      </section>
      <UpdateUser
        isSetRoleReadonly={false}
        isSetPermissionsReadonly={false}
        allProducts={customerProducts}
        user={userViewModel}
        customerId={customer.Id}
        showResetPassword={false}
        successCallback={onUpdateSuccess}
        timeZone={'1'} />
    </div>
  );
}

export default EditUser;
