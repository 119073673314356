const queryKeys = {
  all: ['contactgroups'],
  lists: () => [...queryKeys.all, 'list'],
  list: (customerId: string) => [...queryKeys.lists(), customerId],
  details: () => [...queryKeys.all, 'detail'],
  detail: (id: number) => [...queryKeys.details(), id],
  allUsers: () => [...queryKeys.all, 'users'],
  users: (id: number) => [...queryKeys.allUsers(), id],
  allContacts: () => [...queryKeys.all, 'contacts'],
  contacts: (id: number) => [...queryKeys.allContacts(), id],
};

export default queryKeys;