import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UpsertRequirementBlock from "../components/UpsertRequirementBlock";
import { useGetRequirementBlock, useGetRequirements } from "../api/queries";
import Button from "@murphy-frontend/web-core/components/Button";
import RequirementList from "../components/RequirementList";
import { useDeleteRequirement, useDeleteRequirementBlock } from "../api/mutations";
import LessonList from "../../OnlineEducation/components/LessonList";
import { useGetBaseLessons } from "../../OnlineEducation/api/queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import { useCreateBaseLesson, useDeleteBaseLesson } from "../../OnlineEducation/api/mutations";
import { CreateBaseLessonDTO } from "../../OnlineEducation/api/models";
import LocalizedLessonList from "../../OnlineEducation/components/LocalizedLessonList";

interface EditBlockPageProps {

}

const EditBlockPage: FunctionComponent<EditBlockPageProps> = () => {
    const navigate = useNavigate();
    const { requirementBlockId } = useParams<{ requirementBlockId: string }>();
    const blockId = requirementBlockId ? parseInt(requirementBlockId, 10) : 0;

    const { openModal } = useModal();

    const { data, isLoading } = useGetRequirementBlock(blockId);
    const { data: lessons, isLoading: lessonsIsLoading } = useGetBaseLessons("RequirementBlock", blockId);
    const { data: requirements, isLoading: requirementsIsLoading } = useGetRequirements(blockId);

    const { mutate: deleteRequirement, isPending: deleteIsLoading } = useDeleteRequirement();
    const { mutate: deleteRequirementBlock, isPending: deleteBlockIsLoading } = useDeleteRequirementBlock();

    const { mutate: createBaseLesson, isPending: createBaseLessonIsLoading } = useCreateBaseLesson();
    const { mutate: deleteBaseLesson, isPending: deleteBaseLessonIsLoading } = useDeleteBaseLesson("RequirementBlock", blockId);

    const onClickBack = () => {
        navigate(-1);
    };

    const onClickDelete = () => {
        deleteRequirementBlock(blockId, {
            onSuccess: () => {
                navigate(-1);
            }
        });
    }

    const onClickAddRequirement = () => {
        const url = `/requirements/blocks/${requirementBlockId}/reqs/new`
        navigate(url);
    };

    const onClickEditRequirement = (requirementId: number) => {
        const url = `/requirements/blocks/${requirementBlockId}/reqs/${requirementId}`
        navigate(url);
    }

    const onClickDeleteRequirement = (requirementId: number) => {
        deleteRequirement(requirementId);
    }

    const onClickAddLesson = () => {
        const modalConf: ModalConfiguration = {
            title: 'Add lesson?',
            body: <p>
                Are you sure you want to add a new lesson to this requirement block?
            </p>,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
            okCallback: () => {
                const baseLessonRequest: CreateBaseLessonDTO = {
                    EntityId: blockId,
                    EntityType: "RequirementBlock"
                }
                createBaseLesson(baseLessonRequest);
            }
        };
        openModal(modalConf);
    }

    const onClickEditLesson = (baseLessonId: number) => {
        const localizedLessonList = <LocalizedLessonList
            baseLessonId={baseLessonId}
        />;

        const modalConf: ModalConfiguration = {
            title: `Administrate lesson ${baseLessonId}`,
            body: localizedLessonList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const onClickDeleteLesson = (baseLessonId: number) => {
        const modalConf: ModalConfiguration = {
            title: 'Delete lesson?',
            body: <p>
                Are you sure you want to delete this lesson?
            </p>,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
            okCallback: () => {
                deleteBaseLesson(baseLessonId);
            }
        };
        openModal(modalConf);
    }

    const onSuccessHandler = () => {
        navigate(-1);
    };

    if (!data || isLoading || deleteIsLoading ||
        deleteBlockIsLoading || lessonsIsLoading || requirementsIsLoading ||
        createBaseLessonIsLoading || deleteBaseLessonIsLoading)
        return <div className="spinner-container-global-center"><Spinner /></div>;

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>Requirement Block: {data.Name}</h4>
                </div>
                <div className="two controls-container">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                    <div>
                        <Button isIconButton isNegativeButton buttonStyle="btn--remove" buttonSize="btn-with-icon" onClick={onClickDelete} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <div className="req-flex-container">
                        <UpsertRequirementBlock
                            onSuccess={onSuccessHandler}
                            requirementBlock={data}
                            requirementCategoryId={data.RequirementCategoryId}
                            level={data.Level}
                        />
                        <div>
                            <RequirementList
                                requirements={requirements}
                                onClickAddRequirement={onClickAddRequirement}
                                onClickEditRequirement={onClickEditRequirement}
                                onClickDeleteRequirement={onClickDeleteRequirement}
                            />
                        </div>
                        <div>
                            <LessonList
                                lessons={lessons}
                                onClickAddLesson={onClickAddLesson}
                                onClickEditLesson={onClickEditLesson}
                                onClickDeleteLesson={onClickDeleteLesson}
                            />
                        </div>
                    </div>

                </div>

            </section>
        </div>
    );
}

export default EditBlockPage;