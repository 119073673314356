import { FunctionComponent, useEffect, useState } from 'react';
import AlertTemplateForm, { AlertTemplateFormDto } from './AlertTemplateForm';
import { useCreateAlertTemplate, useUpdateAlertTemplate } from '../api/mutations';
import { CreateAlertTemplateDTO, AlertTemplateDTO, UpdateAlertTemplateDTO, AlertTemplateRecipientDTO } from '../api/models';
import { useCustomer } from '../../../contexts/CustomerContext';
import { useGetCustomerUsers } from '../../UserAdmin/api/queries';
import { useGetContacts } from '../../Contacts/api/queries';
import { useGetContactGroups } from '../../ContactGroups/api/queries';
import { MBSGroupMember, MBSRecipient, SelectedOptionSetViewModel } from '@murphy-frontend/web-core/features/Alert/models';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { MurphyUserAdmin } from '../../UserAdmin/api/models';
import { ContactModel } from '../../Contacts/api/ContactsApi';
import { ContactGroupModel } from '../../ContactGroups/api/models';

interface UpsertAlertTemplateProps {
    alertTemplate?: AlertTemplateDTO;
    onSuccess: () => void;
    customerId: string;
}

const UpsertAlertTemplate: FunctionComponent<UpsertAlertTemplateProps> = ({
    alertTemplate,
    onSuccess,
    customerId,
}) => {

    const [notSelectedRecipients, setNotSelectedRecipients] = useState<
        MBSRecipient[]
    >([]);
    const [selectedRecipients, setSelectedRecipients] = useState<MBSRecipient[]>(
        []
    );

    const { mutate: createAlertTemplate, isPending: isCreating } = useCreateAlertTemplate();
    const { mutate: updateAlertTemplate, isPending: isUpdating } = useUpdateAlertTemplate();
    const [users, setUsers] = useState<MBSRecipient[]>([]);
    const [contacts, setContacts] = useState<MBSRecipient[]>([]);
    const [contactGroups, setContactGroups] = useState<MBSRecipient[]>([]);

    const { customer } = useCustomer();
    const {
        isLoading: isLoadingUsers,
        isError: isErrorUsers,
        data: dataUsers,
        error: errorUsers,
    } = useGetCustomerUsers(customer?.Id);

    const {
        isLoading: isLoadingContacts,
        isError: isErrorContacts,
        data: dataContacts,
        error: errorContacts,
    } = useGetContacts(customer?.Id);

    const {
        isLoading: isLoadingContactGroups,
        isError: isErrorContactGroups,
        data: dataContactGroups,
        error: errorContactGroups,
    } = useGetContactGroups(customer?.Id);

    const addRecipient = (recipient: MBSRecipient): void => {
        const selectedRecipientsCopy = [...selectedRecipients, recipient];
        setSelectedRecipients(selectedRecipientsCopy);

        const notSelectedRecipientsCopy = notSelectedRecipients.filter(
            (p) => p.uniqueKey !== recipient.uniqueKey
        );
        setNotSelectedRecipients(notSelectedRecipientsCopy);
    };

    const removeRecipient = (recipient: MBSRecipient): void => {
        const notSelectedRecipientsCopy = [...notSelectedRecipients, recipient];
        setNotSelectedRecipients(notSelectedRecipientsCopy);

        const selectedRecipientsCopy = selectedRecipients.filter(
            (p) => p.uniqueKey !== recipient.uniqueKey
        );
        setSelectedRecipients(selectedRecipientsCopy);
    };

    const onClickRecipient = (recipient: MBSRecipient) => {
        //todo
    };

    const onSubmitHandler = (alertTemplateData: AlertTemplateFormDto) => {

        const recipients = selectedRecipients.map((p) => {
            const recipient: AlertTemplateRecipientDTO = {
            }

            if (p.type === 'internalgroup') {
                recipient.ContactGroupId = p.id;
            }
            else if (p.type === 'internaluser') {
                recipient.CustomerUserId = p.id;
            }
            else if (p.type === 'internalcontact') {
                recipient.ContactId = p.id;
            }

            return recipient;
        });

        if (alertTemplate) {
            const updatedAlertTemplate: UpdateAlertTemplateDTO = {
                ...alertTemplate,
                ...alertTemplateData,
                CustomerId: customerId,
                Recipients: recipients,
            };
            updateAlertTemplate(updatedAlertTemplate, {
                onSuccess: () => {
                    onSuccess();
                },
            });
        } else {
            const createAlertTemplateDTO: CreateAlertTemplateDTO = {
                ...alertTemplateData,
                CustomerId: customerId,
                Recipients: recipients,
            };
            createAlertTemplate(createAlertTemplateDTO, {
                onSuccess: () => {
                    onSuccess();
                },
            });
        }
    };

    const createUserRecipients = (data: MurphyUserAdmin[]): MBSRecipient[] => {
        const mappedUsers = data.map(
            (p) =>
            ({
                uniqueKey: `${"White"}:${p.UserId}`,
                id: p.UserId,
                name: p.Username,
                email: p.Email,
                mobile: p.Phonenumber,
                deviceToken: p.DeviceToken,
                type: "internaluser",
                isGroup: false,
                mobileChecked: true,
                emailChecked: true,
                notificationChecked: true,
                checked: true,
            } as MBSRecipient)
        );

        return mappedUsers;
    };

    const createContactRecipients = (data: ContactModel[]): MBSRecipient[] => {
        const mappedContacts = data.map(
            (p) =>
            ({
                uniqueKey: `${"internalcontact"}:${p.ID}`,
                id: p.ID,
                name: p.Name,
                email: p.Email,
                mobile: p.MobileNr,
                deviceToken: p.DeviceToken,
                type: "internalcontact",
                mobileChecked: true,
                emailChecked: true,
                notificationChecked: true,
                checked: true,
                isGroup: false,
                selectedOptions: p.SelectedOptions
                    ? p.SelectedOptions.map(
                        (q) =>
                        ({
                            id: q.ID,
                            value: q.Value,
                            optionSetId: q.OptionSetId,
                            optionSetName: q.OptionSetName,
                        } as SelectedOptionSetViewModel)
                    )
                    : [],
            } as MBSRecipient)
        );
        return mappedContacts;
    };

    const createContactGroupRecipients = (
        data: ContactGroupModel[]
    ): MBSRecipient[] => {
        const mappedGroups = data.map((p) => {
            let allGroupMembers = [];
            let mappedContacts: MBSGroupMember[] = [];
            let mappedUsers: MBSGroupMember[] = [];

            if (p.ContactGroupContacts) {
                mappedContacts = p.ContactGroupContacts.map(
                    (q) =>
                    ({
                        name: q.Name,
                        id: q.ID.toString(),
                        email: q.Email,
                        mobile: q.MobileNr,
                        deviceToken: q.DeviceToken,
                        type: "contact",
                    } as MBSGroupMember)
                );
            }

            if (p.ContactGroupUsers) {
                mappedUsers = p.ContactGroupUsers.map(
                    (q) =>
                    ({
                        name: q.UserName,
                        id: q.ID.toString(),
                        customeruserid: q.CustomerUserId.toUpperCase(),
                        email: q.Email,
                        mobile: q.MobilePhone,
                        deviceToken: q.DeviceToken,
                        type: "user",
                    } as MBSGroupMember)
                );
            }

            allGroupMembers = [...mappedUsers, ...mappedContacts];

            return {
                uniqueKey: `${"internalgroup"}:${p.ID}`,
                id: p.ID,
                name: p.Name,
                email: `${p.Name} (group)`,
                mobile: `${p.Name} (group)`,
                deviceToken: `${p.Name} (group)`,
                type: "internalgroup",
                mobileChecked: true,
                emailChecked: true,
                notificationChecked: true,
                checked: true,
                isGroup: true,
                groupMembers: allGroupMembers,
            } as MBSRecipient;
        });
        return mappedGroups;
    };

    useEffect(() => {
        if (isLoadingContactGroups === false) {
            const contactGroupRecipients =
                createContactGroupRecipients(dataContactGroups);
            setContactGroups(contactGroupRecipients);
        }
        return () => {
            setContactGroups([]);
        };
    }, [isLoadingContactGroups, dataContactGroups]);

    useEffect(() => {
        if (isLoadingUsers === false && dataUsers) {
            const userRecipients = createUserRecipients(dataUsers);
            setUsers(userRecipients);
        }
        return () => {
            setUsers([]);
        };
    }, [isLoadingUsers, dataUsers]);

    useEffect(() => {
        if (isLoadingContacts === false && dataContacts) {
            const contactRecipients = createContactRecipients(dataContacts);
            setContacts(contactRecipients);
        }
        return () => {
            setContacts([]);
        };
    }, [isLoadingContacts, dataContacts]);

    useEffect(() => {
        const selectedReps: MBSRecipient[] = [];
        if (alertTemplate?.Recipients) {
            alertTemplate.Recipients.forEach((rep) => {
                if (rep.ContactGroupId) {
                    const group = contactGroups.find((p) => p.id === rep.ContactGroupId);
                    if (group) {
                        selectedReps.push(group);
                    }
                } else if (rep.CustomerUserId) {
                    const user = users.find((p) => p.id === rep.CustomerUserId);
                    if (user) {
                        selectedReps.push(user);
                    }
                } else if (rep.ContactId) {
                    const contact = contacts.find((p) => p.id === rep.ContactId);
                    if (contact) {
                        selectedReps.push(contact);
                    }
                }
            });
            setSelectedRecipients([...selectedReps]);
        }

        const allRec = [
            ...contactGroups.sort((a, b) => a.name.localeCompare(b.name)),
            ...users.sort((a, b) => a.name.localeCompare(b.name)),
            ...contacts.sort((a, b) => a.name.localeCompare(b.name)),
        ];

        const notSelectedRecipients = allRec.filter(
            (p) => !selectedReps.some((q) => q.uniqueKey === p.uniqueKey)
        );

        setNotSelectedRecipients(notSelectedRecipients);
    }, [users, contactGroups, contacts, alertTemplate]);

    if (isLoadingUsers || isLoadingContacts || isLoadingContactGroups) {
        return <Spinner isGlobal />;
    }

    if (alertTemplate) {

        const formDTO = {
            ...alertTemplate
        };

        return (
            <div>
                <AlertTemplateForm
                    isLoading={isCreating || isUpdating}
                    alertTemplate={formDTO}
                    onSubmit={onSubmitHandler}
                    selectedRecipients={selectedRecipients}
                    notSelectedRecipients={notSelectedRecipients}
                    addRecipient={addRecipient}
                    removeRecipient={removeRecipient}
                    onClickRecipient={onClickRecipient}
                />
            </div>
        );
    }
    return (
        <div>
            <AlertTemplateForm
                isLoading={isCreating || isUpdating}
                onSubmit={onSubmitHandler}
                selectedRecipients={selectedRecipients}
                notSelectedRecipients={notSelectedRecipients}
                addRecipient={addRecipient}
                removeRecipient={removeRecipient}
                onClickRecipient={onClickRecipient}
            />
        </div>
    );
};

export default UpsertAlertTemplate;
