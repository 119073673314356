import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { TrainingFileApi } from './TrainingFileApi';
import queryKeys from './queryKeys';
import { CreateTrainingFileRequest, UpdateTrainingFileRequest } from './models';

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export const useCreateTrainingFile = (trainingId: number) => {
    const apiService = useApiService();
    const trainingFileApi = new TrainingFileApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (createTrainingFileRequest: CreateTrainingFileRequest) => trainingFileApi.createTrainingFile(createTrainingFileRequest),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.list(trainingId) });
            toast.success('Fil skapad');
        },
    });
};

export const useEditTrainingFile = (trainingId: number) => {
    const apiService = useApiService();
    const trainingFileApi = new TrainingFileApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (editTrainingFileRequest: UpdateTrainingFileRequest) => trainingFileApi.editTrainingFile(editTrainingFileRequest),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.list(trainingId) });
            toast.success('Fil uppdaterad');
        },
    });
};

export const useDeleteTrainingFile = (trainingId: number) => {
    const apiService = useApiService();
    const trainingFileApi = new TrainingFileApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => trainingFileApi.deleteTrainingFile(id),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.list(trainingId) });
            toast.success('Fil borttagen');
        },
    });
};
