import { FunctionComponent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOnBoardingFlow } from "../api/queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import MurphySelect from "@murphy-frontend/web-core/components/MurphySelect/MurphySelect";
import { OptionType } from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import FieldLocalizationList from "../../LocalizationAdmin/components/FieldLocalizationList";

interface EditOnboardingFlowProps {

}

const EditOnboardingFlow: FunctionComponent<EditOnboardingFlowProps> = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const idAsNumber = parseInt(id, 10);

    const { data: onboardingFlow, isLoading, isError, error } = useGetOnBoardingFlow(idAsNumber);

    const [selectedStep, setSelectedStep] = useState<number>(0);

    const onClickBack = () => {
        navigate('/onboardingflows');
    };

    const onSelectedStepChange = (selectedOption: OptionType | null) => {
        if (!selectedOption) {
            return;
        }

        setSelectedStep(selectedOption.value as number);
    }

    if (isLoading) {
        return <Spinner isGlobal />;
    }

    if (isError) {
        // Handle error case
        if (error) {
            return <div>{JSON.stringify(error)}</div>;
        }
        return <div>Error occurred while fetching.</div>;
    }

    const numberOfSteps = onboardingFlow?.NumberOfSteps;
    if (!numberOfSteps) {
        return <div>No steps added for this onboarding flow</div>;
    }

    const options = Array.from({ length: numberOfSteps }, (_, index) => ({
        value: index + 1,
        label: "Step " + (index + 1),
    }));

    return (<div className="generalwrapper">
        <section className="header-container vertical-aligner">
            <div className="one">
                <h4>Onboarding flow for: {onboardingFlow.Name}</h4>
            </div>
            <div className="two">
            </div>
        </section>
        <section>
            <div className="table-column-group-header-label">STEPS</div>
            <MurphySelect
                placeholder="Select a step"
                options={options}
                value={options.find(option => option.value === selectedStep)}
                onChange={onSelectedStepChange}
            />
        </section>
        {selectedStep !== null ? <>
            <section key={selectedStep} className="user-table-container">
                <FieldLocalizationList
                    fieldName={selectedStep.toString()}
                    entityName={"OnboardingFlow"}
                    entityId={idAsNumber}
                    isHtml={true}
                />
            </section>

        </> : null}
    </div>
    );
}

export default EditOnboardingFlow;