import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useQuery } from '@tanstack/react-query';
import { AlertTemplateAdminApi } from './AlertTemplateAdminApi';

export function useGetAlertTemplates(customerId?: string) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const alertTemplateApi = new AlertTemplateAdminApi(apiService);

    return useQuery({
        queryKey: queryKeys.list(customerId),
        queryFn: () => alertTemplateApi.getAlertTemplates(customerId),
        enabled: !!customerId,
    });
}

export function useGetAlertTemplate(id: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const alertTemplateApi = new AlertTemplateAdminApi(apiService);

    return useQuery({
        queryKey: queryKeys.detail(id),
        queryFn: () => alertTemplateApi.getAlertTemplate(id),
    });
}