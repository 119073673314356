import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useQuery } from '@tanstack/react-query';
import { RequirementApi } from './RequirementApi';

export function useGetRequirements(blockId?: number, requirementCategoryId?: number, requirementStandardId?: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);
    const queryKey = queryKeys.list({ blockId, requirementCategoryId, requirementStandardId });

    return useQuery({
        queryKey: queryKey,
        queryFn: () => requirementApi.getRequirementsByFilter(blockId, requirementCategoryId, requirementStandardId),
        enabled: blockId !== undefined || requirementCategoryId !== undefined || requirementStandardId !== undefined,
    });
}

export function useGetRequirement(requirementId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useQuery({
        queryKey: queryKeys.detail(requirementId),
        queryFn: () => requirementApi.getRequirement(requirementId),
    });
}

export function useGetCustomerFileTypeRequiredRequirement(requirementId: number | undefined) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useQuery({
        queryKey: queryKeys.customerFileTypeRequired(requirementId),
        queryFn: () => requirementApi.getCustomerFileTypeRequiredRequirement(requirementId ?? 0),
        enabled: requirementId > 0,
    });
}

export function useGetRequirementBlocks(requirementCategoryId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useQuery({
        queryKey: queryKeys.blocks(requirementCategoryId),
        queryFn: () => requirementApi.getRequirementBlocks(requirementCategoryId),
    });
}

export function useGetRequirementBlock(blockId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useQuery({
        queryKey: queryKeys.block(blockId),
        queryFn: () => requirementApi.getRequirementBlock(blockId),
    });
}

export function useGetRequirementLevels() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useQuery({
        queryKey: queryKeys.levels(),
        queryFn: () => requirementApi.getRequirementLevels(),
    });
}

export function useGetRequirementLevel(level: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useQuery({
        queryKey: queryKeys.level(level),
        queryFn: () => requirementApi.getRequirementLevel(level),
    });
}
