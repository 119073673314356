
import 'reflect-metadata';
import { Container, interfaces } from 'inversify';
import PersistenceType, {
	IPersistenceService,
} from '@murphy-frontend/common/interfaces/IPersistenceService';

import WebPersistenceService from '@murphy-frontend/web-core/services/WebPersistenceService';

import { AuthServiceProps, WebAuthService } from '@murphy-frontend/web-core/features/auth/WebAuthService';
import ApiServiceType, {
	IApiService,
} from '@murphy-frontend/common/interfaces/IApiService';
import ApiService from '@murphy-frontend/common/services/ApiService';
import AuthServiceType, {
	IAuthService,
} from '@murphy-frontend/common/interfaces/IAuthService';

import { TokenRefreshManager } from '@murphy-frontend/common/services/TokenRefreshManager';
import LogServiceType, {
	ILogService,
} from '@murphy-frontend/web-core/services/LogService/ILogService';
import { AppInsightsLogService } from '@murphy-frontend/web-core/services/LogService/AppInsightsLogService';
import { WebStorageStateStore } from 'oidc-client-ts';

const container = new Container();

// Bind TokenRefreshManager as a singleton
container.bind<TokenRefreshManager>(TokenRefreshManager).to(TokenRefreshManager).inSingletonScope();
container.bind<ILogService>(LogServiceType.ILogService).to(AppInsightsLogService);

container
	.bind<IPersistenceService>(PersistenceType.IPersistenceService)
	.to(WebPersistenceService);

container
	.bind<IAuthService>(AuthServiceType.IAuthService)
	.toDynamicValue((context: interfaces.Context) => {
		const persistenceService = context.container.get<IPersistenceService>(
			PersistenceType.IPersistenceService,
		);
		const authServiceProps: AuthServiceProps = {
			baseAuthServerUrl: import.meta.env.VITE_IDP_URL,
			redirectUrl: import.meta.env.VITE_REDIRECT_URL,
			postLogoutUrl: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URL,
			clientId: import.meta.env.VITE_CLIENT_ID,
			automaticSilentRenew: true,
			stateStore: new WebStorageStateStore({
				store: window.localStorage,
			}),
			enableChangePassword: false,
			enableForgotPassword: false,
		};
		return new WebAuthService(authServiceProps, persistenceService);
	})
	.inSingletonScope();

container
	.bind<IApiService>(ApiServiceType.IApiService)
	.toDynamicValue((context: interfaces.Context) => {
		const authService = context.container.get<IAuthService>(
			AuthServiceType.IAuthService,
		);
		const tokenRefreshManager = container.get<TokenRefreshManager>(TokenRefreshManager);
		return new ApiService(authService, import.meta.env.VITE_API_URL, tokenRefreshManager);
	});

export default container;
