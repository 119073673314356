import { FunctionComponent, useRef, useState } from 'react';
import {
  FaBars,
} from 'react-icons/fa';
import {
  RiHome2Line, RiUserSettingsLine, RiArrowDownSLine, RiTimeLine, RiBookOpenLine,
} from 'react-icons/ri';

import { FiCircle } from "react-icons/fi";

import {
  HiOutlineCog,
} from 'react-icons/hi';

import CheckMarkIcon from "@murphy-frontend/web-core/assets/icons/CheckMarkIcon.svg?react";

import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import NavDropdownMenu from '@murphy-frontend/web-core/components/NavDropdownMenu';
import useDetectOutsideClick from '@murphy-frontend/web-core/hooks/useDetectOutsideClick';
import UserProfileMenuCard from '@murphy-frontend/web-core/components/UserProfileMenuCard';
import { useCustomer } from '../contexts/CustomerContext';
import OptionSet from '@murphy-frontend/web-core/components/OptionSet';
import { timeZones } from '@murphy-frontend/web-core/constants';
import { useAdminUserPreferences } from '../contexts/AdminUserPreferencesContext';
import { useUser } from '../contexts/UserContext';

const Navbar: FunctionComponent = () => {
  const dropdownRef = useRef(null);

  const [click, setClick] = useDetectOutsideClick(dropdownRef, false);
  const [clickAdmin, setClickAdmin] = useDetectOutsideClick(dropdownRef, false);
  const [clickUserProfile, setClickUserProfile] = useState(false);

  const { customer, setSelectedCustomerId, customers } = useCustomer();
  const { timeZone, setTimeZone } = useAdminUserPreferences();
  const { user } = useUser();

  const handleClick = () => {
    setClickAdmin(false);
    setClickUserProfile(false);
    setClick(!click);
  };
  const handleClickAdmin = () => {
    setClick(false);
    setClickUserProfile(false);
    setClickAdmin(!clickAdmin);

  };
  const handleClickUserProfile = () => {
    setClick(false);
    setClickAdmin(false);
    setClickUserProfile(!clickUserProfile);
  };

  const closeMobileMenu = () => {
    setClick(false);
    setClickAdmin(false);
    setClickUserProfile(false);

  };

  const onSelectedCustomerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCustomerId = event.target.value;
    setSelectedCustomerId(newCustomerId);
  };

  const onChangeTimeZone = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setTimeZone(event.target.value);
  };

  const menuIconClass = 'menu-icon dark-mode';
  let menuReactIcon = 'react-icons dark-mode';
  const menuIconClass2 = 'menu-icon2 dark-mode';
  let menuReactIcon2 = 'react-icons dark-mode';
  const menuIconClass3 = 'menu-icon3 dark-mode';
  if (click) {
    menuReactIcon += ' menu-icon-selected';
  }
  if (clickAdmin) {
    menuReactIcon2 += ' menu-icon-selected';
  }

  const settingsItems = [];
  const timeZonePicker = (
    <li key="timeZonePicker" className="nav-item">
      <div className="navbar-optionset-container">
        <IconContext.Provider value={{ className: 'navbar-optionset-icon' }}>
          <RiTimeLine />
        </IconContext.Provider>
        <div className="navbar-optionset">
          <OptionSet
            direction="column"
            key={1}
            optionSetId={1}
            options={timeZones}
            selectedOption={timeZone}
            handleChange={onChangeTimeZone}
          />
        </div>
      </div>
    </li>
  );
  settingsItems.push(timeZonePicker);

  const hamburgerItems = [];

  const HomeMenuItem = (
    <div className="nav-bar-menu-item-container">
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiHome2Line />
        </IconContext.Provider>
      </div>

      <div className="nav-bar-menu-item-text">Home</div>
    </div>
  );
  const startItem = (
    <li key="start" className="nav-item default-module-color">
      <Link
        to="/portal"
        className="nav-links light-text"
        onClick={closeMobileMenu}
      >
        {HomeMenuItem}
      </Link>
    </li>
  );
  hamburgerItems.push(startItem);

  const internalAdminLinks = [];
  const customerAdminPage = { url: '/customers', displayName: "Customers" };
  internalAdminLinks.push(customerAdminPage);
  // const newCustomerPage = { url: '/customers/new', displayName: `+ New Customer` };
  // internalAdminLinks.push(newCustomerPage);

  const newAdminUsersPage = { url: '/users', displayName: "Users" };
  internalAdminLinks.push(newAdminUsersPage);

  const customerGroupAdminPage = { url: '/customergroups', displayName: "Customer Groups" };
  internalAdminLinks.push(customerGroupAdminPage);

  // const customerFilesAdminPage = { url: '/customerfiles', displayName: "Customer Files" };
  // internalAdminLinks.push(customerFilesAdminPage);

  const AdminMenuItem = (
    <div className="nav-bar-menu-item-container">
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiUserSettingsLine />
        </IconContext.Provider>
      </div>
      <span className="nav-bar-menu-item-text">Customers and Users</span>
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiArrowDownSLine />
        </IconContext.Provider>
      </div>
    </div>
  );

  const adminMenuItem = (
    <li key="adminmenu" className="nav-item">
      <NavDropdownMenu
        onClickItem={closeMobileMenu}
        title={AdminMenuItem}
        items={internalAdminLinks}
        externalLinks={undefined}
        customClass={undefined} />
    </li>
  );
  hamburgerItems.push(adminMenuItem);

  const AcademyMenuItem = (
    <div className="nav-bar-menu-item-container">
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiBookOpenLine />
        </IconContext.Provider>
      </div>
      <span className="nav-bar-menu-item-text">Academy</span>
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiArrowDownSLine />
        </IconContext.Provider>
      </div>
    </div>
  );
  const academyAdminLinks = [];
  const courseAdminPage = { url: '/courseadmin', displayName: "Course Admin" };
  academyAdminLinks.push(courseAdminPage);

  const educatorsPage = { url: '/educators', displayName: "Educators" };
  academyAdminLinks.push(educatorsPage);

  const academyAdminMenuItem = (
    <li key="academyadminmenu" className="nav-item">
      <NavDropdownMenu
        onClickItem={closeMobileMenu}
        title={AcademyMenuItem}
        items={academyAdminLinks}
        externalLinks={undefined}
        customClass={undefined} />
    </li>);

  hamburgerItems.push(academyAdminMenuItem);

  const EventFlowMenuItem = (
    <div className="nav-bar-menu-item-container">
      <div className="nav-bar-menu-icon-container">
        <div className="nav-bar-menu-icon-dark">
          <CheckMarkIcon />
        </div>
      </div>
      <span className="nav-bar-menu-item-text">Incidents</span>
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiArrowDownSLine />
        </IconContext.Provider>
      </div>
    </div>
  );
  const eventFlowAdminLinks = [];
  const eventFlowTemplatesPage = { url: '/eventflowtemplates', displayName: "Event Flow Templates" };
  eventFlowAdminLinks.push(eventFlowTemplatesPage);

  const alertTemplatesPage = { url: '/alerttemplates', displayName: "Alert Templates" };
  eventFlowAdminLinks.push(alertTemplatesPage);

  const eventFlowAdminMenuItem = (
    <li key="eventflowadminmenu" className="nav-item">
      <NavDropdownMenu
        onClickItem={closeMobileMenu}
        title={EventFlowMenuItem}
        items={eventFlowAdminLinks}
        externalLinks={undefined}
        customClass={undefined} />
    </li>);

  hamburgerItems.push(eventFlowAdminMenuItem);

  const miscAdminLinks = [];

  const requirementAdminPage = { url: '/requirements', displayName: "Requirement Builder" };
  miscAdminLinks.push(requirementAdminPage);

  const onboardingFlowAdminPage = { url: '/onboardingflows', displayName: "Onboarding Flows" };
  miscAdminLinks.push(onboardingFlowAdminPage);

  const MiscAdminMenuItem = (
    <div className="nav-bar-menu-item-container">
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <FiCircle />
        </IconContext.Provider>
      </div>
      <span className="nav-bar-menu-item-text">Misc</span>
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiArrowDownSLine />
        </IconContext.Provider>
      </div>
    </div>
  );

  const miscAdminMenuItem = (
    <li key="miscadminmenu" className="nav-item">
      <NavDropdownMenu
        onClickItem={closeMobileMenu}
        title={MiscAdminMenuItem}
        items={miscAdminLinks}
        externalLinks={undefined}
        customClass={undefined} />
    </li>
  );
  hamburgerItems.push(miscAdminMenuItem);

  return (
    <nav className="navbar navbar-dark">
      <div ref={dropdownRef} className="navbar-container">
        <div className="navbar-links">
        </div>
        <Link
          to="/portal"
          className="navbar-logo"
          onClick={closeMobileMenu}
        >
          <div className="admin-logo">MURPHY ADMIN</div>
          {/* <img
            alt="logo"
            className="navbar-safe-logo"
            src={require('../assets/img/Logo_MyMurphy.svg')}
          /> */}
        </Link>
        <>
          {customer && user && <div className={menuIconClass3}>
            <UserProfileMenuCard
              userName={user.Email}
              customerName={customer.Name}
              setIsClicked={handleClickUserProfile}
              isClicked={clickUserProfile}
              allLicences={customers}
              currentCustomerId={customer.Id}
              onLicenseChange={onSelectedCustomerChange}
              darkMode={true}
            />
          </div>}

          <div className={menuIconClass} role="button" tabIndex={0} onClick={handleClick} onKeyUp={handleClick}>
            <IconContext.Provider value={{ className: menuReactIcon }}>
              <FaBars />
            </IconContext.Provider>
          </div>

        </>
        {settingsItems.length > 0 ? (
          <div className={menuIconClass2} role="button" tabIndex={0} onClick={handleClickAdmin} onKeyUp={handleClickAdmin}>
            <IconContext.Provider value={{ className: menuReactIcon2 }}>
              <HiOutlineCog />
            </IconContext.Provider>
          </div>
        )
          : null}
        {
          <ul className={click || clickAdmin ? 'nav-menu active' : 'nav-menu'}>
            {click ? hamburgerItems.map((p) => p) : null}
            {clickAdmin ? settingsItems.map((p) => p) : null}
          </ul>
        }
      </div>
    </nav>
  );
}

export default Navbar;
