const queryKeys = {
    all: ['online-education'],
    lists: () => [...queryKeys.all, 'list'],
    baseLessons: (entityId: number, entityType: string) => [...queryKeys.lists(), { entityId, entityType }],
    localizedLessons: (baseLessonId: number) => [...queryKeys.lists(), { baseLessonId }],
    courses: () => [...queryKeys.lists(), 'courses'],
    courseChapters: (courseId: number) => [...queryKeys.lists(), { courseId }],
    chapterLessons: (chapterId: number) => [...queryKeys.lists(), { chapterId }],
};

export default queryKeys;
