import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useQuery } from '@tanstack/react-query';
import { EventFlowTemplatesApi } from './EventFlowTemplatesApi';

export function useGetTemplates(customerId: string) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useQuery({
        queryKey: queryKeys.list(customerId),
        queryFn: () => templateApi.getTemplates(customerId),
        enabled: customerId !== undefined,
    });
}

export function useGetTemplate(templateId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const templateApi = new EventFlowTemplatesApi(apiService);

    return useQuery({
        queryKey: queryKeys.detail(templateId),
        queryFn: () => templateApi.getTemplate(templateId),
    });
}