import { FunctionComponent } from "react";
import { EventFlowRespondBlockDto, UpdateEventFlowRespondBlockDto } from "../../../api/models";
import { useUpdateRespondBlock } from "../../../api/mutations";
import EventFlowRespondBlockForm, { EventFlowRespondBlockFormDto } from "./EventFlowRespondBlockForm";
import { EventFlowCheckListItemType } from "@murphy-frontend/common/enums";

interface UpdateEventFlowRespondBlockProps {
    eventFlowRespondBlock: EventFlowRespondBlockDto;
    onSuccess: () => void;
    templateId: number;
}

const UpdateEventFlowRespondBlock: FunctionComponent<UpdateEventFlowRespondBlockProps> = (
    {
        eventFlowRespondBlock,
        onSuccess,
        templateId,
    }) => {

    const { mutate: updateEventFlowRespondBlock, isPending: isUpdating } = useUpdateRespondBlock(templateId);
    const onSubmitHandler = (eventFlowRespondBlockData: EventFlowRespondBlockFormDto) => {
        const updatedEventFlowBlock: UpdateEventFlowRespondBlockDto = {
            ...eventFlowRespondBlock,
            ...eventFlowRespondBlockData,
        };
        updateEventFlowRespondBlock(updatedEventFlowBlock, {
            onSuccess: () => {
                onSuccess();
            },
        });
    };
    const formDto = {
        ...eventFlowRespondBlock
    };

    const getVisibleFields = (eventFlowRespondBlock: EventFlowRespondBlockDto): string[] => {
        const visibleFields = ["Title", "Text", "PlaceholderText"];

        if (eventFlowRespondBlock.BlockType === EventFlowCheckListItemType.Checkbox) {
            return ["Text"];
        }
        if (eventFlowRespondBlock.BlockType === EventFlowCheckListItemType.TextInput) {
            return ["Text", "PlaceholderText"];
        }

        return visibleFields;
    }

    const visibleFields = getVisibleFields(eventFlowRespondBlock);

    return (
        <div>
            <EventFlowRespondBlockForm
                isLoading={isUpdating}
                eventFlowRespondBlock={formDto}
                onSubmit={onSubmitHandler}
                visibleFields={visibleFields}
            />
        </div>
    );

}

export default UpdateEventFlowRespondBlock;