import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { AxiosResponse } from 'axios';
import {
    CreateEventFlowBlockDto,
    CreateEventFlowRespondBlockDto,
    CreateEventFlowTabDto,
    CreateEventFlowTemplateDto,
    CreateSendAlertActionDto,
    EventFlowBlockDto,
    EventFlowRespondBlockDto,
    EventFlowTabDto,
    EventFlowTemplateDto,
    UpdateEventFlowBlockDto,
    UpdateEventFlowRespondBlockDto,
    UpdateEventFlowTabDto,
    UpdateEventFlowTemplateDto,
    UpdateSendAlertActionDto
} from './models';

export class EventFlowTemplatesApi {
    constructor(private apiService: IApiService) { }

    // templates
    getTemplates = async (customerId: string): Promise<EventFlowTemplateDto[]> => {
        const url = `api/EventFlowTemplate?customerId=${customerId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getTemplate = async (id: number): Promise<EventFlowTemplateDto> => {
        const url = `api/EventFlowTemplate/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createTemplate = async (dto: CreateEventFlowTemplateDto): Promise<EventFlowTemplateDto> => {
        const url = 'api/EventFlowTemplate';
        const { data } = await this.apiService.callApi(url, 'POST', dto);
        return data;
    };

    updateTemplate = (dto: UpdateEventFlowTemplateDto): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowTemplate/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    };

    deleteTemplate = (id: number): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowTemplate/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    //tabs
    getTabs = async (templateId: number): Promise<EventFlowTabDto[]> => {
        const url = `api/EventFlowTab?templateId=${templateId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createTab = async (dto: CreateEventFlowTabDto): Promise<EventFlowTabDto> => {
        const url = 'api/EventFlowTab';
        const { data } = await this.apiService.callApi(url, 'POST', dto);
        return data;
    };

    updateTab = (dto: UpdateEventFlowTabDto): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowTab/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    };

    deleteTab = (id: number): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowTab/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    //blocks
    getBlocks = async (tabId: number): Promise<EventFlowBlockDto[]> => {
        const url = `api/EventFlowBlock?eventId=${tabId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createBlock = async (dto: CreateEventFlowBlockDto): Promise<EventFlowBlockDto> => {
        const url = 'api/EventFlowBlock';
        const { data } = await this.apiService.callApi(url, 'POST', dto);
        return data;
    };

    updateBlock = (dto: UpdateEventFlowBlockDto): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowBlock/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    };

    deleteBlock = (id: number): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowBlock/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    //respond blocks
    getRespondBlocks = async (blockId: number): Promise<EventFlowRespondBlockDto[]> => {
        const url = `api/EventFlowRespondBlock?blockId=${blockId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createRespondBlock = async (dto: CreateEventFlowRespondBlockDto): Promise<EventFlowRespondBlockDto> => {
        const url = 'api/EventFlowRespondBlock';
        const { data } = await this.apiService.callApi(url, 'POST', dto);
        return data;
    };

    updateRespondBlock = (dto: UpdateEventFlowRespondBlockDto): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowRespondBlock/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    };

    deleteRespondBlock = (id: number): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowRespondBlock/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    createSendAlertAction = async (dto: CreateSendAlertActionDto): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowTemplateAction/SendAlert`;
        return this.apiService.callApi(url, 'POST', dto);
    }

    updateSendAlertAction = async (dto: UpdateSendAlertActionDto): Promise<AxiosResponse<any, any>> => {
        const url = `api/EventFlowTemplateAction/SendAlert/${dto.Id}`;
        return this.apiService.callApi(url, 'PUT', dto);
    }
}
