import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { EducatorApi } from './EducatorApi';
import { CreateEducatorDto, UpdateEducatorDto } from './models';

export const useCreateEducator = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new EducatorApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (createEducatorDto: CreateEducatorDto) => api.createEducator(createEducatorDto),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success('Educator added');
        },
    });
};

export const useEditEducator = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new EducatorApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (updateEducatorDto: UpdateEducatorDto) => api.updateEducator(updateEducatorDto),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success('Educator edited');
        },
    });
};