import { useQuery } from '@tanstack/react-query';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import queryKeys from './queryKeys';
import { TrainingApi } from './TrainingApi';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export function useGetTrainings() {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingApi(apiService);
  return useQuery({
    queryKey: queryKeys.all,
    queryFn: () => api.getAllTrainings(),
    staleTime: Infinity
  });
}

export function useGetTraining(id: number) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingApi(apiService);
  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => api.getTraining(id),
    enabled: !!id
  });
}
