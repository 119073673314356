import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '@murphy-frontend/web-core/components/Input';
import Button from '@murphy-frontend/web-core/components/Button';

const EventFlowTabSchema = yup.object().shape({
    Name: yup.string().required(),
});

export interface EventFlowTabFormDto {
    Name: string;
}

interface EventFlowTabFormProps {
    isLoading?: boolean;
    eventFlowTab?: EventFlowTabFormDto;
    onSubmit: (data: EventFlowTabFormDto) => void;
}

const EventFlowTabForm: React.FC<EventFlowTabFormProps> = ({
    isLoading,
    eventFlowTab,
    onSubmit,
}) => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
    } = useForm<EventFlowTabFormDto>({
        resolver: yupResolver(EventFlowTabSchema),
        defaultValues: {
            Name: '',
        },
    });

    useEffect(() => {
        if (eventFlowTab) {
            reset(eventFlowTab);
        }
    }, [eventFlowTab, reset]);

    const onSubmitHandler = (data: EventFlowTabFormDto) => {
        onSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section">
                        <Input
                            isControlled
                            register={register}
                            name="Name"
                            placeholder="Name"
                            errorMessage={errors.Name?.message}
                        />
                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EventFlowTabForm;
