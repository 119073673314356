import { useQuery } from '@tanstack/react-query';
import { ContactGroupApi } from './ContactGroupApi';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import queryKeys from './QueryKeys';

export function useGetContactGroups(customerId: string | null | undefined) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new ContactGroupApi(apiService);

  return useQuery({
    queryKey: queryKeys.list(customerId),
    queryFn: () => api.getContactGroupsByCustId(customerId, true, true),
    enabled: !!customerId, staleTime: 60 * 60 * 1000
  });
}

export function useGetContactGroupUsers(contactGroupId: number) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new ContactGroupApi(apiService);

  return useQuery({
    queryKey: queryKeys.users(contactGroupId),
    queryFn: () => api.getUsersByContactGrpId(contactGroupId),
    enabled: !!contactGroupId, staleTime: 60 * 60 * 1000
  });
}

export function useGetContractGroup(contactGroupId: number) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new ContactGroupApi(apiService);

  return useQuery({
    queryKey: queryKeys.detail(contactGroupId),
    queryFn: () => api.getContactGrpById(contactGroupId, true, true),
    enabled: !!contactGroupId, staleTime: 60 * 60 * 1000
  });
}
