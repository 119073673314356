import { FunctionComponent } from "react";
import { EventFlowTabDto, UpdateEventFlowTabDto } from "../../api/models";
import { useUpdateTab } from "../../api/mutations";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import EventFlowTabForm, { EventFlowTabFormDto } from "./EventFlowTabForm";

interface UpdateEventFlowTabProps {
    eventFlowTab: EventFlowTabDto,
    onSuccess: () => void;
}

const UpdateEventFlowTab: FunctionComponent<UpdateEventFlowTabProps> = ({ eventFlowTab, onSuccess }) => {
    const { mutate: updateTab, isPending: updateIsLoading } = useUpdateTab(eventFlowTab.TemplateId);

    if (updateIsLoading) {
        return <Spinner isGlobal />
    }

    const onSubmitHandler = (eventFlowTemplateData: EventFlowTabFormDto) => {
        const updatedEventFlowTabDto: UpdateEventFlowTabDto = {
            ...eventFlowTab,
            ...eventFlowTemplateData,
        };
        updateTab(updatedEventFlowTabDto, {
            onSuccess: () => {
                onSuccess();
            },
        });
    };

    return (
        <EventFlowTabForm
            onSubmit={onSubmitHandler}
            eventFlowTab={eventFlowTab}
        />
    );
}

export default UpdateEventFlowTab;