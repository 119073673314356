import { FunctionComponent, useState } from "react";
import { useGetCourses, useGetLocalizedLessons } from "../api/queries";
import { DbLanguages } from "@murphy-frontend/web-core/constants";
import { OptionType } from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import MurphySelect from "@murphy-frontend/web-core/components/MurphySelect/MurphySelect";
import UpsertLesson from "./UpsertLesson";

interface LocalizedLessonListProps {
    baseLessonId: number,
}

const LocalizedLessonList: FunctionComponent<LocalizedLessonListProps> = (
    { baseLessonId }
) => {
    const [selectedLanguageId, setSelectedLanguageId] = useState<number>(DbLanguages[0].id);
    const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
    const [selectedChapterId, setSelectedChapterId] = useState<number | null>(null);

    const { data: localizedLessons, isLoading } = useGetLocalizedLessons(baseLessonId);
    const { data: courses, isLoading: coursesIsLoading } = useGetCourses();

    const options: OptionType[] = DbLanguages.map((p) => ({
        value: p.id,
        label: p.value,
    }));

    const handleLanguageChange = (selectedOption: OptionType | null) => {
        if (!selectedOption) {
            return;
        }

        setSelectedLanguageId(selectedOption.value as number);
    }

    if (isLoading || coursesIsLoading) {
        return <div>Loading...</div>
    }

    const matchingLocalization = localizedLessons?.find(l => l.LanguageId === selectedLanguageId);

    const form = <UpsertLesson
        baseLessonId={baseLessonId}
        languageId={selectedLanguageId}
        currentLocalizedLesson={matchingLocalization}
        courses={courses}
        selectedCourseId={selectedCourseId}
        selectedChapterId={selectedChapterId}
        setSelectedCourseId={setSelectedCourseId}
        setSelectedChapterId={setSelectedChapterId} />

    return (<div>
        <MurphySelect
            placeholder="Language"
            options={options}
            value={options.find(option => option.value === selectedLanguageId)}
            onChange={handleLanguageChange}
        />
        {form}
    </div>
    );
}

export default LocalizedLessonList;