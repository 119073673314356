import { FunctionComponent } from "react";
import { EventFlowBlockDto } from "../../../api/models";
import TitleWithControls from "./TitleWithControls";

interface TitleComponentProps {
    eventFlowBlock: EventFlowBlockDto;
    onUpdate: (eventFlowBlock: EventFlowBlockDto) => void;
    onDelete: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    maxRanking: number;
}

const TitleComponent: FunctionComponent<TitleComponentProps> = (
    {
        eventFlowBlock,
        onUpdate,
        onDelete,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking
    }
) => {

    const content = <span>
        {eventFlowBlock.TextValue}
    </span>;

    return (<div className="m-spacing">
        <div className="l-text">
            <TitleWithControls
                content={content}
                eventFlowBlock={eventFlowBlock}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onClickDecrementRanking={onClickDecrementRanking}
                onClickIncrementRanking={onClickIncrementRanking}
                maxRanking={maxRanking}
            />
        </div>
    </div>);
}

export default TitleComponent;