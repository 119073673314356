import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import UpsertCustomerFileTypeRequirement from '../components/UpsertCustomerFileTypeRequirement';
import MurphySelect from '@murphy-frontend/web-core/components/MurphySelect/MurphySelect';
import { OptionType } from '@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect';
import UpsertBasicTypeRequirement from '../components/UpsertBasicTypeRequirement';

interface CreateRequirementPageProps {

}

const CreateRequirementPage: FunctionComponent<CreateRequirementPageProps> = () => {
    const navigate = useNavigate();
    const { requirementBlockId } = useParams<{ requirementBlockId: string }>();
    const blockId = requirementBlockId ? parseInt(requirementBlockId, 10) : 0;

    const [selectedRequirementType, setSelectedRequirementType] = React.useState<OptionType>({ value: 1, label: 'Basic' });

    const handleRequirementTypeChange = (selectedOption) => {
        setSelectedRequirementType(selectedOption);
    }

    const onClickBack = () => {
        navigate(-1);
    };

    const onSuccessHandler = () => {
        navigate(-1);
    };
    const options = [
        { value: 1, label: 'Basic' },
        { value: 2, label: 'Customer File' },
    ];

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>New Requirement</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            {blockId && <section className="container-with-background">
                <MurphySelect
                    placeholder="Select a Requirement Type"
                    options={options}
                    value={options.find(option => option.value === selectedRequirementType?.value)}
                    onChange={handleRequirementTypeChange}
                />

                <div className="form-container">
                    {selectedRequirementType?.value == 2 && <UpsertCustomerFileTypeRequirement
                        onSuccess={onSuccessHandler}
                        requirementBlockId={blockId} />}
                    {selectedRequirementType?.value == 1 && <UpsertBasicTypeRequirement
                        onSuccess={onSuccessHandler}
                        requirementBlockId={blockId} />}
                </div>
            </section>}
        </div>
    );
}

export default CreateRequirementPage;