import { useQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { LocalizationApi } from './LocalizationApi';

export function useGetLocalizations(entityType: string, entityId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const localizationApi = new LocalizationApi(apiService);

    return useQuery({
        queryKey: queryKeys.list({ entityId, entityType }),
        queryFn: () => localizationApi.getLocalizations(entityType, entityId),
    });
}