import { FunctionComponent } from "react";
import { useCustomer } from "../../contexts/CustomerContext";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { useGetAlertTemplates } from "./api/queries";
import { AlertTemplateDTO } from "./api/models";
import AlertTemplatesTable from "./components/AlertTemplatesTable";
import Button from "@murphy-frontend/web-core/components/Button";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import { useDeleteTemplate } from "../EventFlowTemplates/api/mutations";

interface AlertTemplatesProps {

}

const AlertTemplates: FunctionComponent<AlertTemplatesProps> = () => {
    const { customer } = useCustomer();
    const { currentSize } = useWindowDimensions();
    const navigate = useNavigate();

    const { data: templates, isLoading: templatesIsLoading } = useGetAlertTemplates(customer?.Id);
    const { mutate: deleteTemplate, isPending: deleteIsLoading } = useDeleteTemplate(customer?.Id);


    const onClickNew = () => {
        navigate('/alerttemplates/new');
    };

    const onClickEdit = (template: AlertTemplateDTO) => {
        const url = `/alerttemplates/${template.ID}/config`;
        navigate(url);
    };

    const onClickEditConfiguration = (template: AlertTemplateDTO) => {
        const url = `/alerttemplates/${template.ID}/config`;
        navigate(url);
    }

    const onClickDelete = (template: AlertTemplateDTO) => {
        deleteTemplate(template.ID);
        navigate('/alerttemplates');
    };

    if (templatesIsLoading || deleteIsLoading) {
        return (<Spinner isGlobal />);
    }

    return (
        <div className="generalwrapper">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>
                        ALERT TEMPLATES
                    </h4>
                </div>
                <div className="two">
                    <Button
                        isIconButton
                        buttonStyle="btn--add"
                        buttonSize="btn-with-icon"
                        onClick={onClickNew}
                    >
                        <span className="btn-with-icon-text">
                            New Alert Template
                        </span>
                    </Button>
                </div>
            </section>
            <section className="user-table-container">
                <AlertTemplatesTable
                    templates={templates ?? []}
                    onClickEdit={onClickEdit}
                    onClickEditConfiguration={onClickEditConfiguration}
                    onClickDelete={onClickDelete}
                    currentScreenSize={currentSize}
                />
            </section>
        </div>
    );
}

export default AlertTemplates;