import { FunctionComponent } from "react";
import { EventFlowRespondBlockDto } from "../../../api/models";
import TableCellActionDropDownButton from "@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton";
import { IconContext } from "react-icons";
import { RiDeleteBin7Fill, RiPencilFill } from "react-icons/ri";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

interface CheckListTitleWithControlsProps {
    eventFlowRespondBlock: EventFlowRespondBlockDto;
    content: React.ReactNode;
    onUpdate: (EventFlowRespondBlock: EventFlowRespondBlockDto) => void;
    onDelete: (EventFlowRespondBlock: EventFlowRespondBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowRespondBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowRespondBlockDto) => void;
    maxRanking: number;
}

const CheckListTitleWithControls: FunctionComponent<CheckListTitleWithControlsProps> = ({
    eventFlowRespondBlock,
    onDelete,
    onUpdate,
    onClickDecrementRanking,
    onClickIncrementRanking,
    maxRanking,
    content }) => {

    const generateActionDropDownButton = (block: EventFlowRespondBlockDto) => {
        return <TableCellActionDropDownButton
            textComponent={content}
            clickable={false}
            listItems={generateDropDownListItems(block)}
            columnSize="column-medium"
            onClickRow={() => { }}
        />
    }

    const generateDropDownListItems = (block: EventFlowRespondBlockDto) => {
        const ddlItems = [];
        ddlItems.push(
            <li key="edit" onClick={() => onUpdate(block)}>
                <div className="vertical-aligner">
                    <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                        <RiPencilFill />
                    </IconContext.Provider>
                    <span>Edit</span>
                </div>
            </li>
        );

        ddlItems.push(
            <li key="delete" onClick={() => onDelete(block)}>
                <div className="vertical-aligner">
                    <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                        <RiDeleteBin7Fill />
                    </IconContext.Provider>
                    <span>Delete</span>
                </div>
            </li>
        );

        if (block.Ranking > 1) {
            ddlItems.push(
                <li key="decrementranking" onClick={() => onClickDecrementRanking(block)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <IoChevronUp />
                        </IconContext.Provider>
                        <span>Move up</span>
                    </div>
                </li>
            );
        }

        if (block.Ranking < maxRanking) {
            ddlItems.push(
                <li key="incrementranking" onClick={() => onClickIncrementRanking(block)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <IoChevronDown />
                        </IconContext.Provider>
                        <span>Move down</span>
                    </div>
                </li>
            );
        }

        return ddlItems;
    };

    return (
        <div className="l-text">
            {generateActionDropDownButton(eventFlowRespondBlock)}
        </div>
    );
}

export default CheckListTitleWithControls;