import { useState, useEffect, FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import {
    RiPencilFill
} from 'react-icons/ri';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import TableCellActionDropDownButton from '@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton';
import { createColumnGroup, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { getColSizeFlexBasedOnScreen } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { TrainingModel } from '../../api/Training/TrainingApi';
import { ColumnGroup } from '@murphy-frontend/web-core/components/ColumnGroupTable/Models';

interface CourseListProps {
    courses: TrainingModel[],
    onClickEditCourse: (id: number) => void,
    currentScreenSize: string,
}

const CourseList: FunctionComponent<CourseListProps> = ({
    courses,
    onClickEditCourse,
    currentScreenSize,
}) => {
    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [currentSortingColumn, setCurrentSortingColumn] = useState('name');
    const [sortedAscending, setSortedAscending] = useState(true);

    const getSectorDisplayValue = (isPublicSector?: boolean) => {

        if (isPublicSector === undefined || isPublicSector === null) {
            return 'Not specified';
        }
        if (isPublicSector === true) {
            return 'Public';
        }
        if (isPublicSector === false) {
            return 'Private';
        }
    }

    useEffect(() => {
        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (courseRow: TrainingModel) => {
            const items = [];
            items.push(
                (
                    <li key="edit" onClick={() => onClickEditCourse(courseRow.Id)}>
                        <div className="vertical-aligner">
                            <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                                <RiPencilFill />
                            </IconContext.Provider>
                            <span>Edit</span>
                        </div>
                    </li>
                ),
            );
            return items;
        };

        const createMainColumnGroup = (coursesArray: TrainingModel[]) => {
            const courseInfoCols = [
                {
                    columnKey: 'name',
                    displayName: 'Name',
                    isSortable: true,
                    size: getColSizeFlexBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'sector',
                    displayName: 'Sector',
                    isSortable: true,
                    size: getColSizeFlexBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
            ];
            const courseInfoRows = coursesArray.map((row) => ([{
                columnKey: 'name',
                id: row.Id,
                rowId: row.Id,
                displayValue:
                    <TableCellActionDropDownButton
                        onClickRow={() => onClickEditCourse(row.Id)}
                        displayText={row.Name}
                        listItems={generateDropDownListItems(row)}
                        columnSize={getColSizeFlexBasedOnScreen(currentScreenSize)}
                    />,
            },
            {
                columnKey: 'sector',
                id: `sector-${row.Id}`,
                rowId: row.Id,
                displayValue: getSectorDisplayValue(row.PublicSector),
            }]));
            const mainDashboardColumnGroup = createColumnGroup('', courseInfoCols, courseInfoRows);
            return mainDashboardColumnGroup;
        };

        if (courses) {
            const allColumnGroups = [];
            const coursesCopy = [...courses];

            if (currentSortingColumn) {
                coursesCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const mainColGroup = createMainColumnGroup(coursesCopy);

            allColumnGroups.push(mainColGroup);
            setColumnGroups(allColumnGroups);
        }
    }, [courses, sortedAscending, currentSortingColumn, currentScreenSize, onClickEditCourse]);

    return (
        <ColumnGroupTable
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
        />
    );
}

export default CourseList;
