import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
// import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { products, statusTypes } from '@murphy-frontend/web-core/constants';
import CustomerTable, { CustomerTableModel } from './components/CustomerTable';
import { useGetCustomers } from './api/queries';
import { useDeleteCustomer } from './api/mutations';
import { MurphyCustomer } from './api/models';

const CustomerAdmin: FunctionComponent = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<unknown>(null);
  const { openModal } = useModal();

  const [currentSearchText, setCurrentSearchText] = useState<string>('');
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerTableModel[]>([]);

  const {
    isLoading, isError, data, error: getCustomersError,
  } = useGetCustomers(true, true);

  const { mutate: delMutate, isPending: deleteCustomerIsLoading } = useDeleteCustomer();

  const mapCustomers = (customers: MurphyCustomer[]): CustomerTableModel[] => customers.map((customer) => {
    const statusType = statusTypes.find(p => p.id === customer.StatusTypeId)?.value || 'Default';

    const customerTableModel: CustomerTableModel = {
      id: customer.Id,
      name: customer.Name,
      allowedusers: customer.AllowedUsers,
      activeusers: customer.ActiveUsers,
      smssender: customer.SMSsender,
      externalcontactsenabled: customer.ExternalContactsEnabled,
      mfa: customer.MFAEnabled,
      appmfa: customer.AppMFAEnabled,
      activecustomerproducts: customer.ActiveCustomerProducts,
      statustypeid: customer.StatusTypeId,
      statustypename: statusType,
      customergroupname: customer.CustomerGroupName,
      publicsector: customer.PublicSector,
    }
    return customerTableModel;
  });


  const onClickNewCustomer = () => {
    navigate('/customers/new');
  };

  const onClickEditCustomer = (id) => {
    const url = `/customers/${id}`;
    navigate(url);
  };

  const onClickDeleteCustomer = (id) => {
    delMutate(id);
    navigate('/customers');
  };

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <p>{error}</p>,
        buttonText: 'Ok',
        type: 'error',
      };
      openModal(modalConf);
    }
  }, [error, openModal]);

  useEffect(() => {
    if (getCustomersError)
      setError(getCustomersError);
  }, [isError, getCustomersError]);

  useEffect(() => {
    if (!isLoading && data && data.length > 0 && statusTypes && statusTypes.length > 0) {
      let newFilteredCustomers = mapCustomers(data);

      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredCustomers = newFilteredCustomers.filter((p) => p.name.toUpperCase().startsWith(searchValue));
      }
      setFilteredCustomers(newFilteredCustomers);
    }
  }, [currentSearchText, data, isLoading]);

  if (isLoading || deleteCustomerIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>Customers</h4>
        </div>
        <div className="two">
          <Button
            isIconButton
            buttonStyle="btn--add"
            buttonSize="btn-with-icon"
            onClick={onClickNewCustomer}
          >
            <span className="btn-with-icon-text">
              New customer
            </span>
          </Button>
        </div>
        <div className="three">
          <SearchBar
            val={currentSearchText}
            handleChange={onSearchChanged}
            placeholder={"Search"}
          />
        </div>
      </section>
      <section className="user-table-container">
        <div>
          <CustomerTable
            customers={filteredCustomers}
            allProducts={products}
            onClickEditCustomer={onClickEditCustomer}
            onClickDeleteCustomer={onClickDeleteCustomer}
          />
        </div>
      </section>
    </div>
  );
}

export default CustomerAdmin;
