import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import FilterControl, { FilterType } from '@murphy-frontend/web-core/components/FilterControl';
import { TimeZoneType, getConvertedDate, } from "@murphy-frontend/common/services/TimeService"
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useAdminUserPreferences } from '../../../contexts/AdminUserPreferencesContext';
import { TrainingModel, UpdateTrainingRequest } from '../api/Training/TrainingApi';
import { useGetTraining } from '../api/Training/queries';
import { useGetTrainingSessions } from '../api/TrainingSessions/queries';
import { useEditTraining } from '../api/Training/mutations';
import CourseSessionList from '../components/CourseSessionList';
import CourseForm, { CourseFormData } from '../components/CourseForm';
import { useGetCustomers } from '../../CustomerAdmin/api/queries';
import { TrainingSessionModel } from '../api/TrainingSessions/TrainingSessionsApi';
import TabPanel, { TabType } from '@murphy-frontend/web-core/components/Tabs/TabPanel';
import TrainingFiles from '../components/TrainingFiles';
import { TrainingSessionViewModel } from '../models';

const UpdateCourse: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const trainingId = parseInt(id, 10);

  const { timeZone } = useAdminUserPreferences();
  const { currentSize } = useWindowDimensions();

  const [course, setCourse] = useState<TrainingModel>(null);
  const [trainingSessions, setTrainingSessions] = useState<TrainingSessionViewModel[]>([]);
  const [filteredTrainingSessions, setFilteredTrainingSessions] = useState<TrainingSessionViewModel[]>([]);
  const [selectedTabId, setSelectedTabId] = useState(1);
  const [tabs, setTabs] = useState([]);

  const [allFilters, setAllFilters] = useState([]);

  const onClickTab = (tabId: number) => {
    setSelectedTabId(tabId);
  };

  const setInitialTabs = () => {
    const initialTabs = [
      {
        id: 1,
        displayText: 'Course sessions',
      },
      {
        id: 2,
        displayText: 'Info',
      },
      {
        id: 3,
        displayText: 'Filer',
      },
    ];

    setTabs(initialTabs);
  };

  const {
    isLoading: courseIsLoading, isError: courseIsError, data: courseData, error: courseError,
  } = useGetTraining(trainingId);

  const {
    isLoading: trainingSessionsIsLoading, isError: trainingSessionsIsError,
    data: trainingSessionsData, error: trainingSessionsError,
  } = useGetTrainingSessions({
    trainingId: trainingId,
  });

  const { mutate: editTraining, isPending: isEditTrainingLoading } = useEditTraining();
  const { data: customerData, isLoading: customerIsLoading } = useGetCustomers(true, true);

  const onClickEditTrainingSession = (trainingSessionId: number) => {
    const url = `/courseadmin/courses/${trainingId}/trainingsessions/${trainingSessionId}`;
    navigate(url);
  };

  const onClickNewTrainingSession = () => {
    const url = `/courseadmin/courses/${trainingId}/trainingsessionsnew`;
    navigate(url);
  };

  const onClickBack = () => {
    navigate('/courseadmin');
  };

  const mapTraining = (cour: TrainingModel): TrainingModel => ({
    ...cour,
    HtmlDescription: cour.HtmlDescription || '<p></p>',
  });

  const mapTrainingSessions = (trainingSessionsRaw: TrainingSessionModel[], tz: TimeZoneType): TrainingSessionViewModel[] => trainingSessionsRaw.map((t) => ({
    id: t.ID,
    startdate: getConvertedDate(dayjs.utc(t.StartDateTimeUtc), tz),
    enddate: getConvertedDate(dayjs.utc(t.EndDateTimeUtc), tz),
    lastdatetimetoenter: getConvertedDate(dayjs.utc(t.LastTimeToEnterUtc), tz),
    iscompleted: dayjs.utc(t.EndDateTimeUtc) < dayjs.utc(),
    educator: t.Educator,
  }));

  const generateInitialFilters = () => {
    const allPossibleFilters = [
      {
        displayName: "Completed",
        columnname: 'iscompleted',
        filtervalue: true,
        isActive: false,
      },
      {
        displayName: "Not completed",
        columnname: 'iscompleted',
        filtervalue: false,
        isActive: false,
      },
    ];
    return allPossibleFilters;
  };

  const onToggleFilter = (filt: FilterType) => {
    const updatedFilters = allFilters.map((filter) => {
      const updatedItem = { ...filter };
      if (filter.columnname === filt.columnname && filter.filtervalue === filt.filtervalue) {
        updatedItem.isActive = !filter.isActive;
      }
      return updatedItem;
    });

    setAllFilters(updatedFilters);
  };

  useEffect(() => {
    setInitialTabs();
  }, []);

  useEffect(() => {
    const initialFilters = generateInitialFilters();
    setAllFilters(initialFilters);
    return () => {
      setAllFilters([]);
    };
  }, []);

  useEffect(() => {
    if (!courseIsLoading) {
      const mappedCourse = mapTraining(courseData);
      setCourse(mappedCourse);
    }
  }, [courseData, courseIsLoading]);

  useEffect(() => {
    if (!trainingSessionsIsLoading && timeZone) {
      const mappedTrainingSessions = mapTrainingSessions(trainingSessionsData, timeZone);
      setTrainingSessions(mappedTrainingSessions);
    }
  }, [trainingSessionsData, trainingSessionsIsLoading, timeZone]);

  useEffect(() => {
    let newFilteredTrainingSessions = [...trainingSessions];
    if (allFilters) {
      for (let i = 0; i < allFilters.length; i++) {
        const currentFilter = allFilters[i];
        if (currentFilter.isActive === true) {
          newFilteredTrainingSessions = newFilteredTrainingSessions.filter((item) => {
            if (item[currentFilter.columnname] === currentFilter.filtervalue) {
              return item;
            }
          });
        }
      }
    }
    setFilteredTrainingSessions(newFilteredTrainingSessions);
    return () => {
      setFilteredTrainingSessions([]);
    };
  }, [allFilters, trainingSessions]);

  const onSubmitHandler = (updateData: CourseFormData) => {
    const updateTrainingRequest: UpdateTrainingRequest = {
      Id: trainingId,
    };

    if (updateData.Name) {
      updateTrainingRequest.Name = updateData.Name;
    }
    if (updateData.Description) {
      updateTrainingRequest.Description = updateData.Description;
    }
    if (updateData.HtmlDescription) {
      updateTrainingRequest.HtmlDescription = updateData.HtmlDescription;
    }
    if (updateData.Language) {
      updateTrainingRequest.Language = updateData.Language;
    }
    if (updateData.CustomerId) {
      updateTrainingRequest.CustomerId = updateData.CustomerId;
    }
    if (updateData.MaxAttendees) {
      updateTrainingRequest.MaxAttendees = updateData.MaxAttendees;
    }
    if (updateData.PublicSector) {
      updateTrainingRequest.Sector = updateData.PublicSector;
    }

    if (Object.keys(updateTrainingRequest).length > 0) {
      editTraining(updateTrainingRequest, {
        onSuccess: () => {
          navigate('/courseadmin/');
        },
      });
    }
  };

  if (courseIsLoading || trainingSessionsIsLoading || customerIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="padding-bottom-60">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {course && course.Name}
          </h4>
        </div>
        <div className="two">
          <div>
            <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
          </div>
        </div>
        <div className="three" />
      </section>
      <div className="container-with-background">
        <div className="training-tabs-panel">
          <TabPanel tabs={tabs} onClickTab={onClickTab} selectedTabId={selectedTabId} />
        </div>
        {selectedTabId === 1
          && (
            <div className="training-selected-tab">
              <span className="form-section-header">COURSE SESSIONS</span>
              <div className="form-simple-container">
                <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewTrainingSession}><span className="btn-with-icon-text">Add course session</span></Button>
                <div className="filter-control-wrapper">
                  <div className="table-column-group-header-label">FILTER</div>
                  <FilterControl allFilters={allFilters} onToggleFilter={onToggleFilter} translations={undefined} />
                </div>

                {trainingSessions.length > 0
                  ? (
                    <CourseSessionList
                      currentScreenSize={currentSize}
                      trainingSessions={filteredTrainingSessions}
                      onClickEditTrainingSession={onClickEditTrainingSession}
                      onClickDeleteTrainingSession={onClickEditTrainingSession}
                    />
                  ) : <p>Inga utbildningar inlagda</p>}
              </div>
            </div>
          )}
        {selectedTabId === 2
          && course && (
            <div className="upserttraining-container">
              <div className="grid-item">
                <CourseForm
                  onSubmit={onSubmitHandler}
                  courseToBeUpdated={course}
                  isSaveLoading={isEditTrainingLoading}
                  type={'course'}
                  customers={customerData}
                />
              </div>
            </div>
          )}
        {selectedTabId === 3
          && course && (
            <div className="training-selected-tab">
              <TrainingFiles
                trainingId={course.Id}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default UpdateCourse;
