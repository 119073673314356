import { FunctionComponent, useEffect, useState } from "react";
import { useGetLocalizations } from "../api/queries";
import { DbLanguages } from "@murphy-frontend/web-core/constants";
import { OptionType } from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import MurphySelect from "@murphy-frontend/web-core/components/MurphySelect/MurphySelect";
import UpsertLocalization from "./UpsertLocalization";

interface FieldLocalizationListProps {
    fieldName: string,
    entityName: string,
    entityId: number,
    isHtml?: boolean,
}

const FieldLocalizationList: FunctionComponent<FieldLocalizationListProps> = (
    { fieldName, entityName, entityId, isHtml }
) => {
    const [selectedLanguageId, setSelectedLanguageId] = useState<number>(DbLanguages[0].id);
    const { data: localizations, isLoading } = useGetLocalizations(entityName, entityId);

    const options: OptionType[] = DbLanguages.map((p) => ({
        value: p.id,
        label: p.value,
    }));

    const handleLanguageChange = (selectedOption: OptionType | null) => {
        if (!selectedOption) {
            return;
        }

        setSelectedLanguageId(selectedOption.value as number);
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    const matchingLocalization = localizations?.find(l => l.LanguageId === selectedLanguageId
        && l.FieldName === fieldName);

    return (<div>
        <MurphySelect
            placeholder="Language"
            options={options}
            value={options.find(option => option.value === selectedLanguageId)}
            onChange={handleLanguageChange}
        />
        <div key={selectedLanguageId}>
            <UpsertLocalization
                entityFieldName={fieldName}
                entityType={entityName}
                entityId={entityId}
                languageId={selectedLanguageId}
                isHtml={isHtml || matchingLocalization?.IsHtml || false}
                currentLocalizationValue={matchingLocalization?.LocalizationValue || undefined}
            />
        </div>
    </div>
    );
}

export default FieldLocalizationList;