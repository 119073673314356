import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import UpsertRequirementBlock from '../components/UpsertRequirementBlock';

interface CreateBlockPageProps {
    // Define any props you need here
}

const CreateBlockPage: FunctionComponent<CreateBlockPageProps> = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const requirementCategoryId = searchParams.get('requirementCategoryId');
    const level = searchParams.get('level');

    const onClickBack = () => {
        navigate(-1);
    };

    const onSuccessHandler = () => {
        navigate(-1);
    };

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>New Requirement Block</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <UpsertRequirementBlock
                        onSuccess={onSuccessHandler}
                        requirementCategoryId={requirementCategoryId ? parseInt(requirementCategoryId, 10) : 0}
                        level={level ? parseInt(level, 10) : 0}
                    />
                </div>
            </section>
        </div>
    );
}

export default CreateBlockPage;
