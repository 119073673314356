import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRequirementLevel } from "../api/queries";
import Button from "@murphy-frontend/web-core/components/Button";
import UpdateRequirementLevel from "../components/UpdateRequirementLevel";

interface EditRequirementLevelPageProps {

}

const EditRequirementLevelPage: FunctionComponent<EditRequirementLevelPageProps> = () => {
    const navigate = useNavigate();
    const { level } = useParams<{ level: string }>();
    const lvl = level ? parseInt(level, 10) : 0;

    const { data, isLoading } = useGetRequirementLevel(lvl);

    const onClickBack = () => {
        navigate(-1);
    };

    const onSuccessHandler = () => {
    };

    if (isLoading || !data)
        return <div>Loading...</div>;

    return (<div className="generalwrapper padding-bottom-60">
        <section className="header-container vertical-aligner">
            <div className="one">
                <h4>Level: {data.Name}</h4>
            </div>
            <div className="two controls-container">
                <div>
                    <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                </div>
            </div>
            <div className="three" />
        </section>
        <section className="container-with-background">
            <div className="form-container">
                <div className="req-flex-container">
                    <UpdateRequirementLevel
                        onSuccess={onSuccessHandler}
                        requirementLevel={data}
                    />
                </div>
            </div>
        </section>
    </div>);
}

export default EditRequirementLevelPage;