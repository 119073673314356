import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import Button from '@murphy-frontend/web-core/components/Button';
import { getConvertedDate } from "@murphy-frontend/common/services/TimeService"
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useCreateTrainingFile, useDeleteTrainingFile, useEditTrainingFile } from '../api/TrainingFiles/mutations';
import FileUploadControl from '@murphy-frontend/web-core/components/FileUploadControl';
import { useAdminUserPreferences } from '../../../contexts/AdminUserPreferencesContext';
import { useGetTrainingFiles } from '../api/TrainingFiles/queries';
import FileList, { FileListFile } from '@murphy-frontend/web-core/components/FileList';
import EditFile from '@murphy-frontend/web-core/components/EditFile';
import { CreateTrainingFileRequest, TrainingFileModel } from '../api/TrainingFiles/models';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { TrainingFileApi } from '../api/TrainingFiles/TrainingFileApi';
import { downloadFromLink } from '@murphy-frontend/common/utils';

interface TrainingFilesProps {
    trainingId: number;
}

const TrainingFiles: FunctionComponent<TrainingFilesProps> = ({ trainingId }) => {
    const [error, setError] = useState(null);

    const [files, setFiles] = useState<Array<FileListFile>>([]);
    const [filteredFiles, setFilteredFiles] = useState<Array<FileListFile>>([]);

    const [currentSearchText, setCurrentSearchText] = useState('');

    const { openModal, closeModal } = useModal();
    const { timeZone } = useAdminUserPreferences();

    const {
        data: trainingFilesData, isLoading: trainingFilesIsLoading,
        error: trainingFilesError, isError: trainingFilesIsError,
    } = useGetTrainingFiles(trainingId);

    const { mutate: deleteFile, isPending: deleteTrainingFileIsLoading } = useDeleteTrainingFile(trainingId);
    const { mutate: editFile, isPending: editTrainingFileIsLoading } = useEditTrainingFile(trainingId);
    const { mutate: createFile, isPending: createFileIsLoading, isError: createFileIsError } = useCreateTrainingFile(trainingId);

    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);

    function DeleteConfirmationBody({ name }: { name: string }) {
        return (
            <p>
                Delete file
                {' '}
                <strong>{name}</strong>
                {' '}
            </p>
        );
    }

    useEffect(() => {

        const handleDownload = async (fileId: string, fileName: string) => {
            const trainingFileApi = new TrainingFileApi(apiService);
            const file = await trainingFileApi.download(fileId);
            downloadFromLink(fileName, file);
        }

        const mapFiles = (filesArray: TrainingFileModel[]): Array<FileListFile> => filesArray.map((file) => ({
            id: file.ID,
            filename: file.FileName,
            createddatetime: getConvertedDate(dayjs.utc(file.CreatedDateTime), timeZone),
            fileId: file.FileId,
            filepath: file.FilePath,
            isEditable: true,
            onClickDownloadOverride: () => { handleDownload(file.FileId, file.FileName) },
        }));

        if (!trainingFilesIsLoading && trainingFilesData) {
            const mappedFiles = mapFiles(trainingFilesData);
            setFiles(mappedFiles);
        }
    }, [trainingFilesIsLoading, trainingFilesData, timeZone, apiService]);

    const submitRemoveFile = (trainingFileId: number) => {
        deleteFile(trainingFileId);
    };

    const onSubmitNewFile = useCallback((file: File) => {
        const createTrainingFileRequest: CreateTrainingFileRequest = {
            fileToUpload: file,
            trainingId,
        };

        createFile(createTrainingFileRequest, {
            onSettled: () => {
                closeModal();
            },
        });
    }, [createFile, trainingId, closeModal])

    const onClickNewFile = () => {
        const fileUploadComponent = (
            <div>
                <FileUploadControl
                    onClose={() => closeModal()}
                    onSubmit={onSubmitNewFile} />
            </div>
        );

        const modalConf = {
            title: "Add file",
            body: fileUploadComponent,
            buttonText: 'Ok',
            type: 'info',
            hideButton: true,
        };
        openModal(modalConf);
    };

    useEffect(() => {
        if (createFileIsLoading === true) {
            closeModal();
        }
    }, [closeModal, createFileIsLoading]);

    const onClickEditFile = (fileRow: FileListFile) => {
        const modalConf = {
            title: "Edit file",
            body: <EditFile
                id={fileRow.id}
                nameInit={fileRow.filename}
                onSuccess={() => closeModal()}
                onFail={() => closeModal()}
                onSaveEdit={editFile}
            />,
            hideButton: true,
            type: 'info',
        };

        openModal(modalConf);
    };

    const onClickRemoveFile = (fileRow: FileListFile) => {
        const modalConf = {
            title: "Delete file",
            body: <DeleteConfirmationBody name={fileRow.filename} />,
            hideButton: false,
            buttonText: "Delete",
            type: 'warning',
            okCallback: () => submitRemoveFile(fileRow.id),
        };

        openModal(modalConf);
    };

    const onSearchChanged = (event) => {
        const newValue = event.target.value;
        setCurrentSearchText(newValue);
    };

    useEffect(() => {
        if (files) {
            let newFilteredFiles = [...files];

            if (currentSearchText) {
                const searchValue = currentSearchText.toUpperCase();
                newFilteredFiles = newFilteredFiles.filter((p) => p.filename.toUpperCase().startsWith(searchValue));
            }

            setFilteredFiles(newFilteredFiles);
        }
    }, [files, currentSearchText, timeZone]);

    useEffect(() => {
        function ErrorMessage() {
            return <p>{error}</p>;
        }

        if (error) {
            const modalConf = {
                title: 'Error',
                body: <ErrorMessage />,
                buttonText: 'Ok',
                type: 'error',
                okCallback: () => { setError(null); },
            };
            openModal(modalConf);
        }
    }, [error, openModal]);

    if (trainingFilesIsLoading || createFileIsLoading || deleteTrainingFileIsLoading || editTrainingFileIsLoading) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return (
        <div className="generalwrapper">
            <section className="header-container vertical-aligner">
                <div className="one" />
                <div className="two">
                    <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewFile}>
                        <span className="btn-with-icon-text">
                            Add file
                        </span>
                    </Button>
                </div>
                <div className="three">
                    <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder="Search" />
                </div>
            </section>
            <section className="user-table-container">
                <div>
                    <FileList
                        files={filteredFiles}
                        onClickRemoveFile={onClickRemoveFile}
                        onClickEditFile={onClickEditFile}
                    />
                </div>

            </section>
        </div>
    );
}

export default TrainingFiles;