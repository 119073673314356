import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { CreateUserRequest, MurphyUserAdmin, UpdateUserRequest, UserInfoModel } from "./models";

export class UserApi {
    
    constructor(
        private apiService: IApiService,
    ) { }

    getMe = async (): Promise<UserInfoModel> => {
        const url = `api/Me`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getUsers = async (
        {
            objectId = '',
            customerId = '',
            includeTrainingInfo = true,
        } = {},
    ): Promise<MurphyUserAdmin[]> => {
        const baseUrl = '/api/User';
        const searchParams = new URLSearchParams();

        if (customerId) {
            searchParams.append('customerId', customerId);
        }
        if (includeTrainingInfo) {
            searchParams.append('includeTrainingInfo', includeTrainingInfo.toString());
        }
        if (objectId) {
            searchParams.append('objectId', objectId);
        }
        const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        const url = `${baseUrl}${searchParamsString}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getUser = async (customerUserId: string, includeTrainingInfo: boolean): Promise<MurphyUserAdmin> => {
        const url = `/api/User/${customerUserId}?includeTrainingInfo=${includeTrainingInfo}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateUser = (customerUserId: string, updateUserRequest: UpdateUserRequest) => {
        const url = `/api/User/${customerUserId}`;
        return this.apiService.callApi(url, 'PUT', updateUserRequest);
    }

    createUser = (createUserRequest: CreateUserRequest) => {
        const url = '/api/User';
        return this.apiService.callApi(url, 'POST', createUserRequest);
    }

    addCallingUserToCustomer = (customerId: string) => {
        const url = `/api/User/Myself`;
        return this.apiService.callApi(url, 'POST', { customerId });
    }

    deleteUser = (customerUserId: string) => {
        const url = `/api/User/${customerUserId}`;
        return this.apiService.callApi(url, 'DELETE');
    }

    resetMfa(customerUserId: string): Promise<unknown>
    {
        const url = `/api/User/${customerUserId}/ResetMfa`;
        return this.apiService.callApi(url, 'PATCH');
    }
}