import { FunctionComponent } from "react";
import { EventFlowBlockDto } from "../../../api/models";
import TitleWithControls from "./TitleWithControls";

interface TextComponentProps {
    eventFlowBlock: EventFlowBlockDto;
    onUpdate: (eventFlowBlock: EventFlowBlockDto) => void;
    onDelete: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    maxRanking: number;
}

const TextComponent: FunctionComponent<TextComponentProps> = (
    { eventFlowBlock,
        onUpdate,
        onDelete,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking
    }
) => {

    const content = <div className="m-text">
        {eventFlowBlock.TextValue}
    </div>;

    return (<div className="m-spacing">
        <div className="m-text">
            <TitleWithControls
                content={content}
                eventFlowBlock={eventFlowBlock}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onClickDecrementRanking={onClickDecrementRanking}
                onClickIncrementRanking={onClickIncrementRanking}
                maxRanking={maxRanking}
            />
        </div>
    </div>);
}

export default TextComponent;