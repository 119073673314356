import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { AlertTemplateDTO, CreateAlertTemplateDTO, UpdateAlertTemplateDTO } from "./models";
import { AxiosResponse } from "axios";

export class AlertTemplateAdminApi {

    constructor(private apiService: IApiService) { }

    getAlertTemplates = async (customerId?: string): Promise<AlertTemplateDTO[]> => {
        const baseUrl = 'api/AlertTemplate';
        const searchParams = new URLSearchParams();

        if (customerId) {
            searchParams.append('customerId', customerId);
        }
        const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        const url = `${baseUrl}${searchParamsString}`;

        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getAlertTemplate = async (id: number): Promise<AlertTemplateDTO> => {
        const url = `api/AlertTemplate/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createAlertTemplate = async (dto: CreateAlertTemplateDTO): Promise<AlertTemplateDTO> => {
        const url = 'api/AlertTemplate';
        const { data } = await this.apiService.callApi(url, 'POST', dto);
        return data;
    }

    updateAlertTemplate = (dto: UpdateAlertTemplateDTO): Promise<AxiosResponse<any, any>> => {
        const url = `api/AlertTemplate/${dto.ID}`;
        return this.apiService.callApi(url, 'PUT', dto);
    }

    deleteAlertTemplate = (id: number): Promise<AxiosResponse<any, any>> => {
        const url = `api/AlertTemplate/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    }
}