import { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import Button from '@murphy-frontend/web-core/components/Button';
import CustomerForm, { CustomerFormDto, ICustomerFormData } from '../components/CustomerForm';
import { useGetCustomer, useGetCustomers } from '../api/queries';
import { useDeleteCustomer, useUpdateCustomer } from '../api/mutations';
import { MurphyCustomer, UpdateCustomerRequest } from '../api/models';
import { useGetCustomerGroups } from '../../CustomerGroups/api/queries';
import { products } from '@murphy-frontend/web-core/constants';

const UpdateCustomer: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [customer, setCustomer] = useState<CustomerFormDto>(null);

  const {
    isLoading, isError, data, error: getCustomerError,
  } = useGetCustomer(id);

  const { data: customerGroups, isLoading: isCustomerGroupsLoading } = useGetCustomerGroups();

  const {
    data: customers, isLoading: isCustomersLoading, isError: isCustomersError,
  } = useGetCustomers(true, true);

  const onClickBack = () => {
    navigate('/customers');
  };

  const { mutate: updMutate, isPending: updateCustomerIsLoading } = useUpdateCustomer(id);
  const { mutate: delMutate, isPending: deleteCustomerIsLoading } = useDeleteCustomer();

  const onClickDeleteCustomer = () => {
    delMutate(id, {
      onSuccess: () => {
        navigate('/customers');
      },
    });
  };

  const onSubmitHandler = (updateData: ICustomerFormData) => {
    const updateCustomerRequest: UpdateCustomerRequest = {};

    if (updateData.name) {
      updateCustomerRequest.NewName = updateData.name;
    }
    if (updateData.activate) {
      updateCustomerRequest.Activate = updateData.activate;
    }
    if (updateData.deactivate) {
      updateCustomerRequest.DeActivate = updateData.deactivate;
    }
    if (updateData.mfa === false || updateData.mfa === true) {
      updateCustomerRequest.MFAEnabled = updateData.mfa;
    }
    if (updateData.appmfa === false || updateData.appmfa === true) {
      updateCustomerRequest.AppMFAEnabled = updateData.appmfa;
    }
    if (updateData.smssender) {
      updateCustomerRequest.SMSsender = updateData.smssender;
    }
    if (updateData.externalcontactsenabled === false || updateData.externalcontactsenabled === true) {
      updateCustomerRequest.ExternalContactsEnabled = updateData.externalcontactsenabled;
    }
    if (updateData.activecustomerproducts) {
      updateCustomerRequest.UpdatedProductsList = updateData.activecustomerproducts;
    }
    if (updateData.allowedusers) {
      updateCustomerRequest.AllowedUsers = updateData.allowedusers;
    }
    if (updateData.statustypeid) {
      updateCustomerRequest.StatusTypeId = updateData.statustypeid;
    }
    if (updateData.customergroupid) {
      updateCustomerRequest.CustomerGroupId = updateData.customergroupid;
    }
    if (updateData.parentcustomerid) {
      updateCustomerRequest.ParentCustomerId = updateData.parentcustomerid;
    }
    if (updateData.isroot && updateData.isroot !== customer.isroot) {
      updateCustomerRequest.SetAsHierarchyRoot = updateData.isroot;
    }
    if (updateData.removefromhierarchy) {
      updateCustomerRequest.RemoveFromHierarchy = updateData.removefromhierarchy;
    }
    if (updateData.publicSector !== undefined) {
      updateCustomerRequest.PublicSector = updateData.publicSector;
    }

    if (Object.keys(updateCustomerRequest).length > 0) {
      updMutate(updateCustomerRequest, {
        onSuccess: () => {
          navigate('/customers/');
        },
      });
    }
  };

  const mapCustomer = (cust: MurphyCustomer): CustomerFormDto => ({
    id: cust.Id,
    name: cust.Name,
    allowedusers: cust.AllowedUsers,
    activeusers: cust.ActiveUsers,
    smssender: cust.SMSsender,
    externalcontactsenabled: cust.ExternalContactsEnabled,
    mfa: cust.MFAEnabled,
    appmfa: cust.AppMFAEnabled,
    activecustomerproducts: cust.ActiveCustomerProducts,
    statustypeid: cust.StatusTypeId,
    customergroupid: cust.CustomerGroupId,
    parentcustomerid: cust.ParentCustomerId,
    isroot: cust.HierarchyId !== null && cust.ParentCustomerId == null,
    publicSector: cust.PublicSector,
  });

  useEffect(() => {
    if (data) {
      const mappedCustomer = mapCustomer(data);
      setCustomer(mappedCustomer);
    }
  }, [data]);

  if (isLoading || !customer || isCustomerGroupsLoading || isCustomersLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="generalwrapper padding-bottom-60">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {customer.name}
          </h4>
        </div>
        <div className="two controls-container">
          <div>
            <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
          </div>
          <div>
            <Button isIconButton isNegativeButton buttonStyle="btn--remove" onClick={onClickDeleteCustomer} buttonSize="btn-with-icon" />
          </div>
        </div>
        <div className="three" />
      </section>
      <section className="container-with-background">
        <div className="form-container">
          <CustomerForm
            onSubmit={onSubmitHandler}
            allProducts={products || []}
            customerToBeUpdated={customer}
            customerGroups={customerGroups || []}
            customers={customers?.filter((cust) => cust.Id !== customer.id && cust.ParentCustomerId !== customer.id && cust.HierarchyId) || []}
            isLoading={updateCustomerIsLoading || deleteCustomerIsLoading}
          />
        </div>
      </section>
    </div>
  );
}

export default UpdateCustomer;
