import { useQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { OnboardingFlowApi } from './OnboardingFlowApi';

export function useGetOnBoardingFlows() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new OnboardingFlowApi(apiService);

    return useQuery({
        queryKey: queryKeys.all,
        queryFn: () => api.getOnboardingFlows()
    });
}

export function useGetOnBoardingFlow(id: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new OnboardingFlowApi(apiService);

    return useQuery({
        queryKey: queryKeys.detail(id),
        queryFn: () => api.getOnboardingFlow(id),
    });
}
