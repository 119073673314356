import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { OnlineEducationApi } from './OnlineEducationApi';
import { CreateBaseLessonDTO, CreateLocalizedLessonDTO, UpdateLocalizedLessonDTO } from './models';


export const useCreateBaseLesson = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dto: CreateBaseLessonDTO) => onlineEducationApi.createBaseLesson(dto),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.baseLessons(variables.EntityId, variables.EntityType) });
            toast.success("Base lesson create suceeded");
        },
    });
}

export const useDeleteBaseLesson = (entityType: string, entityId: number) => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (baseLessonId: number) => onlineEducationApi.deleteBaseLesson(baseLessonId),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.baseLessons(entityId, entityType) });
            toast.success("Base lesson delete suceeded");
        },
    });
}


export const useCreateLocalizedLesson = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dto: CreateLocalizedLessonDTO) => onlineEducationApi.createLocalizedLesson(dto),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.localizedLessons(variables.BaseLessonId) });
            toast.success("Localized lesson create suceeded");
        },
    });
}

export const useUpdateLocalizedLesson = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dto: UpdateLocalizedLessonDTO) => onlineEducationApi.updateLocalizedLesson(dto),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.localizedLessons(variables.BaseLessonId) });
            toast.success("Localized lesson update suceeded");
        },
    });
}

