/* eslint-disable max-len */
import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { IconContext } from 'react-icons/lib';
import {
  SiMicrosoftteams,
} from 'react-icons/si';
import { getConvertedDate, getUtcDate } from '@murphy-frontend/common/services/TimeService';
import Button from '@murphy-frontend/web-core/components/Button';
import { customerUserTrainingSessionStates } from '@murphy-frontend/web-core/constants';
import { CustomerUserTrainingSessionStates, Products } from '@murphy-frontend/common/enums';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import { openInNewTab } from '@murphy-frontend/common/utils';

import { MurphyUser } from '@murphy-frontend/common/interfaces/IUsersApi';
import { useAdminUserPreferences } from '../../../contexts/AdminUserPreferencesContext';
import CourseSessionForm, { CourseSessionFormData } from '../components/CourseSessionForm';
import { TrainingSessionViewModel, UserTrainingSessionViewModel } from '../models';
import { TrainingSessionModel, UpdateTrainingSessionRequest } from '../api/TrainingSessions/TrainingSessionsApi';
import { useGetTrainingSession } from '../api/TrainingSessions/queries';
import { useEditTrainingSession, useInviteToTrainingSession } from '../api/TrainingSessions/mutations';
import { useGetAllUsers } from '../../UserAdmin/api/queries';
import { useCreateUserTrainingSession, useEditUserTrainingSession } from '../api/UserTrainingSession/mutations';
import { useGetUserTrainingSessionsByTrainingSessionId } from '../api/UserTrainingSession/queries';
import { UpdateCustomerUserTrainingSessionsRequest, UserTrainingSession } from '../api/UserTrainingSession/models';
import CustomerUserTrainingSessionList from '../components/CustomerUserTrainingSessionList';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import EmailInvitationForm from '../components/EmailInvitationForm';
import { useGetEducators } from '../api/Educator/queries';
import { Constant } from '@murphy-frontend/web-core/models/Constant';

const UpdateCourseSession: FunctionComponent = () => {
  const { timeZone } = useAdminUserPreferences();

  const navigate = useNavigate();
  const { id } = useParams();
  const traningSessionId = parseInt(id, 10);
  const { openModal, closeModal } = useModal();

  const [trainingSession, setTrainingSession] = useState<TrainingSessionViewModel | null>(null);
  const [customerUserTrainingSessions, setCustomerUserTrainingSessions] = useState<Array<UserTrainingSessionViewModel>>([]);
  const { data: educators, isLoading: educatorsIsLoading } = useGetEducators();

  const [trainingSessionHasBeenCompleted, setTrainingSessionHasBeenCompleted] = useState(false);

  interface TrainingSearchUserViewModel {
    id: string;
    userName: string;
    customerName: string;
  }

  const [users, setUsers] = useState<Array<TrainingSearchUserViewModel>>([]);
  const [filteredUsers, setFilteredUsers] = useState<Array<TrainingSearchUserViewModel>>([]);

  const [currentSearchText, setCurrentSearchText] = useState('');

  const { mutate: editTrainingSession, isPending: isEditTrainingSessionLoading } = useEditTrainingSession();
  const { mutate: editUserTrainingSession, isPending: isEditUserTrainingSessionLoading } = useEditUserTrainingSession();
  const { mutate: createUserTrainingSession, isPending: isCreateUserTrainingSessionLoading } = useCreateUserTrainingSession();

  const { mutate: inviteToTrainingSession, isPending: inviteToTrainingSessionIsLoading } = useInviteToTrainingSession();

  const {
    isLoading: trainingSessionIsLoading, isError: trainingSessionIsError,
    data: trainingSessionData, error: trainingSessionError,
  } = useGetTrainingSession(traningSessionId);

  const {
    isLoading: userTrainingSessionsIsLoading, isError: userTrainingSessionsIsError,
    data: userTrainingSessionsData, error: userTrainingSessionsError,
  } = useGetUserTrainingSessionsByTrainingSessionId(traningSessionId);

  const {
    isLoading: usersIsLoading, isError: usersIsError,
    data: usersData, error: usersError,
  } = useGetAllUsers();

  const onClickBack = () => {
    navigate(-1);
  };


  const mapUserTrainingSessions = (userTrainingSessionsRaw: UserTrainingSession[]): UserTrainingSessionViewModel[] => userTrainingSessionsRaw.map((t) => ({
    id: t.ID,
    customerName: t.CustomerName,
    customerUserId: t.CustomerUserId,
    userName: t.UserName,
    userEmail: t.UserEmail,
    sessionStateId: t.TrainingSessionStateId,
    sessionStateName: customerUserTrainingSessionStates.filter((p) => p.id === t.TrainingSessionStateId)[0].value,
  }));

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  const onSubmitUserTrainingSession = (customerUserId: string) => {
    const createUserTrainingSessionRequest = {
      CustomerUserId: customerUserId,
      TrainingSessionId: traningSessionId,
    };
    createUserTrainingSession(createUserTrainingSessionRequest, {
      onSuccess: () => {
        setFilteredUsers([]);
        setCurrentSearchText('');
      },
    });
  };

  const onClickAddUserToSession = (customerUser) => {
    const options = {
      onClick: () => onSubmitUserTrainingSession(customerUser.id),
      autoClose: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    };

    const confirmDialogText = `Invite ${customerUser.userName} (${customerUser.customerName})? CLICK HERE TO CONFIRM`;
    toast(confirmDialogText, options);
  };

  const onClickUnbook = (userTrainingSessionId: string) => {
    const editUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest = {
      Id: userTrainingSessionId,
      NewStateId: CustomerUserTrainingSessionStates.Cancelled,
    };

    editUserTrainingSession(editUserTrainingSessionRequest);
  };

  const onClickSetAttended = (userTrainingSessionId: string) => {
    const editUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest = {
      Id: userTrainingSessionId,
      NewStateId: CustomerUserTrainingSessionStates.Completed,
    };

    editUserTrainingSession(editUserTrainingSessionRequest);
  };

  const onClickSetNotAttended = (userTrainingSessionId: string) => {
    const editUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest = {
      Id: userTrainingSessionId,
      NewStateId: CustomerUserTrainingSessionStates.NotAttended,
    };

    editUserTrainingSession(editUserTrainingSessionRequest);
  };

  const mapUsers = (rawDataUsers: MurphyUser[]) => rawDataUsers.map((rawUser) => ({
    id: rawUser.CustomerUserId,
    userName: rawUser.Username,
    customerName: rawUser.CustomerName,
  }));

  useEffect(() => {
    if (users) {
      if (currentSearchText) {
        const alreadyBookedUsers = customerUserTrainingSessions.filter((p) => p.sessionStateId === CustomerUserTrainingSessionStates.Booked).map((p) => p.customerUserId);
        let newFilteredUsers = users.filter((p) => !alreadyBookedUsers.includes(p.id));
        const searchValue = currentSearchText.toUpperCase();
        newFilteredUsers = newFilteredUsers.filter((p) => p.userName.toUpperCase().startsWith(searchValue));
        newFilteredUsers = newFilteredUsers.slice(0, 5);
        setFilteredUsers(newFilteredUsers);
      } else {
        setFilteredUsers([]);
      }
    }
    return () => {
      setFilteredUsers([]);
    };
  }, [currentSearchText, users, customerUserTrainingSessions]);

  useEffect(() => {
    if (!usersIsLoading) {
      const usersWithTrainingProduct = usersData.filter((p) => p.Permissions.includes(Products.Training));
      const mappedUsers = mapUsers(usersWithTrainingProduct);
      setUsers(mappedUsers);
    }
    return () => {
      setUsers([]);
    };
  }, [usersIsLoading, usersData]);



  useEffect(() => {

    const mapTrainingSession = (t: TrainingSessionModel): TrainingSessionViewModel => (
      {
        id: t.ID,
        startdate: getConvertedDate(dayjs.utc(t.StartDateTimeUtc), timeZone),
        enddate: getConvertedDate(dayjs.utc(t.EndDateTimeUtc), timeZone),
        lastdatetimetoenter: getConvertedDate(dayjs.utc(t.LastTimeToEnterUtc), timeZone),
        endDateRaw: t.EndDateTimeUtc,
        urlToMeeting: t.UrlToMeeting,
        educator: t.Educator,
      });

    if (!trainingSessionIsLoading && trainingSessionData && customerUserTrainingSessionStates.length > 0) {
      const mappedTrainingSession = mapTrainingSession(trainingSessionData);
      setTrainingSession(mappedTrainingSession);
      const sessionHasBeenCompleted = dayjs.utc() > dayjs.utc(mappedTrainingSession.endDateRaw);
      setTrainingSessionHasBeenCompleted(sessionHasBeenCompleted);
    }
    return () => {
      setTrainingSession(null);
    };
  }, [trainingSessionData, trainingSessionIsLoading, timeZone]);

  useEffect(() => {
    if (!userTrainingSessionsIsLoading) {
      const mappedUserTrainingSessions = mapUserTrainingSessions(userTrainingSessionsData);
      const filteredUserTrainingSessions = mappedUserTrainingSessions.filter((p) => p.sessionStateId !== CustomerUserTrainingSessionStates.Cancelled);
      setCustomerUserTrainingSessions(filteredUserTrainingSessions);
    }
    return () => {
      setCustomerUserTrainingSessions([]);
    };
  }, [userTrainingSessionsIsLoading, userTrainingSessionsData]);

  const onSubmitHandler = (updateData: CourseSessionFormData) => {
    const updateTrainingSessionsRequest: UpdateTrainingSessionRequest = {
      Id: traningSessionId,
    };

    if (updateData.startdate) {
      updateTrainingSessionsRequest.StartTimeUtc = getUtcDate(updateData.startdate, timeZone);
    }
    if (updateData.enddate) {
      updateTrainingSessionsRequest.EndTimeUtc = getUtcDate(updateData.enddate, timeZone);
    }
    if (updateData.educator) {
      updateTrainingSessionsRequest.EducatorId = updateData.educator;
    }

    if (Object.keys(updateTrainingSessionsRequest).length > 0) {
      editTrainingSession(updateTrainingSessionsRequest);
    }
  };

  const onClickInviteExternal = () => {

    const modalConfig: ModalConfiguration = {
      type: 'info',
      centerContent: true,
      title: 'Invite external email',
      body: <div>
        <EmailInvitationForm onSubmit={onSubmitInviteExternal} />
      </div>,
      hideButton: true,
      buttonText: "Send invite",
    };

    openModal(modalConfig);
  };

  const onSubmitInviteExternal = (email: string) => {
    const inviteToTrainingSessionRequest = {
      Id: traningSessionId,
      Email: email,
    };
    inviteToTrainingSession(inviteToTrainingSessionRequest, {
      onSettled: () => {
        closeModal();
      }
    });
  }

  const searchResults = (foundUsers: TrainingSearchUserViewModel[]) => (foundUsers.length > 0 ? (
    <div className="search-results-container">
      {foundUsers.map((p) => (
        <span key={p.id} className="search-results-item" onClick={() => onClickAddUserToSession(p)}>
          {p.userName}
          {' '}
          <b>{p.customerName}</b>
        </span>
      ))}
    </div>
  ) : null);

  if (inviteToTrainingSessionIsLoading || isEditTrainingSessionLoading || educatorsIsLoading || !educators
    || userTrainingSessionsIsLoading || usersIsLoading || trainingSessionIsLoading || userTrainingSessionsIsLoading
    || isCreateUserTrainingSessionLoading || isEditUserTrainingSessionLoading) {
    return <div className="spinner-container-global-center"><Spinner /></div>;
  }

  const mappedEducators: Constant[] = educators.map((educator) => {
    return {
      value: educator.Name,
      id: educator.Id,
    };
  }
  );

  return (
    <div className="padding-bottom-60">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            Course session
          </h4>
          {trainingSession
            && (
              <h5>
                {trainingSession.startdate}
                {' - '}
                {trainingSession.enddate}
              </h5>
            )}
        </div>
        <div className="two controls-container">
          {trainingSession && trainingSession.urlToMeeting && (
            <div>
              <Button
                onClick={() => openInNewTab(trainingSession.urlToMeeting)}
              >
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                  <SiMicrosoftteams />
                  &nbsp;
                  Connect
                </IconContext.Provider>
              </Button>
            </div>
          )}
          <div>
            <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
          </div>
        </div>
        <div className="three" />
      </section>
      <section className="container-with-background">
        <div className="trainingform-container">
          <div className="grid-item">
            <CourseSessionForm
              timeZone={timeZone}
              onSubmit={onSubmitHandler}
              courseSessionToBeUpdated={trainingSessionData && trainingSessionData}
              allEducators={mappedEducators}
              trainingSessionHasBeenCompleted={trainingSessionHasBeenCompleted}
              hasAttendees={(trainingSession && customerUserTrainingSessions?.length > 0) || false}
              isSaveLoading={isEditTrainingSessionLoading}
            />
          </div>
          <div className="grid-item">
            <span className="form-section-header">DELTAGARE</span>
            <div className="form-simple-container">
              <Button onClick={onClickInviteExternal}>Bjud in extern</Button>
            </div>
            <div className="form-simple-container">
              <SearchBar
                val={currentSearchText}
                handleChange={onSearchChanged}
                placeholder="Sök och lägg till (användarnamn)"
                searchResultsContainer={searchResults(filteredUsers)}
              />
              {trainingSession && customerUserTrainingSessions.length > 0
                ? (
                  <CustomerUserTrainingSessionList
                    customerUserTrainingSessions={customerUserTrainingSessions}
                    onClickUnbook={onClickUnbook}
                    onClickSetAttended={onClickSetAttended}
                    onClickSetNotAttended={onClickSetNotAttended}
                    sessionHasBeenCompleted={trainingSessionHasBeenCompleted}
                  />
                ) : <p>Inga deltagare anmälda</p>}
            </div>
          </div>
          <div className="grid-item">

          </div>
        </div>
      </section>
    </div>
  );
}

export default UpdateCourseSession;
