import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRequirement } from '../api/queries';
import UpsertCustomerFileTypeRequirement from '../components/UpsertCustomerFileTypeRequirement';
import UpsertBasicTypeRequirement from '../components/UpsertBasicTypeRequirement';
import Button from '@murphy-frontend/web-core/components/Button';
import { Discriminators } from '../api/models';

const EditRequirementPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { requirementId } = useParams<{ requirementId: string }>();
    const reqId = requirementId ? parseInt(requirementId, 10) : 0;

    const onClickBack = () => {
        navigate(-1);
    };

    const onSuccessHandler = () => {
        navigate(-1);
    };

    const { data, isLoading } = useGetRequirement(reqId);

    if (isLoading) return <div>Loading...</div>;

    const renderUpsertElement = () => {
        switch (data?.Discriminator) {
            case Discriminators.CustomerFileTypeRequiredRequirement:
            case Discriminators.CustomerFileTypeReviewedRequirement:
                return (
                    <UpsertCustomerFileTypeRequirement
                        requirementBlockId={data.RequirementBlockId}
                        requirementId={reqId}
                        onSuccess={onSuccessHandler}
                    />
                );
            case Discriminators.BasicTypeRequirement:
                return (
                    <UpsertBasicTypeRequirement
                        requirementBlockId={data.RequirementBlockId}
                        requirement={data}
                        onSuccess={onSuccessHandler}
                    />
                );
            default:
                return null;
        }
    };

    const upsertElement = renderUpsertElement();

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>Edit Requirement</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            {data && <section className="container-with-background">
                <div className="form-container">
                    {upsertElement}
                </div>
            </section>}
        </div>
    );
}

export default EditRequirementPage;