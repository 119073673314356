import React, { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpsertCustomerGroup from '../components/UpsertCustomerGroup';
import Button from '@murphy-frontend/web-core/components/Button';
import { useGetCustomerGroup } from '../api/queries';
import Spinner from '@murphy-frontend/web-core/components/Spinner';

const UpdateCustomerGroupPage: FunctionComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const onClickBack = () => {
        navigate('/customergroups');
    };

    const { data: customerGroup, isLoading, isError } = useGetCustomerGroup(id);

    const onSuccessHandler = () => {
        navigate('/customergroups');
    };

    if (isLoading) {
        return <Spinner isGlobal />;
    }

    if (isError) {
        // Handle error case
        return <div>Error occurred while fetching customer group.</div>;
    }

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>EDIT {customerGroup?.Name}</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <UpsertCustomerGroup customerGroup={customerGroup} onSuccess={onSuccessHandler} />
                </div>
            </section>
        </div>
    );
};

export default UpdateCustomerGroupPage;
