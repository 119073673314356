import { FunctionComponent, useEffect } from "react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@murphy-frontend/web-core/components/Button';
import { useForm } from 'react-hook-form';
import Textarea from "@murphy-frontend/web-core/components/TextArea";

const schema = yup.object().shape({
    Value: yup.string().nullable().notRequired(),
});

export interface LocalizationFormDto {
    Value?: string,
}

interface LocalizationsFormProps {
    currentLocalization?: LocalizationFormDto;
    onSubmit: (candidate: LocalizationFormDto) => void,
    isLoading?: boolean,
}

const LocalizationForm: FunctionComponent<LocalizationsFormProps> = (
    {
        currentLocalization,
        onSubmit,
        isLoading,
    }
) => {

    const { reset, register, control, setValue, handleSubmit, formState: { errors, isDirty } } = useForm<LocalizationFormDto>({
        resolver: yupResolver(schema),
        defaultValues: {
            Value: "",
        }
    },);


    const onSubmitHandler = (data: LocalizationFormDto) => {
        onSubmit(data);
    };


    useEffect(() => {
        if (currentLocalization) {
            reset(currentLocalization);
        } else {
            reset({ Value: '' }); // Explicitly reset the Value to an empty string
        }
    }, [reset, currentLocalization]);


    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section localizations-form">
                        <Textarea
                            isControlled
                            register={register}
                            name="Value"
                            placeholder="Localized Value"
                            title="Localized Value"
                            errorMessage={errors.Value?.message}
                            rows={20}
                        />
                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default LocalizationForm;