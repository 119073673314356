import React, { useState, useEffect, FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import {
    RiPencilFill, RiDeleteBin7Fill,
} from 'react-icons/ri';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import TableCellActionDropDownButton from '@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton';
import { createColumnGroup, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { TrainingSessionViewModel } from '../models';

interface CourseSessionListProps {
    trainingSessions: TrainingSessionViewModel[];
    onClickEditTrainingSession: (id: number) => void;
}

const CourseSessionList: FunctionComponent<CourseSessionListProps> = (
    {
        trainingSessions,
        onClickEditTrainingSession,
    }
) => {
    const [columnGroups, setColumnGroups] = useState([]);
    const [currentSortingColumn, setCurrentSortingColumn] = useState('startdate');
    const [sortedAscending, setSortedAscending] = useState(false);
    const [hideFooter, setHideFooter] = useState(true);

    useEffect(() => {

        const sortByColumn = (columnKey) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (row: TrainingSessionViewModel) => {
            const items = [];
            items.push(
                (
                    <li key="edit" onClick={() => onClickEditTrainingSession(row.id)}>
                        <div className="vertical-aligner">
                            <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                                <RiPencilFill />
                            </IconContext.Provider>
                            <span>Edit</span>
                        </div>
                    </li>
                ),
            );
            return items;
        };

        const createMainColumnGroup = (trainingSessionsArray: TrainingSessionViewModel[]) => {
            const trainingSessionInfoCols = [
                {
                    columnKey: 'startdate',
                    displayName: 'Start',
                    isSortable: true,
                    size: 'column-large',
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'enddate',
                    displayName: 'Slut',
                    isSortable: false,
                    size: 'column-medium',
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'educator',
                    displayName: 'Utbildningsledare',
                    isSortable: true,
                    size: 'column-medium',
                    onClickColumn: sortByColumn,
                },
            ];
            const trainingSessionInfoRows = trainingSessionsArray.map((row) => ([
                {
                    columnKey: 'startdate',
                    id: `startdate-${row.id}`,
                    rowId: row.id,
                    displayValue: <TableCellActionDropDownButton
                        onClickRow={() => onClickEditTrainingSession(row.id)}
                        displayText={row.startdate}
                        listItems={generateDropDownListItems(row)}
                        columnSize="column-large"
                    />,
                    customClass: row.iscompleted === true ? 'background-light-blue' : 'background-light-green',
                },
                {
                    columnKey: 'enddate',
                    id: `enddate-${row.id}`,
                    rowId: row.id,
                    displayValue: row.enddate,
                },
                {
                    columnKey: 'educator',
                    id: `educator-${row.id}`,
                    rowId: row.id,
                    displayValue: row.educator,
                },
            ]));

            const dashboardInfoColumnGroup = createColumnGroup('', trainingSessionInfoCols, trainingSessionInfoRows);
            return dashboardInfoColumnGroup;
        };

        if (trainingSessions) {
            const allColumnGroups = [];
            const trainingSessionsCopy = [...trainingSessions];

            if (currentSortingColumn) {
                trainingSessionsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const mainColGroup = createMainColumnGroup(trainingSessionsCopy);
            allColumnGroups.push(mainColGroup);
            setColumnGroups(allColumnGroups);
            const newHideFooter = allColumnGroups.map((p) => p.rows).flat().length < 10;
            setHideFooter(newHideFooter);
        }
    }, [trainingSessions, sortedAscending, currentSortingColumn, onClickEditTrainingSession]);

    return (
        <ColumnGroupTable
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
            hidePageInputs
            hideFooter={hideFooter}
        />
    );
}

export default CourseSessionList;