import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export class ContactGroupApi {
    apiService: IApiService;

    constructor(apiService: IApiService) {
        this.apiService = apiService;
    }
    getContactGrpById = async (id: number, includeContacts = true, includeUsers = true) => {
        const url = `/api/ContactGroup/${id}?includeContacts=${includeContacts}&includeUsers=${includeUsers}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getUsersByContactGrpId = async (contactGroupId: number) => {
        const url = `/api/ContactGroupUser/${contactGroupId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getContactGroupsByCustId = async (customerId: string, includeContacts = true, includeUsers = true) => {
        const url = `/api/ContactGroup?customerId=${customerId}&includeContacts=${includeContacts}&includeUsers=${includeUsers}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getCustomerContactGroups = (customerId: string, includeContacts: boolean, includeUsers: boolean) => {
        let includeContactsVal = false;
        let includeUsersVal = false;

        if (includeContacts === true) {
            includeContactsVal = true;
        }
        if (includeUsers === true) {
            includeUsersVal = true;
        }

        const url = `/api/ContactGroup?customerId=${customerId}&includeContacts=${includeContactsVal}&includeUsers=${includeUsersVal}`;
        return this.apiService.callApi(url, 'GET');
    };
}