import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '@murphy-frontend/web-core/components/Input';
import Button from '@murphy-frontend/web-core/components/Button';

const educatorSchema = yup.object().shape({
    Name: yup.string().required(),
    Email: yup.string().email().required(),
});

export interface EducatorFormDto {
    Name: string;
    Email: string;
}

interface EducatorFormProps {
    isLoading?: boolean;
    educator?: EducatorFormDto;
    onSubmit: (data: EducatorFormDto) => void;
}

const EducatorForm: React.FC<EducatorFormProps> = ({
    isLoading,
    educator,
    onSubmit,
}) => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
    } = useForm<EducatorFormDto>({
        resolver: yupResolver(educatorSchema),
        defaultValues: {
            Name: '',
        },
    });

    useEffect(() => {
        if (educator) {
            reset(educator);
        }
    }, [educator, reset]);

    const onSubmitHandler = (data: EducatorFormDto) => {
        onSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section">
                        <Input
                            isControlled
                            register={register}
                            name="Name"
                            placeholder="Name"
                            label="Name"
                            errorMessage={errors.Name?.message}
                        />
                        <Input
                            isControlled
                            register={register}
                            name="Email"
                            placeholder="Email"
                            type="email"
                            label="Email"
                            errorMessage={errors.Name?.message}
                        />
                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EducatorForm;
