import React, { FunctionComponent } from 'react';
import RequirementBlockRow from './RequirementBlockRow';
import { useGetRequirementBlocks, useGetRequirementLevels } from '../api/queries';
import { IconContext } from 'react-icons/lib';
import { RiPencilFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
interface RequirementLevelListProps {
    requirementCategoryId: number;
}

const RequirementLevelList: FunctionComponent<RequirementLevelListProps> = ({ requirementCategoryId }) => {

    const navigate = useNavigate();

    const { data, isLoading } = useGetRequirementBlocks(requirementCategoryId);
    const { data: requirementLevels, isLoading: requirementLevelsLoading } = useGetRequirementLevels();

    if (isLoading || requirementLevelsLoading) {
        return <div>Loading...</div>
    }

    const editIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon party-icon' }}>
        <RiPencilFill />
    </IconContext.Provider>;

    const handleEditLevelClick = (level: number) => {
        navigate("/requirements/levels/" + level);
    }

    return (
        <div>
            {requirementLevels && requirementLevels.map((level) => {
                const requirementBlocks = data?.filter(r => r.Level === level.Level);
                if (!requirementBlocks) {
                    return null;
                }
                return <>
                    <div className='vertical-aligner gap-10'>
                        <div>{level.Name}</div>
                        <div onClick={() => handleEditLevelClick(level.Level)}>{editIcon}</div>
                    </div>
                    <RequirementBlockRow
                        key={level.Level}
                        blocks={requirementBlocks}
                        level={level.Level}
                        requirementCategoryId={requirementCategoryId} />
                </>
            }).reverse()}
        </div>
    );
}

export default RequirementLevelList;
