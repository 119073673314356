import { FunctionComponent, useEffect } from "react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@murphy-frontend/web-core/components/Button';
import { useForm } from 'react-hook-form';
import ControlledFormSelect, { OptionType } from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import { DefaultCustomerFileTypes } from "@murphy-frontend/web-core/constants";

const schema = yup.object().shape({
    Type: yup.string().required(),
    CustomerFileTypeId: yup.number().required(),
});

interface CustomerFileTypeRequirementFormProps {
    isLoading?: boolean;
    onSubmit: (data: CustomerFileTypeRequirementFormDto) => void;
    requirement?: CustomerFileTypeRequirementFormDto | undefined;
}

export interface CustomerFileTypeRequirementFormDto {
    Type: 'reviewed' | 'required';
    CustomerFileTypeId: number;
}

const CustomerFileTypeRequirementForm: FunctionComponent<CustomerFileTypeRequirementFormProps> = (
    {
        isLoading,
        onSubmit,
        requirement
    }
) => {
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        register,
        reset,
        setValue,
    } = useForm<CustomerFileTypeRequirementFormDto>({
        resolver: yupResolver(schema),
        defaultValues: {
        },
    });

    useEffect(() => {
        if (requirement) {
            reset(requirement);
        }
    }, [requirement, reset]);

    const onSubmitHandler = (data: CustomerFileTypeRequirementFormDto) => {
        onSubmit(data);
    };

    const customerFileTypeOptions: OptionType[] = DefaultCustomerFileTypes.map((customerFileType) => ({
        value: customerFileType.id,
        label: customerFileType.value,
    }));

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section">
                        <ControlledFormSelect
                            control={control}
                            title="CustomerFileType"
                            name="CustomerFileTypeId"
                            label="CustomerFileType"
                            options={customerFileTypeOptions}
                            isMulti={false}
                        />
                        <ControlledFormSelect
                            control={control}
                            title="Type"
                            name="Type"
                            label="Type"
                            options={[{
                                value: 'reviewed',
                                label: "Reviewed"
                            }, {
                                value: 'required',
                                label: "Required"
                            }
                            ]
                            }
                            isMulti={false}
                        />
                        <div className="dashboardform-container-section">
                            <div className='form-buttons save-button'>
                                <Button isLoading={isLoading} disabled={!isDirty || (errors && errors.length > 0)} type="submit" buttonStyle="btn--primary" buttonSize="btn--medium">Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default CustomerFileTypeRequirementForm;