import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import { TrainingTypes } from '@murphy-frontend/common/enums';
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useGetTrainings } from './api/Training/queries';
import CourseList from './components/CourseList';
import { TrainingModel } from './api/Training/TrainingApi';


const CourseAdmin: FunctionComponent = () => {
  const navigate = useNavigate();
  const { currentSize } = useWindowDimensions();

  const [currentSearchText, setCurrentSearchText] = useState('');

  const [courses, setCourses] = useState<Array<TrainingModel>>([]);
  const [filteredCourses, setFilteredCourses] = useState<Array<TrainingModel>>([]);

  const {
    isLoading, isError, data, error,
  } = useGetTrainings();

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  const onClickNewCourse = () => {
    navigate('/courseadmin/coursenew');
  };

  const onClickEditCourse = (id: number) => {
    const url = `/courseadmin/courses/${id}`;
    navigate(url);
  };

  useEffect(() => {
    if (!isLoading) {
      const courseTrainings = data.filter((p) => p.TrainingTypeId === TrainingTypes.Course);
      setCourses(courseTrainings);
    }
    return () => {
      setCourses([]);
    };
  }, [isLoading, data]);

  useEffect(() => {
    if (courses) {
      let newFilteredCourses = [...courses];
      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredCourses = newFilteredCourses.filter((p) => p.Name.toUpperCase().startsWith(searchValue));
      }
      setFilteredCourses(newFilteredCourses);
    }
  }, [currentSearchText, courses]);

  if (isLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            COURSE ADMIN
          </h4>
        </div>
        <div className="two">
          <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewCourse}>
            <span className="btn-with-icon-text">
              New Course
            </span>
          </Button>
        </div>
        <div className="three">
          <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder="Search" />
        </div>
      </section>
      <section className="user-table-container">
        <div>
          <CourseList
            courses={filteredCourses}
            currentScreenSize={currentSize}
            onClickEditCourse={onClickEditCourse}
          />
        </div>

      </section>
    </div>
  );
}

export default CourseAdmin;
