
import { useEffect, FunctionComponent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RequirementLevelList from './components/RequirementLevelList';
import MurphySelect from '@murphy-frontend/web-core/components/MurphySelect/MurphySelect';
import { RequirementCategories } from '@murphy-frontend/web-core/constants';
import Button from '@murphy-frontend/web-core/components/Button';

interface RequirementAdminProps {

}

const RequirementAdmin: FunctionComponent<RequirementAdminProps> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentCategoryId = searchParams.get('requirementCategoryId');
    const navigate = useNavigate();

    // Find the current category object based on the ID from the search parameters
    const currentCategory = RequirementCategories.find(c => c.id.toString() === currentCategoryId) || RequirementCategories[0];

    // Define the options for MurphySelect
    const options = RequirementCategories.map((p) => ({
        value: p.id,
        label: p.value,
    }));

    useEffect(() => {
        // Set the default search param if not present
        if (!searchParams.get('requirementCategoryId')) {
            setSearchParams({ requirementCategoryId: RequirementCategories[0].id.toString() });
        }
    }, [setSearchParams, searchParams]);

    // Update the searchParams when a new category is selected
    const handleCategoryChange = (selectedOption) => {
        setSearchParams({ requirementCategoryId: selectedOption.value.toString() });
    };

    const onClickEditCategory = () => {
        navigate(`/requirements/categories/${currentCategoryId}`);
    }

    return (<div className="generalwrapper">
        <section className="header-container vertical-aligner">
            <div className="one">
                <h4>Requirement Builder</h4>
            </div>
            <div className="two">
            </div>
        </section>
        <section>
            <div className="table-column-group-header-label">FILTER</div>
            <MurphySelect
                placeholder="Select a Requirement Category"
                options={options}
                value={options.find(option => option.value === currentCategory.id)}
                onChange={handleCategoryChange}
            />
        </section>
        {currentCategoryId && <>
            <section className="user-table-container">
                <Button onClick={onClickEditCategory}>
                    Edit Category
                </Button>
            </section>

            <section className="user-table-container">
                <RequirementLevelList requirementCategoryId={parseInt(currentCategoryId, 10)}
                />
            </section>
        </>}
    </div>
    );
}

export default RequirementAdmin;


