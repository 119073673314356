import { FunctionComponent } from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import LessonListRow from './LessonListRow';
import { BaseLessonDTO } from '../api/models';

interface LessonListProps {
    lessons: BaseLessonDTO[] | undefined;
    onClickEditLesson?: (baseLessonId: number) => void;
    onClickAddLesson?: () => void;
    onClickDeleteLesson?: (baseLessonId: number) => void;
}

const LessonList: FunctionComponent<LessonListProps> = ({ onClickDeleteLesson, onClickAddLesson, onClickEditLesson, lessons }) => {
    return (
        <div className='requirement-list'>
            <div className='header'>
                <div>
                    <h4>
                        Lessons
                    </h4>
                </div>
                <div>
                    <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickAddLesson} />
                </div>
            </div>
            <div>
                {lessons && lessons.map((Lesson) => (
                    <LessonListRow
                        lesson={Lesson}
                        key={Lesson.Id}
                        onClickEditLesson={onClickEditLesson}
                        onClickDeleteLesson={onClickDeleteLesson}
                    />
                ))}
            </div>
        </div>

    );
}

export default LessonList;
