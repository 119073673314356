import React, { useState } from "react";
import { EventFlowTabDto } from "../../api/models";
import { IconContext } from "react-icons/lib";
import { RiDeleteBin7Fill, RiPencilFill } from "react-icons/ri";
import TableCellActionDropDownButton from "@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton";
import Button from "@murphy-frontend/web-core/components/Button";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

interface EventFlowEventFlowTabsProps {
    tabs: EventFlowTabDto[];
    selectedTabIndex: number;
    setSelectedTabIndex: (newIndex: number) => void;
    onClickAddTab: () => void;
    onClickDeleteTab: (tab: EventFlowTabDto) => void;
    onClickEditTab: (tab: EventFlowTabDto) => void;
    onClickIncrementRanking: (tab: EventFlowTabDto) => void;
    onClickDecrementRanking: (tab: EventFlowTabDto) => void;
    maxRanking: number;
}

const EventFlowTabs: React.FC<EventFlowEventFlowTabsProps> = (
    {
        tabs,
        selectedTabIndex,
        setSelectedTabIndex,
        onClickAddTab,
        onClickDeleteTab,
        onClickEditTab,
        onClickIncrementRanking,
        onClickDecrementRanking,
        maxRanking
    }
) => {

    const generateActionDropDownButton = (template: EventFlowTabDto) => {
        return <TableCellActionDropDownButton
            displayText={template.Name}
            listItems={generateDropDownListItems(template)}
            columnSize="column-medium"
            onClickRow={() => { }}
        />
    }

    const generateDropDownListItems = (template: EventFlowTabDto) => {
        const ddlItems = [];

        ddlItems.push(
            <li key="edit" onClick={() => onClickEditTab(template)}>
                <div className="vertical-aligner">
                    <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                        <RiPencilFill />
                    </IconContext.Provider>
                    <span>Edit</span>
                </div>
            </li>
        );

        ddlItems.push(
            <li key="delete" onClick={() => onClickDeleteTab(template)}>
                <div className="vertical-aligner">
                    <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                        <RiDeleteBin7Fill />
                    </IconContext.Provider>
                    <span>Delete</span>
                </div>
            </li>
        );

        if (template.Ranking > 1) {
            ddlItems.push(
                <li key="decrementranking" onClick={() => onClickDecrementRanking(template)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <IoChevronBack />
                        </IconContext.Provider>
                        <span>Move left</span>
                    </div>
                </li>
            );
        }

        if (template.Ranking < maxRanking) {
            ddlItems.push(
                <li key="incrementranking" onClick={() => onClickIncrementRanking(template)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <IoChevronForward />
                        </IconContext.Provider>
                        <span>Move right</span>
                    </div>
                </li>
            );
        }

        return ddlItems;
    };

    return (
        <div className="tab-titles-wrapper">
            {tabs.
                sort((a, b) => { return a.Ranking - b.Ranking; })
                .map((tab, index) => {
                    const tabClassName = index === selectedTabIndex ? "chosen-tab-title-wrapper" : "not-chosen-tab-title-wrapper";
                    const textClassName = index === selectedTabIndex ? "chosen-tab-text" : "not-chosen-tab-text";

                    return (
                        <div
                            key={`${tab.Name}-${index}`}
                            className={tabClassName}
                            onClick={() => setSelectedTabIndex(index)}
                        >
                            <span className={`drone-regular-text ${textClassName}`}>
                                {generateActionDropDownButton(tab)}
                            </span>
                        </div>
                    );
                })}
            <div
                className='chosen-tab-title-wrapper'
            >
                <span className={`drone-regular-text chosen-tab-text horizontal`}>
                    <div className="clickable-cell vertical-aligner vertical-space-flex">
                        <Button onClick={onClickAddTab}>Add tab +</Button>
                    </div>

                </span>
            </div>
        </div>
    );
};

export default EventFlowTabs;
