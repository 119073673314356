import { FunctionComponent } from "react";
import { CreateEventFlowBlockDto, EventFlowBlockDto, EventFlowTabDto, UpdateEventFlowBlockDto } from "../../api/models";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import TableCellActionDropDownButton from "@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton";
import { EventFlowComponentType } from "@murphy-frontend/common/enums";
import { useCreateBlock, useDeleteBlock, useUpdateBlock } from "../../api/mutations";
import Button from "@murphy-frontend/web-core/components/Button";
import TitleComponent from "./BlockComponents/TitleComponent";
import TextComponent from "./BlockComponents/TextComponent";
import CompleteButton from "./BlockComponents/CompleteButton";
import CheckListComponent from "./BlockComponents/CheckListComponent";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import UpdateEventFlowBlock from "./BlockComponents/UpdateEventFlowBlock";
import AccordionComponent from "./BlockComponents/AccordionComponent";

interface EventFlowTabProps {
    eventFlowTab: EventFlowTabDto;
    active: boolean;
}

const EventFlowTab: FunctionComponent<EventFlowTabProps> = ({ eventFlowTab, active }) => {

    const { mutate: addBlock, isPending: addBlockIsLoading } = useCreateBlock(eventFlowTab?.TemplateId);
    const { mutate: deleteBlock, isPending: deleteBlockIsLoading } = useDeleteBlock(eventFlowTab?.TemplateId);
    const { mutate: updateBlock, isPending: updateBlockIsPending } = useUpdateBlock(eventFlowTab?.TemplateId);

    const { openModal, closeModal } = useModal();

    const generateActionDropDownButton = () => {
        const addComponentButton = <Button>Add Component +</Button>
        return <TableCellActionDropDownButton
            component={addComponentButton}
            listItems={generateDropDownListItems()}
            columnSize="column-large"
            onClickRow={() => { }}
        />
    }

    const generateDropDownListItems = () => {
        const ddlItems = Object.values(EventFlowComponentType).filter(value => typeof value === "number").map((componentType) => {
            const typeName = EventFlowComponentType[componentType as keyof typeof EventFlowComponentType];
            return (
                <li key={typeName} onClick={() => onClickAddComponent(componentType as number, typeName)}>
                    <div className="vertical-aligner">
                        <span>{typeName}</span>
                    </div>
                </li>
            );
        });

        return ddlItems;
    };

    const onClickAddComponent = (type: number, typeName: string) => {
        const maxRanking = eventFlowTab.Blocks.reduce((prev, current) => (prev.Ranking > current.Ranking) ? prev : current, { Ranking: 0 }).Ranking;
        const dto: CreateEventFlowBlockDto = {
            EventFlowTabId: eventFlowTab.Id,
            Title: type !== EventFlowComponentType.Text ? `New ${typeName}` : undefined,
            BlockType: type,
            TextValue: type === EventFlowComponentType.Text ? `New ${typeName} Component` : undefined,
            Ranking: maxRanking + 1,
        }

        addBlock(dto)
    }

    const onEditBlock = (block: EventFlowBlockDto) => {
        const updateBlockComponent = <UpdateEventFlowBlock eventFlowBlock={block}
            onSuccess={closeModal}
            templateId={eventFlowTab.TemplateId} />

        const modal: ModalConfiguration = {
            title: "Edit Block",
            body: updateBlockComponent,
            hideButton: true,
        }
        openModal(modal);
    }

    const onClickIncrementRanking = (block: EventFlowBlockDto) => {
        const dto: UpdateEventFlowBlockDto = {
            ...block,
            Ranking: block.Ranking + 1
        }
        updateBlock(dto)
    }

    const onClickDecrementRanking = (block: EventFlowBlockDto) => {
        const dto: UpdateEventFlowBlockDto = {
            ...block,
            Ranking: block.Ranking - 1
        }
        updateBlock(dto)
    }

    const onDeleteBlock = (block: EventFlowBlockDto) => {
        deleteBlock(block.Id);
    }

    const getTabComponent = (block: EventFlowBlockDto, maxRank: number) => {
        switch (block.BlockType) {
            case EventFlowComponentType.Title:
                return <TitleComponent
                    eventFlowBlock={block}
                    onUpdate={onEditBlock}
                    onDelete={onDeleteBlock}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRank}
                />
            case EventFlowComponentType.Text:
                return <TextComponent eventFlowBlock={block}
                    onUpdate={onEditBlock}
                    onDelete={onDeleteBlock}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRank}
                />
            case EventFlowComponentType.CompletedButton:
                return <CompleteButton eventFlowBlock={block}
                    onUpdate={onEditBlock}
                    onDelete={onDeleteBlock}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRank}
                />
            case EventFlowComponentType.CheckList:
                return <CheckListComponent eventFlowBlock={block}
                    onUpdate={onEditBlock}
                    onDelete={onDeleteBlock}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRank}
                    templateId={eventFlowTab.TemplateId} />
            case EventFlowComponentType.Accordion:
                return <AccordionComponent eventFlowBlock={block}
                    onUpdate={onEditBlock}
                    onDelete={onDeleteBlock}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRank}
                />
            default:
                return (
                    <>
                        {block.Title}
                        {block.BlockType}
                    </>
                )
        }
    }

    if (!active) {
        return <></>;
    }

    if (addBlockIsLoading || deleteBlockIsLoading || updateBlockIsPending) {
        return <Spinner isGlobal />
    }

    const maxRanking = eventFlowTab.Blocks ? eventFlowTab.Blocks.reduce((prev, current) => (prev.Ranking > current.Ranking) ? prev : current, { Ranking: 0 }).Ranking : 0;
    const blocks = eventFlowTab.Blocks.sort((a, b) => {
        return a.Ranking - b.Ranking;
    }).map((block) => {
        return (
            <div key={block.Id}>
                {getTabComponent(block, maxRanking)}
            </div>
        )
    });

    return (
        <div className="blocks-wrapper">
            <div className="m-spacing"></div>
            <div className="component-add-button">
                {generateActionDropDownButton()}
            </div>

            {blocks}
        </div>
    );
}

export default EventFlowTab;