import { useQuery } from '@tanstack/react-query';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import queryKeys from './queryKeys';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { UserTrainingSessionsApi } from './UserTrainingSessionsApi';

export function useGetUserTrainingSessionsByCustomerUserId(customerUserId: string) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new UserTrainingSessionsApi(apiService);

  return useQuery({
    queryKey: queryKeys.customerList(customerUserId),
    queryFn: () => api.getUserTrainingSessionsByCustomerUserId(customerUserId),
    enabled: !!customerUserId, staleTime: Infinity
  });
}

export function useGetUserTrainingSessionsByTrainingSessionId(trainingSessionId: number) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new UserTrainingSessionsApi(apiService);

  return useQuery({
    queryKey: queryKeys.trainingSessionList(trainingSessionId),
    queryFn: () => api.getUserTrainingSessionsByTrainingSessionId(trainingSessionId),
    enabled: !!trainingSessionId, staleTime: Infinity
  });
}
