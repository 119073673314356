import { FunctionComponent } from "react";
import { EventFlowRespondBlockDto } from "../../../api/models";
import CheckListTitleWithControls from "./CheckboxTitleWithControls";

interface CheckboxInputComponentProps {
    eventFlowRespondBlock: EventFlowRespondBlockDto;
    onUpdate: (EventFlowRespondBlock: EventFlowRespondBlockDto) => void;
    onDelete: (EventFlowRespondBlock: EventFlowRespondBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowRespondBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowRespondBlockDto) => void;
    maxRanking: number;
}

const CheckboxInputComponent: FunctionComponent<CheckboxInputComponentProps> = (
    {
        eventFlowRespondBlock,
        onUpdate,
        onDelete,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking,
    }) => {

    const content = <span>
        {eventFlowRespondBlock.Text}
    </span>;

    return (
        <>
            <div className="m-spacing"></div>
            <div className="drone-checklist-checkbox-wrapper">
                <div className="drone-text-wrapper">
                    <div className="drone-text-input-label">
                        <CheckListTitleWithControls
                            content={content}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                            eventFlowRespondBlock={eventFlowRespondBlock}
                            onClickDecrementRanking={onClickDecrementRanking}
                            onClickIncrementRanking={onClickIncrementRanking}
                            maxRanking={maxRanking}
                        />
                    </div>
                </div>
                <div className="drone-checkbox-wrapper">
                    <input type="checkbox" className="drone-checkbox"></input>
                </div>
            </div>
        </>
    );
}

export default CheckboxInputComponent;