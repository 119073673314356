import { FunctionComponent } from 'react';
import EventFlowTemplateForm, { EventFlowTemplateFormDto } from './EventFlowTemplateForm';
import { useCreateSendAlertAction, useCreateTemplate, useUpdateSendAlertAction, useUpdateTemplate } from '../api/mutations';
import { CreateEventFlowTemplateDto, CreateSendAlertActionDto, EventFlowTemplateDto, UpdateEventFlowTemplateDto, UpdateSendAlertActionDto } from '../api/models';
import { useGetAlertTemplates } from '../../AlertTemplateAdmin/api/queries';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { AlertTemplateDTO } from '../../AlertTemplateAdmin/api/models';
import { ActionTypes } from '../ActionTypes';

interface UpsertEventFlowTemplateProps {
    eventFlowTemplate?: EventFlowTemplateDto;
    onSuccess: () => void;
    customerId: string;
}

const UpsertEventFlowTemplate: FunctionComponent<UpsertEventFlowTemplateProps> = ({
    eventFlowTemplate,
    onSuccess,
    customerId,
}) => {
    const { mutate: createEventFlowTemplate, isPending: isCreating } = useCreateTemplate(customerId);
    const { mutate: updateEventFlowTemplate, isPending: isUpdating } = useUpdateTemplate(customerId);
    const { mutate: createSendAlertAction, isPending: isCreatingSendAlertAction } = useCreateSendAlertAction();
    const { mutate: updateSendAlertAction, isPending: isUpdatingSendAlertAction } = useUpdateSendAlertAction()

    const { data: alertTemplates, isLoading: alertTemplatesIsLoading } = useGetAlertTemplates(customerId);

    const onSubmitHandler = (eventFlowTemplateData: EventFlowTemplateFormDto) => {
        if (eventFlowTemplate) {
            const updatedEventFlowTemplate: UpdateEventFlowTemplateDto = {
                ...eventFlowTemplate,
                ...eventFlowTemplateData,
            };
            updateEventFlowTemplate(updatedEventFlowTemplate, {
                onSuccess: () => {
                    if (eventFlowTemplateData.AlertTemplateId) {

                        if (eventFlowTemplate.Actions.find(a => a.ActionType === ActionTypes.SendAlert) !== undefined) {
                            const updateSendAlertActionDto: UpdateSendAlertActionDto = {
                                Id: eventFlowTemplate.Actions.find(a => a.ActionType === ActionTypes.SendAlert)!.Id,
                                EventFlowTemplateId: eventFlowTemplate.Id,
                                AlertTemplateId: eventFlowTemplateData.AlertTemplateId,
                            }
                            updateSendAlertAction(updateSendAlertActionDto, {
                                onSuccess: () => {
                                    onSuccess();
                                }
                            })
                        } else {
                            const createSendAlertActionDto: CreateSendAlertActionDto = {
                                EventFlowTemplateId: eventFlowTemplate.Id,
                                AlertTemplateId: eventFlowTemplateData.AlertTemplateId,
                            }
                            createSendAlertAction(createSendAlertActionDto, {
                                onSuccess: () => {
                                    onSuccess();
                                }
                            })
                        }
                    }
                },
            });
        } else {
            const createEventFlowTemplateDto: CreateEventFlowTemplateDto = {
                ...eventFlowTemplateData,
                CustomerId: customerId,
            };
            createEventFlowTemplate(createEventFlowTemplateDto, {
                onSuccess: (data) => {
                    if (eventFlowTemplateData.AlertTemplateId) {
                        const createSendAlertActionDto: CreateSendAlertActionDto = {
                            EventFlowTemplateId: data.Id,
                            AlertTemplateId: eventFlowTemplateData.AlertTemplateId,
                        }
                        createSendAlertAction(createSendAlertActionDto, {
                            onSuccess: () => {
                                onSuccess();
                            }
                        })
                    }
                },
            });
        }
    };

    if (alertTemplatesIsLoading) {
        return <Spinner isGlobal />
    }

    const alertTemplateOptions = alertTemplates?.filter(p => p.Enabled === true && p.EventFlowEnabled === true).map((alertTemplate: AlertTemplateDTO) => ({
        value: alertTemplate.ID,
        label: alertTemplate.Name,
    }));

    if (eventFlowTemplate) {

        const formDto: EventFlowTemplateFormDto = {
            ...eventFlowTemplate
        };

        // this only works if there is only one send alert action. 
        // This is a temporary solution until we have a better way to handle this.
        const sendAlertActionConfig = eventFlowTemplate.Actions.find(a => a.ActionType === ActionTypes.SendAlert)?.Configuration;
        if (sendAlertActionConfig) {
            const alertTemplateId = JSON.parse(sendAlertActionConfig).AlertTemplateId;
            formDto.AlertTemplateId = alertTemplateId;
        }

        return (
            <div>
                <EventFlowTemplateForm
                    isLoading={isCreating || isUpdating || isCreatingSendAlertAction || isUpdatingSendAlertAction}
                    eventFlowTemplate={formDto}
                    onSubmit={onSubmitHandler}
                    alertTemplateOptions={alertTemplateOptions ?? []}
                />
            </div>
        );
    }
    return (
        <div>
            <EventFlowTemplateForm
                isLoading={isCreating || isUpdating || isCreatingSendAlertAction || isUpdatingSendAlertAction}
                onSubmit={onSubmitHandler}
                alertTemplateOptions={alertTemplateOptions ?? []}
            />
        </div>
    );
};

export default UpsertEventFlowTemplate;
