import { FunctionComponent } from 'react';
import CustomerGroupForm, { CustomerGroupFormDto } from './CustomerGroupForm';
import { CustomerGroupModel } from '../api/CustomerGroupsApi';
import { useCreateCustomerGroup, useUpdateCustomerGroup } from '../api/mutations';

interface UpsertCustomerGroupProps {
    customerGroup?: CustomerGroupModel;
    onSuccess: () => void;
}

const UpsertCustomerGroup: FunctionComponent<UpsertCustomerGroupProps> = ({
    customerGroup,
    onSuccess,
}) => {
    const { mutate: createCustomerGroup, isPending: isCreating } = useCreateCustomerGroup();
    const { mutate: updateCustomerGroup, isPending: isUpdating } = useUpdateCustomerGroup();

    const onSubmitHandler = (customerGroupData: CustomerGroupFormDto) => {
        if (customerGroup) {
            const updatedCustomerGroup: CustomerGroupModel = {
                ...customerGroup,
                ...customerGroupData,
            };
            updateCustomerGroup(updatedCustomerGroup, {
                onSuccess: () => {
                    onSuccess();
                },
            });
        } else {
            createCustomerGroup(customerGroupData, {
                onSuccess: () => {
                    onSuccess();
                },
            });
        }
    };

    return (
        <div>
            <CustomerGroupForm
                isLoading={isCreating || isUpdating}
                customerGroup={customerGroup}
                onSubmit={onSubmitHandler}
            />
        </div>
    );
};

export default UpsertCustomerGroup;
