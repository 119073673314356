import { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@murphy-frontend/web-core/components/Button';
import { useForm } from 'react-hook-form';
import RichTextEditor from "@murphy-frontend/web-core/components/RichTextEditor";
import { deserialize } from "@murphy-frontend/web-core/components/RichTextEditor/helpers/serializationHelpers";
import DOMPurify from 'dompurify';

yup.addMethod(yup.string, 'html', function () {
    return this.test({
        name: 'html',
        exclusive: true,
        message: 'Invalid text',
        test: (value) => {
            DOMPurify.sanitize(value);
            if (DOMPurify.removed.length > 0) {
                return false;
            }
            return true;
        }
    });
});

const schema = yup.object().shape({
    Value: yup.string().nullable().notRequired(),
});

export interface HtmlLocalizationFormDto {
    Value?: string,
}

interface HtmlLocalizationsFormProps {
    currentLocalization?: HtmlLocalizationFormDto;
    onSubmit: (candidate: HtmlLocalizationFormDto) => void,
    isLoading?: boolean,
}

const HtmlLocalizationForm: FunctionComponent<HtmlLocalizationsFormProps> = (
    {
        currentLocalization,
        onSubmit,
        isLoading,
    }
) => {

    const { reset, handleSubmit, formState: { errors } } = useForm<HtmlLocalizationFormDto>({
        resolver: yupResolver(schema),
        defaultValues: {
            Value: "",
        }
    },);

    const [htmlDescription, setHtmlDescription] = useState("");
    const [htmlDescriptionEditor, setHtmlDescriptionEditor] = useState(null);

    const onSubmitHandler = (data: HtmlLocalizationFormDto) => {
        const dataToSubmit: HtmlLocalizationFormDto = {};
        if (data.Value !== htmlDescription) {
            dataToSubmit.Value = htmlDescription;
        }
        onSubmit(dataToSubmit);
    };

    const onChangeHtmlDescription = (newHtmlDescription: string) => {
        setHtmlDescription(newHtmlDescription);
    };

    useEffect(() => {
        if (currentLocalization) {
            const document = new DOMParser().parseFromString(
                currentLocalization?.Value || '<p></p>',
                "text/html"
            );
            const deserializedContent = deserialize(document.body);
            setHtmlDescription(deserializedContent);
            reset(currentLocalization);
        } else {
            const initialValue = [
                {
                    type: "paragraph",
                    children: [{ text: "" }],
                },
            ];
            setHtmlDescription(initialValue);
            reset({ Value: '' }); // Explicitly reset the Value to an empty string
        }
    }, [reset, currentLocalization]);

    useEffect(() => {
        if (htmlDescription) {
            const newIntroEditor = (
                <div className="overridecontainer">
                    <RichTextEditor
                        contents={htmlDescription}
                        onChangeContents={onChangeHtmlDescription}
                    />
                </div>
            );
            setHtmlDescriptionEditor(newIntroEditor);
        } else {
            setHtmlDescriptionEditor(null);
        }
        return () => {
            setHtmlDescriptionEditor(null);
        };
    }, [htmlDescription, currentLocalization]);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section localizations-form">
                        <div className="form-simple-container">
                            <span className="form-section-header">Localized Value</span>
                            <div className="rich-text-editor-container">
                                {htmlDescriptionEditor}
                            </div>
                        </div>

                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default HtmlLocalizationForm;