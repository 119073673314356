import { FunctionComponent } from "react";
import { OnboardingFlowDTO } from "./api/models";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useGetOnBoardingFlows } from "./api/queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import OnboardingFlowsTable from "./components/OnboardingFlowsTable";

interface OnboardingFlowAdminProps {

}

const OnboardingFlowAdmin: FunctionComponent<OnboardingFlowAdminProps> = () => {

    const { data: onboardingFlows, isLoading: customerGroupsIsLoading } = useGetOnBoardingFlows();

    const { currentSize } = useWindowDimensions();
    const navigate = useNavigate()

    const onClickEdit = (onboardingFlow: OnboardingFlowDTO) => {
        const url = `/onboardingflows/${onboardingFlow.ID}`;
        navigate(url);
    };

    if (customerGroupsIsLoading) {
        return <Spinner isGlobal />;
    }

    return (
        <div className="generalwrapper">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>
                        ONBOARDING FLOWS
                    </h4>
                </div>
                <div className="two">
                </div>
            </section>
            <section className="user-table-container">
                <OnboardingFlowsTable
                    onboardingFlows={onboardingFlows ?? []}
                    currentScreenSize={currentSize}
                    onClickEditOnboardingFlow={onClickEdit} />
            </section>
        </div>
    );
}

export default OnboardingFlowAdmin;