import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import AuthServiceType, { IAuthService } from "@murphy-frontend/common/interfaces/IAuthService";
import { User } from "oidc-client-ts";
import React from "react";
import { FunctionComponent } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { useUser } from "../contexts/UserContext";

const PrivateOutlet: FunctionComponent = ({ children }: { children: React.ReactNode }) => {
    const { isError, user } = useUser();
    const authService = useInjection<IAuthService>(AuthServiceType.IAuthService);

    let userFromStorage = null;
    let isActuallyLoggedIn = false;
    const location = useLocation();
    const loginUrl = authService.getLoginUrl();

    try {
        const userStorageString = authService.getUserStorageString();
        userFromStorage = User.fromStorageString(userStorageString);
    } catch (err) {
        return <Navigate to={loginUrl} state={{ from: location }} replace /> //Go back to login if not logged in
    }

    if (userFromStorage && userFromStorage.access_token) {
        isActuallyLoggedIn = true;
    }

    if (isError) {
        return <Navigate to={loginUrl} state={{ from: location }} replace /> //Go back to login if not logged in
    }

    return isActuallyLoggedIn === true ? ( //Check if logged in
        (user ? <>
            {children}
            <Outlet />
        </> : <div className="spinner-container-global-center"><Spinner /></div>)
    ) : (
        (<Navigate to={loginUrl} state={{ from: location }} replace />) //Go back to login if not logged in
    );
};

export default PrivateOutlet;