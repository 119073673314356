import { injectable } from 'inversify';
import { IPersistenceService } from '@murphy-frontend/common/interfaces/IPersistenceService';

export const LocalStorageKeys = Object.freeze({
	SelectedCustomerId: 'selectedCustomerId',
	CurrentObjectId: 'currentObjectId',
	Language: 'lang',
	Translations: 'translations',
	SelectedTimeZone: 'selectedTimeZone',
	LogoutTriggeredFromPortal: 'logoutTriggeredFromPortal',
	SelectedCourseLanguages: 'selectedCourseLanguages',
	CourseFilter: 'courseFilter',
	Provider: 'provider',
});

@injectable()
export default class WebPersistenceService implements IPersistenceService {
	getTranslations = () => {
		return window.localStorage.getItem(LocalStorageKeys.Translations);
	};
	setTranslations = (value: string) => {
		window.localStorage.setItem(LocalStorageKeys.Translations, value);
	};
	setSelectedTimeZone = (value: string) => {
		window.localStorage.setItem(LocalStorageKeys.SelectedTimeZone, value);
	};
	getSelectedTimeZone = () => {
		return window.localStorage.getItem(LocalStorageKeys.SelectedTimeZone);
	};
	setLanguage = (value: string) => {
		window.localStorage.setItem(LocalStorageKeys.Language, value);
	};
	getLanguage = () => {
		return window.localStorage.getItem(LocalStorageKeys.Language);
	};
	setSelectedCustomerId = (id: string) => {
		window.localStorage.setItem(LocalStorageKeys.SelectedCustomerId, id);
	};
	getSelectedCustomerId = () => {
		return window.localStorage.getItem(LocalStorageKeys.SelectedCustomerId);
	};
	getCurrentObjectId = () => {
		return window.localStorage.getItem(LocalStorageKeys.CurrentObjectId);
	};
	getLogoutTriggeredFromPortal = () => {
		return (
			window.localStorage.getItem(
				LocalStorageKeys.LogoutTriggeredFromPortal,
			) === 'true' || false
		);
	};
	setSelectedCourseLanguages = (value: string) => {
		window.localStorage.setItem(
			LocalStorageKeys.SelectedCourseLanguages,
			value,
		);
	};
	getSelectedCourseLanguages = () => {
		return window.localStorage.getItem(
			LocalStorageKeys.SelectedCourseLanguages,
		);
	};
	setCourseFilter = (value: string) => {
		window.localStorage.setItem(
			LocalStorageKeys.CourseFilter,
			value,
		);
	};
	getCourseFilter = () => {
		return window.localStorage.getItem(
			LocalStorageKeys.CourseFilter,
		);
	};
	saveLogoutTriggeredFromPortal = (value: boolean) => {
		window.localStorage.setItem(
			LocalStorageKeys.LogoutTriggeredFromPortal,
			value.toString(),
		);
	};
	saveCurrentObjectId = (id: string) => {
		window.localStorage.setItem(LocalStorageKeys.CurrentObjectId, id);
	};
	saveProvider = (provider: string) => {
		window.localStorage.setItem(LocalStorageKeys.Provider, provider);
	};
	getProvider = () => {
		return window.localStorage.getItem(LocalStorageKeys.Provider);
	};

	clearNonPersistentItems = () => {
		const lang = this.getLanguage();
		const timeZone = this.getSelectedTimeZone();
		const selectedCustomerId = this.getSelectedCustomerId();
		const logoutTriggeredFromPortal = this.getLogoutTriggeredFromPortal();
		const translations = this.getTranslations();
		const selectedCourseLanguages = this.getSelectedCourseLanguages();

		window.localStorage.clear();

		if (lang) {
			this.setLanguage(lang);
		}
		if (translations) {
			this.setTranslations(translations);
		}
		if (timeZone) {
			this.setSelectedTimeZone(timeZone);
		}
		if (selectedCustomerId) {
			this.setSelectedCustomerId(selectedCustomerId);
		}
		if (logoutTriggeredFromPortal) {
			this.saveLogoutTriggeredFromPortal(logoutTriggeredFromPortal);
		}
		if (selectedCourseLanguages) {
			this.setSelectedCourseLanguages(selectedCourseLanguages);
		}
	};
}
