import NotFound from '@murphy-frontend/web-core/components/NotFound'
import Navbar from './navigation/Navbar'
import {
  Route,
  BrowserRouter as Router, Routes, Outlet,
} from 'react-router-dom';

import OidcWrapper from '@murphy-frontend/web-core/features/auth/OidcWrapper';

import CustomerAdmin from './features/CustomerAdmin'
import '@murphy-frontend/web-core/styles/main.scss';
import { WebStorageStateStore } from 'oidc-client-ts'
import { AuthProviderProps } from "react-oidc-context";
import { WebAuthProvider } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import LoginPage from './features/Login/LoginPage'
import UpdateCustomer from './features/CustomerAdmin/pages/UpdateCustomer'
import AddCustomer from './features/CustomerAdmin/pages/AddCustomer'
import { ModalProvider } from '@murphy-frontend/web-core/contexts/ModalContext'
import CustomerGroups from './features/CustomerGroups'
import AddCustomerGroupPage from './features/CustomerGroups/pages/AddCustomerGroupPage'
import UpdateCustomerGroupPage from './features/CustomerGroups/pages/UpdateCustomerGroupPage'
import { CustomerProvider } from './contexts/CustomerContext';
import AdminPortal from './navigation/AdminPortal';
import AdminUserPreferencesProvider from './contexts/AdminUserPreferencesContext';

import { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import nb from 'date-fns/locale/nb';

//dayjs locale config
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import RequirementAdmin from './features/RequirementAdmin';
import CreateBlockPage from './features/RequirementAdmin/pages/CreateBlockPage';
import EditBlockPage from './features/RequirementAdmin/pages/EditBlockPage';
import CreateRequirementPage from './features/RequirementAdmin/pages/CreateRequirementPage';
import EditRequirementPage from './features/RequirementAdmin/pages/EditRequirementPage';
import EditRequirementLevelPage from './features/RequirementAdmin/pages/EditRequirementLevelPage';
import EditRequirementCategoryPage from './features/RequirementAdmin/pages/EditRequirementCategoryPage';
import { UserProvider } from './contexts/UserContext';
import PrivateOutlet from './authentication/PrivateOutlet';
import CourseAdmin from './features/CourseAdmin';
import AddCourse from './features/CourseAdmin/pages/AddCourse';
import UpdateCourse from './features/CourseAdmin/pages/UpdateCourse';
import AddCourseSession from './features/CourseAdmin/pages/AddCourseSession';
import UpdateCourseSession from './features/CourseAdmin/pages/UpdateCourseSession';
import UsersAdmin from './features/UserAdmin';
import AddUser from './features/UserAdmin/pages/AddUser';
import EditUser from './features/UserAdmin/pages/EditUser';
import Educators from './features/CourseAdmin/pages/Educators';
import UpdateEducator from './features/CourseAdmin/pages/UpdateEducator';
import AddEducator from './features/CourseAdmin/pages/AddEducator';
import EventFlowTemplates from './features/EventFlowTemplates';
import UpdateEventFlowTemplatePage from './features/EventFlowTemplates/pages/UpdateTemplatePage';
import AddEventFlowTemplatePage from './features/EventFlowTemplates/pages/AddTemplatePage';
import TemplateBuilderPage from './features/EventFlowTemplates/pages/TemplateBuilderPage';
import OnboardingFlowAdmin from './features/OnboardingFlowAdmin';
import EditOnboardingFlow from './features/OnboardingFlowAdmin/pages/EditOnboardingFlow';
import AlertTemplates from './features/AlertTemplateAdmin';
import AddAlertTemplatePage from './features/AlertTemplateAdmin/pages/AddAlertTemplatePage';
import UpdateAlertTemplatePage from './features/AlertTemplateAdmin/pages/UpdateAlertTemplatePage';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.utc().isUTC();

registerLocale("sv", sv); // register it with the name you want
registerLocale("nb", nb); // register it with the name you translateConstantsWithBackendTranslations


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_IDP_URL,
  client_id: import.meta.env.VITE_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_REDIRECT_URL,
  silent_redirect_uri: import.meta.env.VITE_REDIRECT_URL,
  post_logout_redirect_uri: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URL,
  response_type: "code",
  automaticSilentRenew: true,
  scope: 'openid murphy_admin_api offline_access',
  userStore: new WebStorageStateStore({
    store: localStorage
  }),
  // metadata: {
  //   issuer: auth.baseAuthServerUrl,
  //   authorization_endpoint: `${auth.baseAuthServerUrl}/connect/authorize`,
  //   token_endpoint: `${auth.baseAuthServerUrl}/connect/token`,
  //   end_session_endpoint: `${auth.baseAuthServerUrl}/connect/logout`,
  // },
}

function App() {
  return (
    <>
      <ModalProvider>
        <Router>
          <OidcWrapper {...oidcConfig}>
            <WebAuthProvider>
              <AdminUserPreferencesProvider>
                <UserProvider>
                  <CustomerProvider>
                    <Navbar />
                    <Routes>
                      <Route element={<PrivateOutlet />}>
                        <Route path="/portal" element={<AdminPortal />} />
                        <Route path="/" element={<AdminPortal />} />
                        <Route path="/customers">
                          <Route index element={<CustomerAdmin />} />
                          <Route path=":id" element={<UpdateCustomer />} />
                          <Route path="new" element={<AddCustomer />} />
                        </Route>
                        <Route path="/customergroups">
                          <Route index element={<CustomerGroups />} />
                          <Route path="new" element={<AddCustomerGroupPage />} />
                          <Route path=":id" element={<UpdateCustomerGroupPage />} />
                        </Route>

                        <Route path="/users" element={<UsersAdmin />} />
                        <Route path="/users/:id" element={<EditUser />} />
                        <Route path="/usernew" element={<AddUser />} />

                        {/* <Route path="/customerfiles" element={<CustomerFiles />} /> */}
                        <Route path="/requirements" element={<Outlet />}>
                          <Route index element={<RequirementAdmin />} />
                          <Route path="blocks" element={<Outlet />}>
                            <Route path="new" element={<CreateBlockPage />} />
                            <Route path=":requirementBlockId" element={<Outlet />} >
                              <Route index element={<EditBlockPage />} />
                              <Route path="reqs" element={<Outlet />}>
                                <Route path="new" element={<CreateRequirementPage />} />
                                <Route path=":requirementId" element={<EditRequirementPage />} />
                              </Route>
                            </Route>
                          </Route>
                          <Route path="levels" element={<Outlet />}>
                            <Route path=":level" element={<EditRequirementLevelPage />} />
                          </Route>
                          <Route path="categories" element={<Outlet />}>
                            <Route path=":category" element={<EditRequirementCategoryPage />} />
                          </Route>
                        </Route>
                        <Route path="/courseadmin" element={<Outlet />}>
                          <Route index element={<CourseAdmin />} />
                          <Route
                            path="coursenew"
                            element={<AddCourse />}
                          />
                          <Route
                            path="courses/:id"
                            element={<UpdateCourse />}
                          />
                          <Route
                            path="courses/:trainingId/trainingsessionsnew"
                            element={<AddCourseSession />}
                          />
                          <Route
                            path="courses/:trainingId/trainingsessions/:id"
                            element={<UpdateCourseSession />}
                          />
                        </Route>

                        <Route path="/educators" element={<Outlet />} >
                          <Route index element={<Educators />} />
                          <Route
                            path=":id"
                            element={<UpdateEducator />}
                          />
                          <Route path="new" element={<AddEducator />} />
                        </Route>

                        <Route path="/eventflowtemplates">
                          <Route index element={<EventFlowTemplates />} />
                          <Route path="new" element={<AddEventFlowTemplatePage />} />
                          <Route
                            path=":id/config"
                            element={<UpdateEventFlowTemplatePage />}
                          />
                          <Route
                            path=":id"
                            element={<TemplateBuilderPage />}
                          />
                        </Route>

                        <Route path="/alerttemplates">
                          <Route index element={<AlertTemplates />} />
                          <Route path="new" element={<AddAlertTemplatePage />} />
                          <Route
                            path=":id/config"
                            element={<UpdateAlertTemplatePage />}
                          />
                          {/* <Route
                            path=":id"
                            element={<TemplateBuilderPage />}
                          /> */}
                        </Route>

                        <Route path="/onboardingflows">
                          <Route index element={<OnboardingFlowAdmin />} />
                          <Route
                            path=":id"
                            element={<EditOnboardingFlow />}
                          />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                      </Route>
                      <Route path="/login" element={<LoginPage />} />
                    </Routes>
                  </CustomerProvider>
                </UserProvider>
              </AdminUserPreferencesProvider>
            </WebAuthProvider>
          </OidcWrapper>
        </Router>
      </ModalProvider>
    </>

  )
}

export default App
