import { useQuery } from '@tanstack/react-query';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import queryKeys from './queryKeys';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { TrainingSessionApi } from './TrainingSessionsApi';

export function useGetAllTrainingSessions() {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingSessionApi(apiService);
  return useQuery({
    queryKey: queryKeys.all,
    queryFn: () => api.getTrainingSessions()
  });
}

export function useGetTrainingSessions(filter: {
  customerId?: any;
  trainingId?: any;
}) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingSessionApi(apiService);

  return useQuery({
    queryKey: queryKeys.list(filter),
    queryFn: () => api.getTrainingSessions(filter)
  });
}

export function useGetTrainingSession(id: number) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const api = new TrainingSessionApi(apiService);

  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => api.getTrainingSession(id),
    enabled: !!id
  });
}
