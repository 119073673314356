import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { OnboardingFlowDTO } from "./models";

export class OnboardingFlowApi {
    apiService: IApiService;
    baseUrl: string = 'api/OnboardingFlow';

    constructor(apiService: IApiService) {
        this.apiService = apiService;
    }

    getOnboardingFlows = async (): Promise<OnboardingFlowDTO[]> => {
        const { data } = await this.apiService.callApi(this.baseUrl, 'GET');
        return data;
    };

    getOnboardingFlow = async (id: number): Promise<OnboardingFlowDTO> => {
        const url = `${this.baseUrl}/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        const map = new Map<number, string>();
        Object.entries(data.StepsHtmlContents).forEach(([key, value]) => {
            map.set(parseInt(key), value as string);
        });
        data.StepsHtmlContents = map;
        return data;
    };
}