import { FunctionComponent, useEffect, useState } from "react";
import ColumnGroupTable from "@murphy-frontend/web-core/components/ColumnGroupTable";
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from "@murphy-frontend/web-core/components/ColumnGroupTable/Models";
import { createColumnGroup, getLargeColSizeBasedOnScreen, sortByKey } from "@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils";
import TableCellActionDropDownButton from "@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton";
import { IconContext } from "react-icons/lib";
import { RiDeleteBin7Fill, RiPencilFill } from "react-icons/ri";
import { AlertTemplateDTO } from "../api/models";

interface AlertTemplatesTableProps {
    templates: AlertTemplateDTO[];
    onClickEdit: (template: AlertTemplateDTO) => void;
    onClickEditConfiguration: (template: AlertTemplateDTO) => void;
    onClickDelete: (template: AlertTemplateDTO) => void;
    currentScreenSize: string;
}

const AlertTemplatesTable: FunctionComponent<AlertTemplatesTableProps> = (
    {
        templates,
        onClickEdit,
        onClickEditConfiguration,
        onClickDelete,
        currentScreenSize,
    }
) => {

    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [maxColumns, setMaxColumns] = useState<number>(0);
    const [currentSortingColumn, setCurrentSortingColumn] = useState<string>('Name');
    const [sortedAscending, setSortedAscending] = useState<boolean>(true);

    useEffect(() => {
        const createMainColumnGroup = (AlertTemplates: AlertTemplateDTO[]) => {
            const customerGroupNameColumns: ColumnGroupColumnCell[] = [
                {
                    columnKey: 'Name',
                    displayName: "Name",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
            ];

            const customerGroupNameRows: ColumnGroupRowCell[][] = AlertTemplates.map((row) => [
                {
                    id: `name-${row.Id}`,
                    rowId: row.Id,
                    title: row.Name,
                    displayValue: (
                        <TableCellActionDropDownButton
                            displayText={row.Name}
                            listItems={generateDropDownListItems(row)}
                            columnSize="column-large"
                            onClickRow={() => onClickEdit(row)}
                        />
                    ),
                    columnKey: 'Name',
                },
            ]);

            const customerGroupNameColumnGroup = createColumnGroup(
                "ALERT TEMPLATES",
                customerGroupNameColumns,
                customerGroupNameRows
            );

            return customerGroupNameColumnGroup;
        };

        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (template: AlertTemplateDTO) => {
            const ddlItems = [];

            ddlItems.push(
                <li key="edit" onClick={() => onClickEditConfiguration(template)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <RiPencilFill />
                        </IconContext.Provider>
                        <span>Edit</span>
                    </div>
                </li>
            );

            ddlItems.push(
                <li key="delete" onClick={() => onClickDelete(template)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <RiDeleteBin7Fill />
                        </IconContext.Provider>
                        <span>Delete</span>
                    </div>
                </li>
            );
            return ddlItems;
        };

        if (templates) {
            const allColumnGroups = [];
            const customerGroupsCopy = [...templates];

            if (currentSortingColumn) {
                customerGroupsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const customerGroupNameColGroup = createMainColumnGroup(customerGroupsCopy);
            allColumnGroups.push(customerGroupNameColGroup);

            setMaxColumns(customerGroupNameColGroup.columns.length);
            setColumnGroups(allColumnGroups);
        }
    }, [templates, sortedAscending, currentSortingColumn, currentScreenSize, onClickEdit, onClickDelete, onClickEditConfiguration]);

    return (
        <ColumnGroupTable
            maxColumns={maxColumns}
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
        />
    );
}

export default AlertTemplatesTable;