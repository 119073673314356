import React, { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpsertAlertTemplate from '../components/UpsertAlertTemplate';
import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { useCustomer } from '../../../contexts/CustomerContext';
import { useGetAlertTemplate } from '../api/queries';

const UpdateAlertTemplatePage: FunctionComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { customer } = useCustomer();

    const onClickBack = () => {
        navigate('/alerttemplates');
    };

    const { data: AlertTemplate, isLoading, isError } = useGetAlertTemplate(id);

    const onSuccessHandler = () => {
        navigate('/alerttemplates');
    };

    if (isLoading) {
        return <Spinner isGlobal />;
    }

    if (isError) {
        return <div>Error occurred while fetching template.</div>;
    }

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>EDIT {AlertTemplate?.Name}</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <UpsertAlertTemplate
                        alertTemplate={AlertTemplate}
                        onSuccess={onSuccessHandler}
                        customerId={customer?.Id} />
                </div>
            </section>
        </div>
    );
};

export default UpdateAlertTemplatePage;
