import { useQuery } from '@tanstack/react-query';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import queryKeys from './queryKeys';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { EducatorApi } from './EducatorApi';

export function useGetEducators() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new EducatorApi(apiService);
    return useQuery({
        queryKey: queryKeys.all,
        queryFn: () => api.getEducators(),
        staleTime: Infinity
    });
}

export function useGetEducator(id: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const api = new EducatorApi(apiService);
    return useQuery({
        queryKey: [queryKeys.detail(id)],
        queryFn: () => api.getEducator(id),
        staleTime: Infinity
    });
}