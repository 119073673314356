import { useQuery } from '@tanstack/react-query';
import { CustomerGroupModel, CustomerGroupsApi } from './CustomerGroupsApi';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export function useGetCustomerGroups() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerGroupsApi = new CustomerGroupsApi(apiService);

    return useQuery({
        queryKey: queryKeys.all,
        queryFn: () => customerGroupsApi.getCustomerGroups().then(({ data }) => data as CustomerGroupModel[]),
        staleTime: 60 * 60 * 1000
    });
}

export function useGetCustomerGroup(customerGroupId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerGroupsApi = new CustomerGroupsApi(apiService);

    return useQuery({
        queryKey: queryKeys.detail(customerGroupId),
        queryFn: () => customerGroupsApi.getCustomerGroup(customerGroupId).then(({ data }) => data as CustomerGroupModel),
        staleTime: 60 * 60 * 1000
    });
}
