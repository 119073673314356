import { FunctionComponent } from "react";
import { EventFlowRespondBlockDto } from "../../../api/models";
import CheckListTitleWithControls from "./CheckboxTitleWithControls";

interface TextInputComponentProps {
    eventFlowRespondBlock: EventFlowRespondBlockDto;
    onUpdate: (EventFlowRespondBlock: EventFlowRespondBlockDto) => void;
    onDelete: (EventFlowRespondBlock: EventFlowRespondBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowRespondBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowRespondBlockDto) => void;
    maxRanking: number;
}

const TextInputComponent: FunctionComponent<TextInputComponentProps> = (
    {
        eventFlowRespondBlock,
        onUpdate,
        onDelete,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking,
    }) => {

    const content = <span>
        {eventFlowRespondBlock.Text}
    </span>;

    return (
        <>
            <div className="m-spacing"></div>
            <div className="drone-checklist-item-card">
                <div className="drone-text-input-label">

                    <CheckListTitleWithControls
                        content={content}
                        onDelete={onDelete}
                        onUpdate={onUpdate}
                        eventFlowRespondBlock={eventFlowRespondBlock}
                        onClickDecrementRanking={onClickDecrementRanking}
                        onClickIncrementRanking={onClickIncrementRanking}
                        maxRanking={maxRanking}
                    />
                </div>
                <input type="text" placeholder={eventFlowRespondBlock.PlaceholderText} className="drone-text-input">
                </input>
            </div>
        </>
    );
}

export default TextInputComponent;