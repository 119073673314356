import React, { useState, useEffect, FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import {
    RiUserUnfollowLine, RiCheckFill, RiCloseFill,
} from 'react-icons/ri';
import { CustomerUserTrainingSessionStates } from '@murphy-frontend/common/enums';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import TableCellActionDropDownButton from '@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton';
import { createColumnGroup, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { ColumnGroup } from '@murphy-frontend/web-core/components/ColumnGroupTable/Models';
import { UserTrainingSessionViewModel } from '../models';

interface CustomerUserTrainingSessionListProps {
    customerUserTrainingSessions: UserTrainingSessionViewModel[],
    onClickUnbook: (userTrainingSessionId: string) => void,
    onClickSetAttended: (userTrainingSessionId: string) => void,
    onClickSetNotAttended: (userTrainingSessionId: string) => void,
    sessionHasBeenCompleted: boolean,
}

const CustomerUserTrainingSessionList: FunctionComponent<CustomerUserTrainingSessionListProps> = (
    { customerUserTrainingSessions,
        onClickUnbook,
        onClickSetAttended,
        onClickSetNotAttended,
        sessionHasBeenCompleted, }
) => {
    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [currentSortingColumn, setCurrentSortingColumn] = useState('customername');
    const [sortedAscending, setSortedAscending] = useState(true);

    useEffect(() => {
        const sortByColumn = (columnKey: any) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (row: UserTrainingSessionViewModel) => {
            const items = [];
            if (row.sessionStateId === CustomerUserTrainingSessionStates.Booked
                && sessionHasBeenCompleted !== true) {
                items.push(
                    (
                        <li key="unbook" onClick={() => onClickUnbook(row.id)}>
                            <div className="vertical-aligner">
                                <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                                    <RiUserUnfollowLine />
                                </IconContext.Provider>
                                <span>Avboka</span>
                            </div>
                        </li>
                    ),
                );
            }

            if (sessionHasBeenCompleted === true) {
                items.push(
                    (
                        <li key="attend" onClick={() => onClickSetAttended(row.id)}>
                            <div className="vertical-aligner">
                                <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                                    <RiCheckFill />
                                </IconContext.Provider>
                                <span>Närvarade</span>
                            </div>
                        </li>
                    ),
                );
                items.push(
                    (
                        <li key="unattend" onClick={() => onClickSetNotAttended(row.id)}>
                            <div className="vertical-aligner">
                                <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                                    <RiCloseFill />
                                </IconContext.Provider>
                                <span>Icke närvarande</span>
                            </div>
                        </li>
                    ),
                );
            }

            return items;
        };

        const createMainColumnGroup = (userTrainingSessionsArray: UserTrainingSessionViewModel[]) => {
            const userTrainingSessionInfoCols = [
                {
                    columnKey: 'userEmail',
                    displayName: "Email",
                    isSortable: true,
                    size: 'column-extra-extra-large',
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'userName',
                    displayName: "Username",
                    isSortable: true,
                    size: 'column-extra-large',
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'customerName',
                    displayName: "Customer",
                    isSortable: true,
                    size: 'column-medium-large',
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'sessionStateName',
                    displayName: 'Status',
                    isSortable: true,
                    size: 'column-medium',
                    onClickColumn: sortByColumn,
                },
            ];
            const trainingSessionInfoRows = userTrainingSessionsArray.map((row) => ([
                {
                    columnKey: 'userEmail',
                    id: `userEmail-${row.id}`,
                    rowId: row.id,
                    displayValue: <TableCellActionDropDownButton
                        onClickRow={() => (row.id)}
                        displayText={row.userEmail}
                        listItems={generateDropDownListItems(row)}
                        columnSize="column-large"
                    />,
                },
                {
                    columnKey: 'userName',
                    id: `userName-${row.id}`,
                    rowId: row.id,
                    displayValue: row.userName,
                },
                {
                    columnKey: 'customerName',
                    id: `customer-${row.id}`,
                    rowId: row.id,
                    displayValue: row.customerName,
                },
                {
                    columnKey: 'sessionStateName',
                    id: `state-${row.id}`,
                    rowId: row.id,
                    displayValue: row.sessionStateName,
                },
            ]));

            const mainColumnGroup = createColumnGroup('', userTrainingSessionInfoCols, trainingSessionInfoRows);
            return mainColumnGroup;
        };

        if (customerUserTrainingSessions) {
            const allColumnGroups = [];
            const trainingSessionsCopy = [...customerUserTrainingSessions];

            if (currentSortingColumn) {
                trainingSessionsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const mainColGroup = createMainColumnGroup(trainingSessionsCopy);
            allColumnGroups.push(mainColGroup);
            setColumnGroups(allColumnGroups);
        }
    }, [customerUserTrainingSessions, sortedAscending, currentSortingColumn, sessionHasBeenCompleted, onClickUnbook, onClickSetAttended, onClickSetNotAttended]);

    return (
        <ColumnGroupTable
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
            hidePageInputs
            hideFooter={false}
        />
    );
}

export default CustomerUserTrainingSessionList;