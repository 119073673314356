import { FunctionComponent } from "react";
import { EventFlowBlockDto } from "../../../api/models";
import TableCellActionDropDownButton from "@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton";
import { IconContext } from "react-icons";
import { RiDeleteBin7Fill, RiPencilFill } from "react-icons/ri";
import { IoChevronBack, IoChevronDown, IoChevronForward, IoChevronUp } from "react-icons/io5";

interface TitleWithControlsProps {
    eventFlowBlock: EventFlowBlockDto;
    content: React.ReactNode;
    onUpdate: (eventFlowBlock: EventFlowBlockDto) => void;
    onDelete: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    maxRanking: number;
}

const TitleWithControls: FunctionComponent<TitleWithControlsProps> = (
    {
        eventFlowBlock,
        onDelete,
        onUpdate,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking,
        content }) => {

    const generateActionDropDownButton = (block: EventFlowBlockDto) => {
        return <TableCellActionDropDownButton
            textComponent={content}
            clickable={false}
            listItems={generateDropDownListItems(block)}
            columnSize="column-medium"
            onClickRow={() => { }}
        />
    }

    const generateDropDownListItems = (block: EventFlowBlockDto) => {
        const ddlItems = [];

        ddlItems.push(
            <li key="edit" onClick={() => onUpdate(block)}>
                <div className="vertical-aligner">
                    <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                        <RiPencilFill />
                    </IconContext.Provider>
                    <span>Edit</span>
                </div>
            </li>
        );

        ddlItems.push(
            <li key="delete" onClick={() => onDelete(block)}>
                <div className="vertical-aligner">
                    <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                        <RiDeleteBin7Fill />
                    </IconContext.Provider>
                    <span>Delete</span>
                </div>
            </li>
        );

        if (eventFlowBlock.Ranking > 1) {
            ddlItems.push(
                <li key="decrementranking" onClick={() => onClickDecrementRanking(block)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <IoChevronUp />
                        </IconContext.Provider>
                        <span>Move up</span>
                    </div>
                </li>
            );
        }

        if (eventFlowBlock.Ranking < maxRanking) {
            ddlItems.push(
                <li key="incrementranking" onClick={() => onClickIncrementRanking(block)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <IoChevronDown />
                        </IconContext.Provider>
                        <span>Move down</span>
                    </div>
                </li>
            );
        }

        return ddlItems;
    };

    return (
        <div className="l-text">
            {generateActionDropDownButton(eventFlowBlock)}
        </div>
    );
}

export default TitleWithControls;