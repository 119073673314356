import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { CustomerGroupModel, CustomerGroupsApi } from './CustomerGroupsApi';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export const useCreateCustomerGroup = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerGroupsApi = new CustomerGroupsApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (customerGroupModel: CustomerGroupModel) => customerGroupsApi.createCustomerGroup(customerGroupModel),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("License Group Created");
        },
    });
};

export const useUpdateCustomerGroup = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerGroupsApi = new CustomerGroupsApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (customerGroupModel: CustomerGroupModel) => customerGroupsApi.editContactGroup(customerGroupModel),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("License Group Edited");
        },
    });
};

export const useDeleteCustomerGroup = () => {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerGroupsApi = new CustomerGroupsApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => customerGroupsApi.removeCustomerGroup(id),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("License Group Deleted");
        },
    });
};
