import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { useGetTemplate } from "../api/queries";
import EventFlowTemplateBuilder from "../components/EventFlowTemplateBuilder";
import Spinner from "@murphy-frontend/web-core/components/Spinner";

interface TemplateBuilderPageProps {

}

const TemplateBuilderPage: FunctionComponent<TemplateBuilderPageProps> = () => {
    const { id } = useParams();
    const templateId = parseInt(id, 10);
    const { data: eventFlowTemplate, isLoading, isError } = useGetTemplate(templateId);

    if (isLoading || !eventFlowTemplate) {
        return <Spinner isGlobal />
    }

    if (isError) {
        return <div>Error occurred while fetching template.</div>;
    }

    return (<div className="general-wrapper">
        <div className='h2-with-info-button'>
            <h2>{eventFlowTemplate.Name}</h2>
        </div>
        <div className="event-flow-wrapper">
            <EventFlowTemplateBuilder
                eventFlowTemplate={eventFlowTemplate}
            />
        </div>
    </div>);
}

export default TemplateBuilderPage;