import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { AlertTemplateAdminApi } from './AlertTemplateAdminApi';
import { CreateAlertTemplateDTO, UpdateAlertTemplateDTO } from './models';

export function useCreateAlertTemplate() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const alertTemplateApi = new AlertTemplateAdminApi(apiService);

    return useMutation({
        mutationFn: (createAlertTemplate: CreateAlertTemplateDTO) => alertTemplateApi.createAlertTemplate(createAlertTemplate),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("Alert Template created");
        },
    });
}

export function useUpdateAlertTemplate() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const alertTemplateApi = new AlertTemplateAdminApi(apiService);

    return useMutation({
        mutationFn: (updateAlertTemplate: UpdateAlertTemplateDTO) => alertTemplateApi.updateAlertTemplate(updateAlertTemplate),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("Alert Template updated");
        },
    });
}

export function useDeleteAlertTemplate() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const alertTemplateApi = new AlertTemplateAdminApi(apiService);

    return useMutation({
        mutationFn: (id: number) => alertTemplateApi.deleteAlertTemplate(id),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            toast.success("Alert Template deleted");
        },
    });
}