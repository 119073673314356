import React, { FunctionComponent, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import Button from '@murphy-frontend/web-core/components/Button';
import MurphyDatePicker from "@murphy-frontend/web-core/components/MurphyDatePicker";
import ControlledFormSelect from '@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect';
import { Constant } from '@murphy-frontend/web-core/models/Constant';
import { TrainingSessionModel } from '../api/TrainingSessions/TrainingSessionsApi';
import { TimeZoneType, getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';

const today = new Date();
today.setHours(0, 0, 0, 0);

const courseSessionSchema = yup.object().shape({
    startdate: yup.date().max(
        yup.ref('enddate'),
        'Start date must be before enddate',
    ).min(today, 'Date cannot be in the past').required(),
    enddate: yup.date().min(
        yup.ref('startdate'),
        'End date must be after startdate',
    ).min(today, 'Date cannot be in the past').required(),
    educator: yup.number().required(),
});

interface CourseSessionFormProps
{
    onSubmit: (data: CourseSessionFormData) => void,
    courseSessionToBeUpdated?: TrainingSessionModel,
    allEducators: Constant[],
    trainingSessionHasBeenCompleted: boolean,
    hasAttendees: boolean,
    isSaveLoading: boolean,
    timeZone: TimeZoneType,
}

export interface CourseSessionFormData
{
    startdate: Date;
    enddate: Date;
    educator: number;
}

const CourseSessionForm: FunctionComponent<CourseSessionFormProps> = (
    {
        onSubmit,
        courseSessionToBeUpdated,
        allEducators,
        trainingSessionHasBeenCompleted,
        hasAttendees,
        isSaveLoading,
        timeZone
    }
) =>
{
    const { control, handleSubmit, setValue, formState: { errors, isDirty }, reset } = useForm<CourseSessionFormData>({
        resolver: yupResolver(courseSessionSchema),
    });

    const startdate = useWatch({ control, name: 'startdate' });
    const onSubmitHandler = (data: CourseSessionFormData) =>
    {
        onSubmit(data);
    };

    useEffect(() =>
    {
        if (startdate)
        {
            const newEndDate = dayjs(startdate).add(1, 'hour').toDate();
            setValue('enddate', newEndDate);
        }
    }, [startdate, setValue]);

    useEffect(() =>
    {
        if (courseSessionToBeUpdated)
        {
            const defaults = {
                // startdate: dayjs(courseSessionToBeUpdated?.StartDateTimeUtc).toDate(),
                startdate: getConvertedDateDayjs(courseSessionToBeUpdated?.StartDateTimeUtc, timeZone).toDate(),
                // enddate: dayjs(courseSessionToBeUpdated?.EndDateTimeUtc).toDate(),
                enddate: getConvertedDateDayjs(courseSessionToBeUpdated?.EndDateTimeUtc, timeZone).toDate(),
                educator: courseSessionToBeUpdated?.EducatorId,
            };
            reset(defaults);
        }
    }, [courseSessionToBeUpdated, reset, timeZone]);

    const disableFields = trainingSessionHasBeenCompleted === true;

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="form-simple-container">
                {trainingSessionHasBeenCompleted === true && <span>THIS SESSION IS FINISHED</span>}
                {hasAttendees === true && <span>THIS SESSION HAS ATTENDEES AND IS THEREFORE LOCKED</span>}

                <ControlledFormSelect
                    control={control}
                    name="educator"
                    label={"Utbildningsledare"}
                    options={allEducators.map((educator) => ({ value: educator.id, label: educator.value }))}
                    isMulti={false}
                    disabled={disableFields}
                />

                <MurphyDatePicker
                    title="Start date"
                    isControlled
                    control={control}
                    name="startdate"
                    locale="sv"
                    showTime
                    errorMessage={errors.startdate?.message}
                    disabled={disableFields}
                />
                <MurphyDatePicker
                    title="End date"
                    isControlled
                    control={control}
                    name="enddate"
                    locale="sv"
                    showTime
                    errorMessage={errors.enddate?.message}
                    disabled={disableFields}
                />
                {disableFields !== true
                    && <Button disabled={!isDirty || (errors && errors.length > 0)} isLoading={isSaveLoading} type="submit" buttonStyle="btn--primary" buttonSize="btn--medium">Save</Button>}
            </div>
        </form>
    );
}

export default CourseSessionForm;