import React, { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import UpsertEducator from '../components/Educator/UpsertEducator';
import { useGetEducator } from '../api/Educator/queries';

const UpdateEducator: FunctionComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const onClickBack = () => {
        navigate('/educators');
    };

    const { data: educator, isLoading, isError } = useGetEducator(id);

    const onSuccessHandler = () => {
        navigate('/educators');
    };

    if (isLoading) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    if (isError) {
        return <div>Error occurred while fetching educator.</div>;
    }

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>EDIT {educator?.Name}</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <UpsertEducator educator={educator} onSuccess={onSuccessHandler} />
                </div>
            </section>
        </div>
    );
};

export default UpdateEducator;
