import React, {
	useState,
	useContext,
	createContext,
	useEffect,
	FunctionComponent,
} from 'react';

import PersistenceType, {
	IPersistenceService,
	LocalStorageKeys,
} from '@murphy-frontend/common/interfaces/IPersistenceService';


import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';

interface AdminUserPreferencesContextType {
	timeZone: TimeZoneType;
	setTimeZone: (tz: TimeZoneType) => void;
}

interface AdminUserPreferencesContextProps {
	children?: React.ReactNode;
}

const AdminUserPreferencesContext = createContext<AdminUserPreferencesContextType | undefined>(undefined);
const defaultTimeZone = '1'; // Local time default

const AdminUserPreferencesProvider: FunctionComponent<
	AdminUserPreferencesContextProps
> = ({ children }) => {
	const [timeZone, setTimeZone] = useState<TimeZoneType>(null);
	const persistenceService = useInjection<IPersistenceService>(
		PersistenceType.IPersistenceService,
	);

	useEffect(() => {
		if (timeZone) {
			persistenceService.setSelectedTimeZone(timeZone);
		}
	}, [persistenceService, timeZone]);

	const updateTimeZone = () => {
		const tz = persistenceService.getSelectedTimeZone();
		if (tz) {
			setTimeZone(tz as TimeZoneType);
		} else {
			setTimeZone(defaultTimeZone);
		}
	};

	useEffect(() => {
		updateTimeZone();
		function storageEventHandler(event: any) {
			if (event.key === LocalStorageKeys.SelectedTimeZone) {
				updateTimeZone();
			}
		}
		// Hook up the event handler
		window.addEventListener('storage', storageEventHandler);
		return () => {
			// Remove the handler when the component unmounts
			window.removeEventListener('storage', storageEventHandler);
		};
	}, []);

	const value = {
		timeZone,
		setTimeZone,
	};

	return (
		<AdminUserPreferencesContext.Provider value={value}>
			{children}
		</AdminUserPreferencesContext.Provider>
	);
};

export default AdminUserPreferencesProvider;

export const useAdminUserPreferences = () => useContext(AdminUserPreferencesContext);
