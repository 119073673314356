import React, { useState } from "react";
import { CreateEventFlowTabDto, EventFlowTabDto, EventFlowTemplateDto, UpdateEventFlowTabDto } from "../../api/models";
import EventFlowTabs from "./EventFlowTabs";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import { useCreateTab, useDeleteTab, useUpdateTab } from "../../api/mutations";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import UpdateEventFlowTab from "./UpdateEventFlowTab";
import EventFlowTab from "./EventFlowTab";

interface EventFlowTemplateBuilderProps {
    eventFlowTemplate: EventFlowTemplateDto;
}

const EventFlowTemplateBuilder: React.FC<EventFlowTemplateBuilderProps> = ({ eventFlowTemplate }) => {

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const { mutate: addTab, isPending: addTabIsLoading } = useCreateTab(eventFlowTemplate?.Id);
    const { mutate: updateTab, isPending: updateTabIsLoading } = useUpdateTab(eventFlowTemplate?.Id);
    const { mutate: deleteTab, isPending: deleteTabIsLoading } = useDeleteTab(eventFlowTemplate?.Id);

    const { openModal, closeModal } = useModal();

    const onClickAddTab = () => {
        const dto: CreateEventFlowTabDto = {
            TemplateId: eventFlowTemplate?.Id,
            Name: "New Tab",
            Ranking: maxRanking + 1
        }
        addTab(dto)
    }

    const onClickDeleteTab = (tab: EventFlowTabDto) => {
        deleteTab(tab.Id);
    }

    const onClickEditTab = (tab: EventFlowTabDto) => {

        const updateEventFlowTab = <UpdateEventFlowTab
            eventFlowTab={tab} onSuccess={closeModal} />;

        const openModalConfig: ModalConfiguration = {
            title: "Edit Tab",
            body: updateEventFlowTab,
            type: "info",
            hideButton: true,
        }
        openModal(openModalConfig)
    }

    const onClickIncrementRanking = (tab: EventFlowTabDto) => {
        const dto: UpdateEventFlowTabDto = {
            ...tab,
            Ranking: tab.Ranking + 1
        }
        updateTab(dto)
    }

    const onClickDecrementRanking = (tab: EventFlowTabDto) => {
        const dto: UpdateEventFlowTabDto = {
            ...tab,
            Ranking: tab.Ranking - 1
        }
        updateTab(dto)
    }

    if (addTabIsLoading || deleteTabIsLoading || updateTabIsLoading) {
        return <Spinner isGlobal />
    }

    const maxRanking = eventFlowTemplate.Tabs ? eventFlowTemplate.Tabs.reduce((prev, current) => (prev.Ranking > current.Ranking) ? prev : current, { Ranking: 0 }).Ranking : 0;
    return (
        <div className="event-flow-main-wrapper">
            <EventFlowTabs
                tabs={eventFlowTemplate.Tabs ?? [{
                    Id: 1,
                    Name: "test tab",
                }]}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                onClickAddTab={onClickAddTab}
                onClickDeleteTab={onClickDeleteTab}
                onClickEditTab={onClickEditTab}
                onClickIncrementRanking={onClickIncrementRanking}
                onClickDecrementRanking={onClickDecrementRanking}
                maxRanking={maxRanking}
            />
            <div className="divider"></div>
            {eventFlowTemplate.Tabs?.map((tab, index) => (
                <EventFlowTab
                    key={`${tab.Id}-${index}`}
                    eventFlowTab={tab}
                    active={index === selectedTabIndex}
                />
            ))
            }
        </div>
    );
};

export default EventFlowTemplateBuilder;
