import { QueryClient, useQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { OnlineEducationApi } from './OnlineEducationApi';

export function useGetBaseLessons(entityType: string, entityId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);

    return useQuery({
        queryKey: queryKeys.baseLessons(entityId, entityType),
        queryFn: () => onlineEducationApi.getBaseLessons(entityType, entityId),
    });
}

export function useGetLocalizedLessons(baseLessonId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);

    return useQuery({
        queryKey: queryKeys.localizedLessons(baseLessonId),
        queryFn: () => onlineEducationApi.getLocalizedLessons(baseLessonId),
    });
}

export function useGetCourses() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);

    return useQuery({
        queryKey: queryKeys.courses(),
        queryFn: () => onlineEducationApi.getCourses(),
    });
}

export function useGetCourseChapters(courseId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);

    return useQuery({
        queryKey: queryKeys.courseChapters(courseId),
        queryFn: () => onlineEducationApi.getCourseChapters(courseId),
        enabled: !!courseId,
    });
}

export function useGetChapterLessons(chapterId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const onlineEducationApi = new OnlineEducationApi(apiService);

    return useQuery({
        queryKey: queryKeys.chapterLessons(chapterId),
        queryFn: () => onlineEducationApi.getChapterLessons(chapterId),
        enabled: !!chapterId,
    });
}