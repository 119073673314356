import React from 'react';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import Button from '@murphy-frontend/web-core/components/Button';
import PersistenceType, {
	IPersistenceService,
} from '@murphy-frontend/common/interfaces/IPersistenceService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';

const LoginPage: FunctionComponent = () => {
	const webAuth = useAuth();
	const location = useLocation();
	let from = location.state?.from?.pathname || '/portal';
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const persistenceService = useInjection<IPersistenceService>(
		PersistenceType.IPersistenceService,
	);

	const onClickLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
		setErrorMessage("");
		e.preventDefault();
		await persistenceService.clearNonPersistentItems()
		from = from != '/login' ? from : '/';
		webAuth.signIn(from, "MURPHY");
	};

	const onClickTestProvider = async (e: React.MouseEvent<HTMLButtonElement>) => {
		setErrorMessage("");
		e.preventDefault();
		await persistenceService.clearNonPersistentItems()
		from = from != '/login' ? from : '/';
		webAuth.signIn(from, "TESTPROVIDER");
	};

	const showTestProviderLogin = import.meta.env.VITE_SHOW_TESTPROVIDER_LOGIN;

	return (
		<div className='login-flex-container'>
			<div className='login-left-container'>
				<div className='login-buttons-container'>
					<div>
						{/* <img
							className='murphy-logo'
							src={require('../../assets/img/LOGO.png')}
							alt='Murphy LOGO'
						/> */}
					</div>
					{errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
					<Button title='login' buttonSize={'btn--large'} onClick={onClickLogin}>
						<span>Login</span>
					</Button>
					{
						showTestProviderLogin &&
						<Button title='login' buttonSize={'btn--medium'} onClick={onClickTestProvider}>
							<span>Login Test Provider</span>
						</Button>
					}

					{/* {auth.showForgotPassword === 'true' && (
						<div className='link' onClick={onClickForgotPassword}>
							{translations['forgot-password']}
						</div>
					)} */}
				</div>
			</div>
			<div className='login-right-container'>
				<div>
					<div>
						{/* <img
							className='preaparatus-pic'
							src={require('../../assets/img/Praeparatus_Supervivet_Practise_Risk.jpg')}
							alt='Praeparatus Supervivet'
						/> */}
					</div>
					<div className='login-page-text'>
						<h3 className='login-page-title'>
							<strong className='login-page-title-bold'>
								Murphy{' '} ADMIN
							</strong>

						</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
