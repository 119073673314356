import { FunctionComponent, useState } from "react";
// import { useUserPreferences } from "../../common/contexts/UserPreferencesContext";
import { useGetCustomerGroups } from "./api/queries";
import React from "react";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import CustomerGroupsTable from "./components/CustomerGroupsTable";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { useDeleteCustomerGroup } from "./api/mutations";
import { CustomerGroupModel } from "./api/CustomerGroupsApi";
import Button from "@murphy-frontend/web-core/components/Button";

interface CustomerGroupsProps {

}

const CustomerGroups: FunctionComponent<CustomerGroupsProps> = () => {
    const { data: customerGroups, isLoading: customerGroupsIsLoading } = useGetCustomerGroups();
    const { currentSize } = useWindowDimensions();
    const navigate = useNavigate();
    const { mutate: deleteCustomerGroup, isPending: deleteCustomerGroupIsLoading } = useDeleteCustomerGroup();

    const loadingSpinner = <div className="spinner-container-global-center"><Spinner /></div>;

    if (customerGroupsIsLoading) {
        return loadingSpinner;
    }

    const onClickNewCustomerGroup = () => {
        navigate('/customergroups/new');
    };

    const onClickEditCustomerGroup = (customerGroup: CustomerGroupModel) => {
        const url = `/customergroups/${customerGroup.ID}`;
        navigate(url);
    };

    const onClickDeleteCustomerGroup = (customerGroup: CustomerGroupModel) => {
        deleteCustomerGroup(customerGroup.ID);
        navigate('/customergroups');
    };

    if (deleteCustomerGroupIsLoading) {
        <Spinner isGlobal />
    }

    return (
        <div className="generalwrapper">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>
                        CUSTOMER GROUPS
                    </h4>
                </div>
                <div className="two">
                    <Button
                        isIconButton
                        buttonStyle="btn--add"
                        buttonSize="btn-with-icon"
                        onClick={onClickNewCustomerGroup}
                    >
                        <span className="btn-with-icon-text">
                            New Customer Group
                        </span>
                    </Button>
                </div>
            </section>
            <section className="user-table-container">
                <CustomerGroupsTable
                    customerGroups={customerGroups ?? []}
                    onClickEditCustomerGroup={onClickEditCustomerGroup}
                    onClickDeleteCustomerGroup={onClickDeleteCustomerGroup}
                    currentScreenSize={currentSize}
                />
            </section>
        </div>
    );
}

export default CustomerGroups;