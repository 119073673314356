import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@murphy-frontend/web-core/components/Input";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import Button from "@murphy-frontend/web-core/components/Button";
import RichTextEditor from "@murphy-frontend/web-core/components/RichTextEditor";
import { deserialize } from "@murphy-frontend/web-core/components/RichTextEditor/helpers/serializationHelpers";
import { serialize } from "@murphy-frontend/web-core/components/RichTextEditor/helpers/serializationHelpers";
import { TrainingLanguages } from "@murphy-frontend/web-core/constants";
import { TrainingModel } from "../../../../../../web/src/common/api/Training/TrainingApi";
import ControlledFormSelect, {
  OptionType,
} from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import DOMPurify from 'dompurify';
import { BasicCustomerDto } from "../../../CustomerAdmin/api/models";
import { Descendant } from "slate";
import MurphySelect from "@murphy-frontend/web-core/components/MurphySelect/MurphySelect";

yup.addMethod(yup.string, 'html', function () {
  return this.test({
    name: 'html',
    exclusive: true,
    message: 'Invalid text',
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    }
  });
});

const courseSchema = yup.object().shape(
  {
    Name: yup.string().min(3).required(),
    Description: yup.string().min(10).required(),
    Language: yup.number().min(1).required(),
    CustomerId: yup.string().notRequired().nullable(),
    PublicSector: yup.number().min(1).notRequired(),
    MaxAttendees: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value))
      .min(0)
      .notRequired()
  },
);

interface CourseFormProps {
  type: "course" | "guidance";
  onSubmit: (data: CourseFormData) => void;
  courseToBeUpdated?: TrainingModel;
  isSaveLoading?: boolean;
  customers: BasicCustomerDto[];
}

export interface CourseFormData {
  Name: string;
  Description: string;
  HtmlDescription: any;
  Language?: number;
  CustomerId?: string;
  MaxAttendees?: number;
  PublicSector: number;
}

function CourseForm({
  type,
  onSubmit,
  courseToBeUpdated,
  isSaveLoading,
  customers,
}: CourseFormProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
  } = useForm<CourseFormData>({
    resolver: yupResolver(courseSchema),
    defaultValues: {
      Name: "",
      Description: "",
      HtmlDescription: "",
      Language: 1,
      PublicSector: 3,
    },
  });

  const [isReallyDirty, setIsReallyDirty] = useState(false);
  const [htmlDescription, setHtmlDescription] = useState<Descendant[]>();
  const [htmlDescriptionEditor, setHtmlDescriptionEditor] = useState(null);

  const onChangeHtmlDescription = (newHtmlDescription: Descendant[]) => {
    setHtmlDescription(newHtmlDescription);
  };

  useEffect(() => {
    const initialHtmlDescription = getValues("HtmlDescription")
      ? getValues("HtmlDescription")
      : "";
    let isHtmlDescriptionDirty = false;
    if (initialHtmlDescription !== htmlDescription) {
      isHtmlDescriptionDirty = true;
    }

    const newIsReallyDirty =
      isHtmlDescriptionDirty === true || isDirty || false;
    setIsReallyDirty(newIsReallyDirty);
  }, [isDirty, htmlDescription, getValues]);

  useEffect(() => {
    const initialValue = [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];

    if (courseToBeUpdated) {
      const defaults: CourseFormData = {
        Name: courseToBeUpdated?.Name,
        Description: courseToBeUpdated?.Description,
        HtmlDescription: initialValue,
        Language: courseToBeUpdated?.TrainingLanguageId,
        CustomerId: courseToBeUpdated?.CustomerId,
        MaxAttendees: courseToBeUpdated?.MaxAttendees,
        PublicSector: 3,
      };

      if (courseToBeUpdated.PublicSector === true) {
        defaults.PublicSector = 1;
      }
      if (courseToBeUpdated.PublicSector === false) {
        defaults.PublicSector = 2;
      }

      if (courseToBeUpdated.HtmlDescription) {
        const document = new DOMParser().parseFromString(
          courseToBeUpdated?.HtmlDescription,
          "text/html"
        );
        const deserializedContent = deserialize(document.body);
        defaults.HtmlDescription = deserializedContent;
        setHtmlDescription(deserializedContent);
      } else {
        setHtmlDescription(initialValue);
      }

      reset(defaults);
    } else {
      setHtmlDescription(initialValue);
    }
  }, [courseToBeUpdated, reset]);

  useEffect(() => {
    if (htmlDescription) {
      const newIntroEditor = (
        <div className="overridecontainer">
          <RichTextEditor
            contents={htmlDescription}
            onChangeContents={onChangeHtmlDescription}
          />
        </div>
      );
      setHtmlDescriptionEditor(newIntroEditor);
    }
    return () => {
      setHtmlDescriptionEditor(null);
    };
  }, [htmlDescription, courseToBeUpdated]);

  const onSubmitHandler = (data: CourseFormData) => {

    if (typeof htmlDescription === 'string' || htmlDescription instanceof String)
      data.HtmlDescription = htmlDescription;
    else
      data.HtmlDescription = htmlDescription.map((node) => serialize(node)).join('');
    onSubmit(data);
  };

  const languageOptions = TrainingLanguages.map((lang) => ({
    value: lang.id,
    label: lang.value,
  }));

  languageOptions.unshift({ value: 0, label: "Select language" });

  const customerOptions: OptionType[] = customers.map((cust) => ({
    value: cust.Id,
    label: cust.Name,
  }));

  const publicSectorOptions: OptionType[] = [
    { value: 1, label: "Public sector" },
    { value: 2, label: "Private sector" },
    { value: 3, label: "Not specified" }
  ];

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="form-simple-container upserttrainigform-container">
        <div className="form-simple-container">
          <span className="form-section-header">INFO</span>
          <Input
            isControlled
            register={register}
            name="Name"
            isRequired
            title="Name"
            errorMessage={errors.Name?.message}
          />
          <ControlledFormSelect
            control={control}
            name={"Language"}
            label={"Language"}
            options={languageOptions}
            isMulti={false}
            errorMessage={errors.Language?.message}
          />
          {type === "course" && (
            <Input
              isControlled
              type="number"
              min={0}
              register={register}
              name="MaxAttendees"
              title="Max attendees"
              errorMessage={errors.MaxAttendees?.message}
            />
          )}
          <ControlledFormSelect
            control={control}
            title="Sector"
            name="PublicSector"
            label="Sector"
            options={publicSectorOptions}
            isMulti={false}
            errorMessage={errors.PublicSector?.message}
          />
          <ControlledFormSelect
            control={control}
            title="Licens"
            name="CustomerId"
            label="Licens"
            options={customerOptions}
            isMulti={false}
            errorMessage={errors.CustomerId?.message}
          />
          <Textarea
            isControlled
            register={register}
            name="Description"
            title="Summary"
            errorMessage={errors.Description?.message}
            columns={10}
            rows={25}
          />
          {errors && errors.length > 0
            ? errors.map((p) => {
              return <p>{p.description?.message}</p>;
            })
            : null}
          <Button
            disabled={!isReallyDirty || (errors && errors.length > 0)}
            isLoading={isSaveLoading}
            type="submit"
            buttonStyle="btn--primary"
            buttonSize="btn--medium"
          >
            Save
          </Button>
        </div>
        <div className="form-simple-container">
          <span className="form-section-header">Description</span>
          <div className="rich-text-editor-container">
            {htmlDescriptionEditor}
          </div>
        </div>
      </div>
    </form>
  );
}

export default CourseForm;
