import { useState, useEffect, FunctionComponent } from 'react';
import UserForm, { UserFormViewModel } from '@murphy-frontend/web-core/features/UsersAdmin/components/UserForm';
import { Constant } from '@murphy-frontend/web-core/models/Constant';
import { TimeZoneType } from '@murphy-frontend/common/services/TimeService';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import UserTrainingSessions from '@murphy-frontend/web-core/components/UserTrainingSessions';
import { UpdateUserRequest } from '../api/models';
import { useResetMfa, useUpdateUserMutation } from '../api/mutations';
import { useEditUserTrainingSession } from '../../CourseAdmin/api/UserTrainingSession/mutations';
import { CustomerUserTrainingSessionStates } from '@murphy-frontend/common/enums';

interface UpdateUserProps {
  allProducts: Constant[],
  user: UserFormViewModel,
  customerId: string,
  showResetPassword: boolean,
  onClickChangePassword?: () => void,
  successCallback?: () => void,
  isSetPermissionsReadonly: boolean,
  isSetRoleReadonly: boolean,
  timeZone: TimeZoneType,
  translations?: Record<string, string>,
  language?: any,
  showTrainingSessions?: boolean,
}

const UpdateUser: FunctionComponent<UpdateUserProps>
  = ({
    allProducts,
    user,
    showResetPassword,
    onClickChangePassword,
    successCallback,
    isSetPermissionsReadonly,
    isSetRoleReadonly,
    timeZone,
    showTrainingSessions
  }) => {

    const { mutate: updMutate, isPending: updateIsLoading } = useUpdateUserMutation(user?.id);
    const { mutate: editUserTrainingSession, isPending: editUserTrainingSessionIsLoading } = useEditUserTrainingSession()
    const { mutate: resetMfa, isPending: resetMfaIsLoading } = useResetMfa(user?.id);

    const onSubmitHandler = (updateData: any) => {
      const updateUserRequest: UpdateUserRequest = {
      };

      if (typeof updateData.phonenumber !== 'undefined') {
        updateUserRequest.NewMobilePhone = updateData.phonenumber;
      }

      if (updateData.email) {
        updateUserRequest.NewEmail = updateData.email;
      }
      if (updateData.username) {
        updateUserRequest.NewUserName = updateData.username;
      }

      if (updateData.selectedproductids) {
        updateUserRequest.UpdatedProductsList = updateData.selectedproductids;
      }

      if (updateData.role >= 0) {
        updateUserRequest.NewRole = updateData.role;
      }

      if (Object.keys(updateUserRequest).length > 0) {
        updMutate(updateUserRequest, {
          onSuccess: () => {
            if (successCallback) {
              successCallback();
            }
          },
        });
      }
    };

    const onResetMfa = (userId: string) => {
      resetMfa();
    }

    const onUnRegisterForTrainingSession = (customerUserTrainingSessionId: string) => {
      const editUserTrainingSessionRequest = {
        Id: customerUserTrainingSessionId,
        NewStateId: CustomerUserTrainingSessionStates.Cancelled,
      };

      editUserTrainingSession(editUserTrainingSessionRequest);
    };

    if (!allProducts || editUserTrainingSessionIsLoading || resetMfaIsLoading) {
      return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return (
      <>
        <section className="container-with-background">
          <div className="form-container">
            <UserForm
              allProducts={allProducts}
              userToBeUpdated={user}
              showResetPassword={showResetPassword}
              onClickChangePassword={onClickChangePassword}
              onSubmit={onSubmitHandler}
              isSetPermissionsReadonly={isSetPermissionsReadonly}
              isSetRoleReadonly={isSetRoleReadonly}
              isLoading={updateIsLoading}
              isUserNameDisabled={false}
              isEmailDisabled={false}
              isPhoneNumberDisabled={false}
              isUpsertUserEnabled={true}
              onResetMfa={onResetMfa}
              showResetMfa={true}
            />
          </div>
        </section>
        {showTrainingSessions &&
          <UserTrainingSessions
            customerUserTrainingSessions={user.userTrainingSessions}
            timeZone={timeZone}
            onUnRegisterForTrainingSession={onUnRegisterForTrainingSession}
          />
        }
      </>
    );
  };


export default UpdateUser;
