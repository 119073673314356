const queryKeys = {
  all: ['usertrainingsessions'],
  lists: () => [...queryKeys.all, 'list'],
  customerList: (customerUserId: string) => [...queryKeys.lists(), customerUserId],
  trainingSessionList: (trainingSessionId: number) => [...queryKeys.lists(), trainingSessionId],
  details: () => [...queryKeys.all, 'detail'],
  detail: (id: number) => [...queryKeys.details(), id],
};

export default queryKeys;
