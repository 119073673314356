import { FunctionComponent } from 'react';
import { useUpdateBlock } from '../../../api/mutations';
import { EventFlowBlockDto, UpdateEventFlowBlockDto } from '../../../api/models';
import EventFlowBlockForm, { EventFlowBlockFormDto } from './EventFlowBlockForm';
import { EventFlowComponentType } from '@murphy-frontend/common/enums';

interface UpdateEventFlowBlockProps {
    eventFlowBlock: EventFlowBlockDto;
    onSuccess: () => void;
    templateId: number;
}

const UpdateEventFlowBlock: FunctionComponent<UpdateEventFlowBlockProps> = ({
    eventFlowBlock,
    onSuccess,
    templateId,
}) => {
    const { mutate: updateEventFlowBlock, isPending: isUpdating } = useUpdateBlock(templateId);

    const onSubmitHandler = (eventFlowBlockData: EventFlowBlockFormDto) => {
        const updatedEventFlowBlock: UpdateEventFlowBlockDto = {
            ...eventFlowBlock,
            ...eventFlowBlockData,
        };
        updateEventFlowBlock(updatedEventFlowBlock, {
            onSuccess: () => {
                onSuccess();
            },
        });
    };
    const formDto = {
        ...eventFlowBlock
    };

    const getVisibleFields = (eventFlowBlock: EventFlowBlockDto): string[] => {
        const visibleFields = ["TextValue", "Title"];

        if (eventFlowBlock.BlockType === EventFlowComponentType.CheckList) {
            return ["Title"];
        }
        if (eventFlowBlock.BlockType === EventFlowComponentType.Accordion) {
            return ["Title", "TextValue"];
        }
        if (eventFlowBlock.BlockType === EventFlowComponentType.CompletedButton) {
            return ["Title"];
        }
        if (eventFlowBlock.BlockType === EventFlowComponentType.Text) {
            return ["TextValue"];
        }
        if (eventFlowBlock.BlockType === EventFlowComponentType.Title) {
            return ["TextValue"];
        }

        return visibleFields;
    }

    const visibleFields = getVisibleFields(eventFlowBlock);

    return (
        <div>
            <EventFlowBlockForm
                isLoading={isUpdating}
                eventFlowBlock={formDto}
                onSubmit={onSubmitHandler}
                visibleFields={visibleFields}
            />
        </div>
    );
};

export default UpdateEventFlowBlock;
