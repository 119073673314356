import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { BasicCustomerDto, CreateCustomerRequest, MurphyCustomer, UpdateCustomerRequest } from "./models";


export class CustomersApi {
    constructor(
        private apiService: IApiService,
    ) { }

    getCustomers = async (): Promise<MurphyCustomer[]> => {
        const url = 'api/Customer';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getCustomersBasic = async (): Promise<BasicCustomerDto[]> => {
        const url = 'api/Customer/basic';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getCustomer = async (id: string): Promise<MurphyCustomer> => {
        const url = `api/Customer/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    createCustomer = (createCustomerRequest: CreateCustomerRequest) => {
        const url = 'api/Customer/';
        return this.apiService.callApi(url, 'POST', createCustomerRequest);
    };

    updateCustomer = (customerId: string, updateCustomerRequest: UpdateCustomerRequest) => {
        const url = `api/Customer/${customerId}`;
        return this.apiService.callApi(url, 'PUT', updateCustomerRequest);
    };

    deleteCustomer = (customerId: string) => {
        const url = `api/Customer/${customerId}`;
        return this.apiService.callApi(url, 'DELETE');
    };

}