import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { CustomersApi } from './CustomersApi';
import { CreateCustomerRequest, UpdateCustomerRequest } from './models';

export const useCreateCustomer = () => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const customerApi = new CustomersApi(apiService);

  return useMutation({
    mutationFn: (createCustomerRequest: CreateCustomerRequest) => customerApi.createCustomer(createCustomerRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success("Customer created");
    },
  });
};

export const useUpdateCustomer = (customerId: string) => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const customerApi = new CustomersApi(apiService);

  return useMutation({
    mutationFn: (updateCustomerRequest: UpdateCustomerRequest) => customerApi.updateCustomer(customerId, updateCustomerRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success("Customer updated");
    },
  });
};

export const useDeleteCustomer = () => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const customerApi = new CustomersApi(apiService);

  return useMutation({
    mutationFn: (customerId: string) => customerApi.deleteCustomer(customerId),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success("Customer deleted");
    },
  });
};
