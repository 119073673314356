import { FunctionComponent } from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import RequirementListRow from './RequirementListRow';
import { BaseRequirementDTO } from '../api/models';

interface RequirementListProps {
    requirements: BaseRequirementDTO[] | undefined;
    onClickEditRequirement?: (requirementId: number) => void;
    onClickAddRequirement?: () => void;
    onClickDeleteRequirement?: (requirementId: number) => void;
}

const RequirementList: FunctionComponent<RequirementListProps> = ({
    onClickDeleteRequirement,
    onClickAddRequirement,
    onClickEditRequirement,
    requirements }) => {
    return (
        <div className='requirement-list'>
            <div className='header'>
                <div>
                    <h4>
                        Requirements
                    </h4>
                </div>
                <div>
                    <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickAddRequirement} />
                </div>
            </div>
            <div>
                {requirements && requirements.map((requirement) => (
                    <RequirementListRow
                        requirement={requirement}
                        key={requirement.Id}
                        onClickEditRequirement={onClickEditRequirement}
                        onClickDeleteRequirement={onClickDeleteRequirement}
                    />
                ))}
            </div>
        </div>

    );
}

export default RequirementList;
