import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { CreateUserRequest, UpdateUserRequest } from './models';
import { UserApi } from './UserApi';

export const useUpdateUserMutation = (customerUserId: string) => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const userApi = new UserApi(apiService);

  return useMutation({
    mutationFn: (updateUserRequest: UpdateUserRequest) => userApi.updateUser(customerUserId, updateUserRequest),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success("User updated");
    },
  });
};

export const useResetMfa = (customerUserId: string) => {
  // const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const userApi = new UserApi(apiService);

  return useMutation({
    mutationFn: () => userApi.resetMfa(customerUserId),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      toast.success("MFA reset");
    },
  });
}


export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const userApi = new UserApi(apiService);

  return useMutation({
    mutationFn: (createUserRequest: CreateUserRequest) => userApi.createUser(createUserRequest),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success("User created");
    },
  });
};

export const useAddCallingUserToCustomerMutation = () => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const userApi = new UserApi(apiService);

  return useMutation({
    mutationFn: (customerId: string) => userApi.addCallingUserToCustomer(customerId),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error adding user to customer');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success("User added to customer");
    },
  });
}

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const userApi = new UserApi(apiService);

  return useMutation({
    mutationFn: (customerUserId: string) => userApi.deleteUser(customerUserId),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error deleting user');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success("User deleted");
    },
  });
};