import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { CreateTrainingFileRequest, TrainingFileModel, UpdateTrainingFileRequest } from "./models";


export class TrainingFileApi {
  constructor(private apiService: IApiService) { }

  getTrainingFiles = async (trainingId: number): Promise<TrainingFileModel[]> => {
    const baseUrl = 'api/TrainingFile';
    const searchParams = new URLSearchParams();

    if (trainingId) {
      searchParams.append('trainingId', trainingId.toString());
    }
    const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
    const url = `${baseUrl}${searchParamsString}`;
    const { data } = await this.apiService.callApi(url, 'GET');
    return data;
  }

  createTrainingFile(createTrainingFileRequest: CreateTrainingFileRequest) {
    const url = 'api/TrainingFile';

    const formData = new FormData();
    formData.append('File', createTrainingFileRequest.fileToUpload);
    const createFileRequest = {
      TrainingId: createTrainingFileRequest.trainingId,
    };
    formData.append('FileJsonData', JSON.stringify(createFileRequest));

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return this.apiService.callApi(url, 'POST', formData, headers);
  }

  editTrainingFile(editTrainingFileRequest: UpdateTrainingFileRequest) {
    const url = `api/TrainingFile/${editTrainingFileRequest.Id}`;
    return this.apiService.callApi(url, 'PUT', editTrainingFileRequest);
  }

  deleteTrainingFile(id: number) {
    const url = `api/TrainingFile/${id}`;
    return this.apiService.callApi(url, 'DELETE');
  }

  download = async (fileId: string) => {
    const url = `api/TrainingFile/${fileId}/Download`;
    const responseType = 'blob';
    const { data } = await this.apiService.callApi(url, 'GET', null, null, responseType);
    return new Blob([data]);
  }
}
