import { FunctionComponent, useRef } from "react";
import { AdminRequirementBlockDto } from "../api/models";
import { useMeasure } from "@uidotdev/usehooks";
import useTruncatedText from "@murphy-frontend/web-core/hooks/useTruncatedText";

interface RequirementBlockProps {
    block: AdminRequirementBlockDto,
    onClick: (block: AdminRequirementBlockDto) => void,
}

const RequirementBlock: FunctionComponent<RequirementBlockProps> = ({ block, onClick }) => {

    const handleClick = (e: any) => {
        onClick(block);
    }

    const textRef = useRef<HTMLElement>(null);
    const [measureRef, { height }] = useMeasure<HTMLDivElement>();
    const truncatedText = useTruncatedText(textRef, block.Name, height ?? 134);


    const classes = `pyramid-block block-width`;
    const levelTitleClass = `level-title small`;

    return (
        <div className={classes} key={block.Id} onClick={handleClick} ref={measureRef}>
            <div ref={textRef} className={levelTitleClass}>
                {truncatedText}
            </div>
        </div>
    );
}

export default RequirementBlock;