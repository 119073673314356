import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export interface TrainingSessionModel {
    ID: number,
    TrainingId: number,
    Name: string,
    Desc: string,
    StartDateTimeUtc: Date,
    EndDateTimeUtc: Date,
    CustomerId?: string,
    ExternalLink: string,
    LastTimeToEnterUtc: Date,
    Educator: string,
    EducatorId: number,
    UseExternalLink: boolean,
    UrlToMeeting: string,
    AttendeeSpotLeft?: number | null
}

export interface UpdateTrainingSessionRequest {
    Id: number,
    StartTimeUtc?: Date,
    EndTimeUtc?: Date,
    EducatorId?: number,
}

export interface CreateTrainingSessionRequest {
    ID?: number,
    TrainingId: number,
    StartTimeUtc: Date,
    EndTimeUtc: Date,
    CustomerId?: string,
    ExternalLink?: string,
    Educator?: string,
    EducatorId?: number,
    LastTimeToEnterUtc?: Date,
}

export interface InviteToTrainingSessionRequest {
    Id: number,
    Email: string,
}

export class TrainingSessionApi {

    constructor(
        private apiService: IApiService,
    ) { }

    getTrainingSessions = async (
        {
            customerId = null,
            trainingId = null,
        } = {},
    ): Promise<TrainingSessionModel[]> => {
        const baseUrl = 'api/TrainingSession';
        const searchParams = new URLSearchParams();

        if (customerId) {
            searchParams.append('customerId', customerId);
        }
        if (trainingId) {
            searchParams.append('trainingId', trainingId);
        }

        const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        const url = `${baseUrl}${searchParamsString}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getTrainingSession = async (id: number): Promise<TrainingSessionModel> => {
        const url = `api/TrainingSession/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    createTrainingSession = (createTrainingSessionRequest: CreateTrainingSessionRequest) => {
        const url = 'api/TrainingSession';
        return this.apiService.callApi(url, 'POST', createTrainingSessionRequest);
    };

    deleteTrainingSession = (id: number) => {
        const url = `api/TrainingSession/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    editTrainingSession = (editTrainingSessionRequest: UpdateTrainingSessionRequest) => {
        const url = `api/TrainingSession/${editTrainingSessionRequest.Id}`;
        return this.apiService.callApi(url, 'PUT', editTrainingSessionRequest);
    };

    inviteToTrainingSession = (inviteToTrainingSessionRequest: InviteToTrainingSessionRequest) => {
        const url = `api/TrainingSession/${inviteToTrainingSessionRequest.Id}/Invite`;
        return this.apiService.callApi(url, 'POST', inviteToTrainingSessionRequest);
    }
}