import { FunctionComponent } from "react";
import { EventFlowBlockDto } from "../../../api/models";
import TitleWithControls from "./TitleWithControls";

interface CompleteButtonProps {
    eventFlowBlock: EventFlowBlockDto;
    onUpdate: (eventFlowBlock: EventFlowBlockDto) => void;
    onDelete: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickIncrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    onClickDecrementRanking: (eventFlowBlock: EventFlowBlockDto) => void;
    maxRanking: number;
}

const CompleteButton: FunctionComponent<CompleteButtonProps> = (
    {
        eventFlowBlock,
        onUpdate,
        onDelete,
        onClickDecrementRanking,
        onClickIncrementRanking,
        maxRanking
    }

) => {

    const content = <span>
        {eventFlowBlock.Title}
    </span>;

    return (<div className="m-spacing">
        <div className="drone-button-wrapper">
            <div className="drone-button-title">
                <TitleWithControls
                    content={content}
                    eventFlowBlock={eventFlowBlock}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onClickDecrementRanking={onClickDecrementRanking}
                    onClickIncrementRanking={onClickIncrementRanking}
                    maxRanking={maxRanking}
                />
            </div>
        </div>
    </div>);
}

export default CompleteButton;