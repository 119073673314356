import { FunctionComponent, useEffect, useState } from 'react';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import { createColumnGroup, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from '@murphy-frontend/web-core/components/ColumnGroupTable/Models';
import { getLargeColSizeBasedOnScreen } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { OnboardingFlowDTO } from '../api/models';

interface OnboardingFlowsTableProps {
    onboardingFlows: OnboardingFlowDTO[];
    onClickEditOnboardingFlow: (onboardingFlow: OnboardingFlowDTO) => void;
    currentScreenSize: string;
}

const OnboardingFlowsTable: FunctionComponent<OnboardingFlowsTableProps> = ({
    onboardingFlows,
    onClickEditOnboardingFlow,
    currentScreenSize,
}) => {
    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [maxColumns, setMaxColumns] = useState<number>(0);
    const [currentSortingColumn, setCurrentSortingColumn] = useState<string>('Name');
    const [sortedAscending, setSortedAscending] = useState<boolean>(true);

    useEffect(() => {
        const createOnboardingFlowColumnGroup = (onboardingFlowsArray: OnboardingFlowDTO[]) => {
            const OnboardingFlowNameColumns: ColumnGroupColumnCell[] = [
                {
                    columnKey: 'Name',
                    displayName: "Name",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
            ];

            const onboardingFlowNameRows: ColumnGroupRowCell[][] = onboardingFlowsArray.map((row) => [
                {
                    id: `name-${row.ID}`,
                    rowId: row.ID,
                    title: row.Name,
                    displayValue: (<div className='clickable' onClick={() => onClickEditOnboardingFlow(row)}>
                        {row.Name}
                    </div>
                    ),
                    columnKey: 'Name',
                },
            ]);

            const onboardingFlowNameColumnGroup = createColumnGroup(
                "ONBOARDING FLOWS",
                OnboardingFlowNameColumns,
                onboardingFlowNameRows
            );

            return onboardingFlowNameColumnGroup;
        };

        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        if (onboardingFlows) {
            const allColumnGroups = [];
            const onboardingFlowsCopy = [...onboardingFlows];

            if (currentSortingColumn) {
                onboardingFlowsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const onboardingFlowNameColGroup = createOnboardingFlowColumnGroup(onboardingFlowsCopy);
            allColumnGroups.push(onboardingFlowNameColGroup);

            setMaxColumns(onboardingFlowNameColGroup.columns.length);
            setColumnGroups(allColumnGroups);
        }
    }, [onboardingFlows, sortedAscending, currentSortingColumn, currentScreenSize, onClickEditOnboardingFlow]);

    return (
        <ColumnGroupTable
            maxColumns={maxColumns}
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
        />
    );
};

export default OnboardingFlowsTable;
