import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import MBSSelectedRecipientRow from "./MBSSelectedRecipientRow";
import { MBSRecipient } from "../models";
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from "../../../components/ColumnGroupTable/Models";
import ColumnGroupTable from "../../../components/ColumnGroupTable";
import { createColumnGroup, sortByKey } from "../../../components/ColumnGroupTable/TableUtils";

interface MBSSelectedRecipientsProps {
    recipients: MBSRecipient[],
    translations?: Record<string, string>,
    removeRecipient?: (recipient: MBSRecipient) => void,
    clickRecipient?: (recipient: MBSRecipient) => void,
}

const MBSSelectedRecipients: FunctionComponent<MBSSelectedRecipientsProps>
    = ({ recipients, translations, removeRecipient, clickRecipient }) => {
        const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
        const [currentSortingColumn, setCurrentSortingColumn] = useState<string>('name');
        const [sortedAscending, setSortedAscending] = useState<boolean>(true);

        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const createMainColumnGroup = (recipients: MBSRecipient[]) => {
            const nameColumn: ColumnGroupColumnCell[] =
                [
                    {
                        columnKey: 'name',
                        displayName: translations ? translations.name : 'Name',
                        isSortable: true,
                        size: 'column-extra-large',
                        onClickColumn: sortByColumn,
                    },
                ];

            const userNameRows: ColumnGroupRowCell[][] = recipients.map((row) => (
                [
                    {
                        id: `name-${row.id}`,
                        rowId: row.uniqueKey,
                        title: row.name,
                        displayValue: <MBSSelectedRecipientRow
                            recipient={row}
                            removeRecipient={() => removeRecipient(row)}
                            clickRecipient={() => clickRecipient(row)}
                        />,
                        columnKey: 'name',
                    }
                ]
            )
            );
            const userNameColumnGroup = createColumnGroup("", nameColumn, userNameRows);
            return userNameColumnGroup;
        };

        useEffect(() => {
            if (recipients) {
                const allColumnGroups = [];
                const recipientsCopy = [...recipients];

                if (currentSortingColumn) {
                    recipientsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
                }

                const mainColGroup = createMainColumnGroup(recipientsCopy);
                allColumnGroups.push(mainColGroup);
                setColumnGroups(allColumnGroups);
            }
        }, [recipients, sortedAscending, currentSortingColumn, translations]);

        return (
            <ColumnGroupTable
                columngroups={columnGroups}
                sortedByColumn={currentSortingColumn}
                isSortedAscending={sortedAscending}
                hidePageInputs={true}
                translations={translations}
            />
        );
    }

export default MBSSelectedRecipients;