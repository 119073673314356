import { FunctionComponent } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "@murphy-frontend/web-core/components/Input";
import Button from "@murphy-frontend/web-core/components/Button";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";

yup.addMethod(yup.string, "html", function () {
  return this.test({
    name: "html",
    exclusive: true,
    message: "Invalid text",
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    },
  });
});

const schema = yup.object().shape({
  Name: yup.string().required(),
  Description: yup.string().nullable().html(),
});

export interface RequirementLevelFormDto {
  Name: string;
  Description: string;
}

interface RequirementLevelFormProps {
  isLoading?: boolean;
  requirementLevel?: RequirementLevelFormDto;
  onSubmit: (data: RequirementLevelFormDto) => void;
  nameTranslationButton: React.ReactElement;
  descriptionTranslationButton: React.ReactElement;
}

const RequirementLevelForm: FunctionComponent<RequirementLevelFormProps> = ({
  isLoading,
  requirementLevel,
  onSubmit,
  nameTranslationButton,
  descriptionTranslationButton,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
    setValue,
  } = useForm<RequirementLevelFormDto>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useEffect(() => {
    if (requirementLevel) {
      reset(requirementLevel);
    }
  }, [requirementLevel, reset]);

  const onSubmitHandler = (data: RequirementLevelFormDto) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="row">
        <div className="column">
          <div className="form-section">
            <Input
              isControlled
              register={register}
              name="Name"
              isRequired
              title={nameTranslationButton}
              errorMessage={errors.Name?.message}
            />
            <Textarea
              isControlled
              rows={8}
              register={register}
              name="Description"
              title={descriptionTranslationButton}
              errorMessage={errors.Description?.message}
            />
            <div className="dashboardform-container-section">
              <div className="form-buttons save-button">
                <Button
                  isLoading={isLoading}
                  disabled={!isDirty || (errors && errors.length > 0)}
                  type="submit"
                  buttonStyle="btn--primary"
                  buttonSize="btn--medium"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RequirementLevelForm;
