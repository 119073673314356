import { useQuery } from '@tanstack/react-query';
import { ContactModel, ContactsApi, OptionSetModel } from './ContactsApi';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export function useGetContacts(customerId?: string, contactGroupId?: number) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const contactsApi = new ContactsApi(apiService);
  const queryKey = queryKeys.list({ customerId, contactGroupId });

  return useQuery({
    queryKey: queryKey,
    queryFn: () => contactsApi.getContacts(customerId, contactGroupId).then(({ data }) => data as ContactModel[]),
    enabled: !!customerId
  });
}

export function useGetCustomerContactOptionSets(customerId: string) {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const contactsApi = new ContactsApi(apiService);

  return useQuery({
    queryKey: queryKeys.optionSets(customerId),
    queryFn: () => contactsApi.getCustomerContactsOptionSets(customerId).then(({ data }) => data as OptionSetModel[]),
    enabled: !!customerId
  });
}