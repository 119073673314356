import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons/lib';
import {
    RiPencilFill, RiCheckFill, RiCloseFill, RiDeleteBin7Fill,
} from 'react-icons/ri';
import { getProductColorClassById } from '@murphy-frontend/common/common';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import TableCellActionDropDownButton from '@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton';
import { getColSizeBasedOnScreen, getColSizeBasedOnScreenLargeNames, getSmallColSizeBasedOnScreen } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import useWindowDimensions from '@murphy-frontend/web-core/hooks/useWindowDimensions';
import { createColumnGroup, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { Constant } from '@murphy-frontend/web-core/models/Constant';
import { CustomerProduct } from '../api/models';
import { ColumnGroup } from '@murphy-frontend/web-core/components/ColumnGroupTable/Models';

interface CustomerTableProps {
    customers: CustomerTableModel[];
    allProducts: Constant[];
    onClickEditCustomer: (id: string) => void;
    onClickDeleteCustomer: (id: string) => void;
}

export interface CustomerTableModel {
    id: string,
    name: string,
    allowedusers: number,
    activeusers: number,
    smssender: string,
    externalcontactsenabled: boolean,
    mfa: boolean,
    appmfa: boolean,
    publicsector: boolean,
    activecustomerproducts: CustomerProduct[],
    statustypeid: number,
    statustypename: string,
    customergroupname?: string,
}

const CustomerTable: React.FC<CustomerTableProps> = ({ customers, allProducts,
    onClickEditCustomer, onClickDeleteCustomer,
}) => {
    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [currentSortingColumn, setCurrentSortingColumn] = useState('name');
    const [sortedAscending, setSortedAscending] = useState(true);
    const { currentSize: currentScreenSize } = useWindowDimensions();

    const createCustomerProductsColumnGroup = (allCustomerProducts: Constant[], customersArray: CustomerTableModel[]) => {
        const customerProductColumns = allCustomerProducts.map((product) => ({
            columnKey: product.id,
            displayName: product.value,
            isSortable: false,
            size: getColSizeBasedOnScreen('sm'),
        }));

        const customerProductRows = customersArray.map((row) => {
            const customerProductIds = row.activecustomerproducts.map((cp) => cp.ProductId);
            return allCustomerProducts.map((product) => {
                const customerHasProduct = customerProductIds.includes(product.id) === true;
                return {
                    id: product.id,
                    rowId: row.id,
                    displayValue: customerHasProduct === true
                        ? (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCheckFill />
                            </IconContext.Provider>
                        )
                        : (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCloseFill />
                            </IconContext.Provider>
                        ),
                    centerContent: true,
                    customClass: customerHasProduct === true ? getProductColorClassById(product.id) : 'user-table-icon-unselected',
                    columnKey: product.id,
                };
            });
        });

        const customerProductsColGroup = createColumnGroup('PERMISSIONS', customerProductColumns, customerProductRows);
        return customerProductsColGroup;
    };

    useEffect(() => {

        const sortByColumn = (columnKey: any) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (customerRow: CustomerTableModel) => {
            const items = [];
            items.push(
                (
                    <li key="edit" onClick={() => onClickEditCustomer(customerRow.id)}>
                        <div className="vertical-aligner">
                            <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                                <RiPencilFill />
                            </IconContext.Provider>
                            <span>Edit</span>
                        </div>
                    </li>
                ),
            );

            items.push(
                (
                    <li key="delete" onClick={() => onClickDeleteCustomer(customerRow.id)}>
                        <div className="vertical-aligner">
                            <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                                <RiDeleteBin7Fill />
                            </IconContext.Provider>
                            <span>Delete</span>
                        </div>
                    </li>
                ),
            );

            return items;
        };

        const createMainColumnGroup = (customers: CustomerTableModel[]) => {
            const customerNameCols = [
                {
                    columnKey: 'name',
                    displayName: 'Name',
                    isSortable: true,
                    size: getColSizeBasedOnScreenLargeNames(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'customergroupname',
                    displayName: "Group",
                    isSortable: true,
                    size: getColSizeBasedOnScreenLargeNames(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
            ];
            const customerNameRows = customers.map((row) => ([
                {
                    columnKey: 'name',
                    id: row.id,
                    rowId: row.id,
                    displayValue:
                        <TableCellActionDropDownButton
                            onClickRow={() => onClickEditCustomer(row.id)}
                            displayText={row.name}
                            listItems={generateDropDownListItems(row)}
                            columnSize={getColSizeBasedOnScreenLargeNames(currentScreenSize)}
                        />,
                },
                {
                    columnKey: 'customergroupname',
                    id: `group-${row.id}`,
                    rowId: row.id,
                    displayValue: row.customergroupname,
                },
            ]));
            const mainDashboardColumnGroup = createColumnGroup('CUSTOMER', customerNameCols, customerNameRows);
            return mainDashboardColumnGroup;
        };

        const createCustomerInfoColumnGroup = (customersArray: CustomerTableModel[]) => {
            const customerInfoCols = [
                {
                    columnKey: 'mfa',
                    displayName: 'MFA',
                    isSortable: false,
                    size: getColSizeBasedOnScreen('sm'),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'appmfa',
                    displayName: 'MFA (App)',
                    isSortable: false,
                    size: getColSizeBasedOnScreen('sm'),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'publicsector',
                    displayName: 'Public Sector',
                    isSortable: false,
                    size: getColSizeBasedOnScreen('sm'),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'statustypeid',
                    displayName: 'StatusTyp',
                    isSortable: false,
                    size: getColSizeBasedOnScreenLargeNames(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'activeusers',
                    displayName: 'Users',
                    isSortable: false,
                    size: getColSizeBasedOnScreen('sm'),
                    onClickColumn: sortByColumn,
                },
            ];
            const customerInfoRows = customersArray.map((row) => ([
                {
                    columnKey: 'mfa',
                    id: `mfa-${row.id}`,
                    rowId: row.id,
                    displayValue: row.mfa === true
                        ? (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCheckFill />
                            </IconContext.Provider>
                        )
                        : (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCloseFill />
                            </IconContext.Provider>
                        ),
                    centerContent: true,
                },
                {
                    columnKey: 'appmfa',
                    id: `appmfa-${row.id}`,
                    rowId: row.id,
                    displayValue: row.appmfa === true
                        ? (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCheckFill />
                            </IconContext.Provider>
                        )
                        : (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCloseFill />
                            </IconContext.Provider>
                        ),
                    centerContent: true,
                },
                {
                    columnKey: 'publicsector',
                    id: `publicsector-${row.id}`,
                    rowId: row.id,
                    displayValue: row.publicsector === true
                        ? (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCheckFill />
                            </IconContext.Provider>
                        )
                        : (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCloseFill />
                            </IconContext.Provider>
                        ),
                    centerContent: true,
                },
                {
                    columnKey: 'statustypeid',
                    id: `statustypeid-${row.id}`,
                    rowId: row.id,
                    displayValue: row.statustypename,
                },
                {
                    columnKey: 'activeusers',
                    id: `activeusers-${row.id}`,
                    rowId: row.id,
                    displayValue: `${row.activeusers}/${row.allowedusers}`,
                    customClass: row.activeusers > row.allowedusers && 'background-light-red',
                },
            ]));

            const dashboardInfoColumnGroup = createColumnGroup('INFO', customerInfoCols, customerInfoRows);
            return dashboardInfoColumnGroup;
        };

        const createMBSSettingsColumnGroup = (customersArray: CustomerTableModel[]) => {
            const mbsSettingsCols = [
                {
                    columnKey: 'smssender',
                    displayName: 'Sender',
                    isSortable: false,
                    size: getColSizeBasedOnScreenLargeNames(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'externalcontactsenabled',
                    displayName: 'Contacts',
                    isSortable: false,
                    size: getSmallColSizeBasedOnScreen('xs'),
                    onClickColumn: sortByColumn,
                },
            ];
            const mbsSettingsRows = customersArray.map((row) => ([
                {
                    columnKey: 'smssender',
                    id: `smssender-${row.id}`,
                    rowId: row.id,
                    displayValue: row.smssender,
                },
                {
                    columnKey: 'externalcontactsenabled',
                    id: `externalcontactsenabled-${row.id}`,
                    rowId: row.id,
                    displayValue: row.externalcontactsenabled === true
                        ? (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCheckFill />
                            </IconContext.Provider>
                        )
                        : (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCloseFill />
                            </IconContext.Provider>
                        ),
                    centerContent: true,
                },
            ]));

            const MBSSettingsColumnGroup = createColumnGroup('MBS', mbsSettingsCols, mbsSettingsRows);
            return MBSSettingsColumnGroup;
        };

        if (customers && allProducts) {
            const allColumnGroups = [];
            const customersCopy = [...customers];

            if (currentSortingColumn) {
                customersCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const mainColGroup = createMainColumnGroup(customersCopy);
            const customerInfoGroup = createCustomerInfoColumnGroup(customersCopy);
            const customerProductsGroup = createCustomerProductsColumnGroup(allProducts, customersCopy);
            const mbsSettingsGroup = createMBSSettingsColumnGroup(customersCopy);

            allColumnGroups.push(mainColGroup);
            allColumnGroups.push(customerInfoGroup);
            allColumnGroups.push(customerProductsGroup);
            allColumnGroups.push(mbsSettingsGroup);

            setColumnGroups(allColumnGroups);
        }
    }, [customers, sortedAscending, currentSortingColumn, allProducts, currentScreenSize, onClickEditCustomer, onClickDeleteCustomer]);

    return (
        <ColumnGroupTable
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
        />
    );
}

export default CustomerTable;
