import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import UpsertEducator from '../components/Educator/UpsertEducator';

const AddEducator: FunctionComponent = () => {
    const navigate = useNavigate();

    const onClickBack = () => {
        navigate('/educators');
    };

    const onSuccessHandler = () => {
        navigate("/educators");
    };

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>New Educator</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <UpsertEducator onSuccess={onSuccessHandler} />
                </div>
            </section>
        </div>
    );
};

export default AddEducator;
