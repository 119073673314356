import { FunctionComponent, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@murphy-frontend/web-core/components/Button";
import { useForm } from "react-hook-form";
import Input from "@murphy-frontend/web-core/components/Input";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import DOMPurify from "dompurify";

yup.addMethod(yup.string, "html", function () {
  return this.test({
    name: "html",
    exclusive: true,
    message: "Invalid text",
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    },
  });
});

const schema = yup.object().shape({
  Name: yup.string().required(),
  Description: yup.string().nullable().html(),
});

interface BasicTypeRequirementFormProps {
  isLoading?: boolean;
  onSubmit: (data: BasicTypeRequirementFormDto) => void;
  requirement?: BasicTypeRequirementFormDto | undefined;
}

export interface BasicTypeRequirementFormDto {
  Name: string;
  Description: string;
}

const BasicTypeRequirementForm: FunctionComponent<
  BasicTypeRequirementFormProps
> = ({ isLoading, onSubmit, requirement }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
    setValue,
  } = useForm<BasicTypeRequirementFormDto>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useEffect(() => {
    if (requirement) {
      reset(requirement);
    }
  }, [requirement, reset]);

  const onSubmitHandler = (data: BasicTypeRequirementFormDto) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="row">
        <div className="column">
          <div className="form-section">
            <Input
              isControlled
              register={register}
              name="Name"
              isRequired
              title="Name"
              errorMessage={errors.Name?.message}
            />
            <Textarea
              isControlled
              rows={8}
              register={register}
              name="Description"
              isRequired
              title="Description"
              errorMessage={errors.Description?.message}
            />
            <div className="dashboardform-container-section">
              <div className="form-buttons save-button">
                <Button
                  isLoading={isLoading}
                  disabled={!isDirty || (errors && errors.length > 0)}
                  type="submit"
                  buttonStyle="btn--primary"
                  buttonSize="btn--medium"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BasicTypeRequirementForm;
