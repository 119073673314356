import { FunctionComponent } from 'react';
import { EducatorDto, UpdateEducatorDto } from '../../api/Educator/models';
import { useCreateEducator, useEditEducator } from '../../api/Educator/mutations';
import EducatorForm, { EducatorFormDto } from './EducatorForm';

interface UpsertEducatorProps {
    educator?: EducatorDto;
    onSuccess: () => void;
}

const UpsertCustomerGroup: FunctionComponent<UpsertEducatorProps> = ({
    educator,
    onSuccess,
}) => {
    const { mutate: createEducator, isPending: isCreating } = useCreateEducator();
    const { mutate: updateEducator, isPending: isUpdating } = useEditEducator();

    const onSubmitHandler = (educatorFormData: EducatorFormDto) => {
        if (educator) {
            const updatedEducator: UpdateEducatorDto = {
                ...educator,
                ...educatorFormData,
            };

            updateEducator(updatedEducator, {
                onSuccess: () => {
                    onSuccess();
                },
            });
        } else {
            createEducator(educatorFormData, {
                onSuccess: () => {
                    onSuccess();
                },
            });
        }
    };

    return (
        <div>
            <EducatorForm
                isLoading={isCreating || isUpdating}
                educator={educator}
                onSubmit={onSubmitHandler}
            />
        </div>
    );
};

export default UpsertCustomerGroup;
