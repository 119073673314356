import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export interface CustomerGroupModel {
    ID?: number,
    Name: string,
    CreatedDateTimeUtc?: string,
}

export class CustomerGroupsApi {
    apiService: IApiService;
    baseUrl: string = 'api/CustomerGroup';

    constructor(apiService: IApiService) {
        this.apiService = apiService;
    }

    getCustomerGroups = () => {
        return this.apiService.callApi(this.baseUrl, 'GET');
    };

    getCustomerGroup = (customeGroupId: number) => {
        const url = `${this.baseUrl}/${customeGroupId}`;
        return this.apiService.callApi(url, 'GET');
    };

    editContactGroup = (customerGroupModel: CustomerGroupModel) => {
        const url = `${this.baseUrl}/${customerGroupModel.ID}`;
        return this.apiService.callApi(url, 'PUT', customerGroupModel);
    };

    removeCustomerGroup = (id: number) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    createCustomerGroup = (customerGroupModel: CustomerGroupModel) => {
        return this.apiService.callApi(this.baseUrl, 'POST', customerGroupModel);
    };

}