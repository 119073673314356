import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import { getUtcDate } from '@murphy-frontend/common/services/TimeService';
import { useAdminUserPreferences } from '../../../contexts/AdminUserPreferencesContext';
import CourseSessionForm, { CourseSessionFormData } from '../components/CourseSessionForm';
import { useCreateTrainingSession } from '../api/TrainingSessions/mutations';
import { CreateTrainingSessionRequest } from '../api/TrainingSessions/TrainingSessionsApi';
import { useGetEducators } from '../api/Educator/queries';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { Constant } from '@murphy-frontend/web-core/models/Constant';

const AddCourseSession: FunctionComponent = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams();
  const trainingIdNumber = parseInt(trainingId, 10);

  const { timeZone } = useAdminUserPreferences();

  const { mutate: createTrainingSession, isPending: createTrainingSessionIsLoading } = useCreateTrainingSession();
  const { data: educators, isLoading: educatorsIsLoading } = useGetEducators();

  const onClickBack = () => {
    navigate(-1);
  };

  const onSubmitHandler = (createData: CourseSessionFormData) => {
    const createCourseSessionRequest: CreateTrainingSessionRequest = {
      TrainingId: trainingIdNumber,
      EducatorId: createData.educator,
    };

    if (createData.startdate) {
      createCourseSessionRequest.StartTimeUtc = getUtcDate(createData.startdate, timeZone);
    }
    if (createData.enddate) {
      createCourseSessionRequest.EndTimeUtc = getUtcDate(createData.enddate, timeZone);
    }
    if (createData.educator) {
      createCourseSessionRequest.Educator = createData.educator;
    }

    if (Object.keys(createCourseSessionRequest).length > 0) {
      createTrainingSession(createCourseSessionRequest, {
        onSuccess: (response) => {
          navigate(-1);
        },
      });
    }
  };

  if (educatorsIsLoading || !educators)
    return <div className="spinner-container-global-center"><Spinner /></div>;

  const mappedEducators: Constant[] = educators.map((educator) => {
    return {
      value: educator.Name,
      id: educator.Id,
    };
  }
  );

  return (
    <div className="generalwrapper padding-bottom-60">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            New course session
          </h4>
        </div>
        <div className="two">
          <div>
            <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
          </div>
        </div>
        <div className="three" />
      </section>
      <section className="container-with-background">
        <div className="trainingform-container">
          <div className="grid-item">
            <CourseSessionForm
              timeZone={timeZone}
              isSaveLoading={createTrainingSessionIsLoading}
              onSubmit={onSubmitHandler}
              allEducators={mappedEducators}
              trainingSessionHasBeenCompleted={false}
              hasAttendees={false} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default AddCourseSession;
