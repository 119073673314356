import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@murphy-frontend/web-core/components/Button';
import { TrainingTypes } from '@murphy-frontend/common/enums';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { useCreateTraining } from '../api/Training/mutations';
import CourseForm, { CourseFormData } from '../components/CourseForm';
import { CreateTrainingRequest } from '../api/Training/TrainingApi';
import { useGetCustomersBasic } from '../../CustomerAdmin/api/queries';
interface AddCourseProps {
}

const AddCourse: FunctionComponent<AddCourseProps> = () => {
  const navigate = useNavigate();

  const { mutate: createCourse, isPending: isLoadingCreateCourse } = useCreateTraining();

  const onClickBack = () => {
    navigate('/courseadmin');
  };

  const { data: customers, isLoading: isLoadingCustomers } = useGetCustomersBasic(true, true);

  const onSubmitHandler = (createData: CourseFormData) => {
    const createTrainingRequest: CreateTrainingRequest = {
      TrainingTypeId: TrainingTypes.Course,
      Name: createData.Name,
      Description: createData.Description,
      HtmlDescription: '',
      CustomerId: createData.CustomerId,
      MaxAttendees: createData.MaxAttendees,
    };

    if (createData.PublicSector) {
      createTrainingRequest.Sector = createData.PublicSector;
    }

    if (createData.HtmlDescription) {
      createTrainingRequest.HtmlDescription = createData.HtmlDescription;
    }
    if (createData.Language) {
      createTrainingRequest.Language = createData.Language;
    }

    if (Object.keys(createTrainingRequest).length > 0) {
      createCourse(createTrainingRequest, {
        onSuccess: (response) => {
          const url = `/courseadmin/courses/${response.data.Id}`;
          navigate(url);
        },
      });
    }
  };

  if (isLoadingCustomers) {
    return <div><Spinner /></div>;
  }

  return (
    <div className="generalwrapper padding-bottom-60">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            New course
          </h4>
        </div>
        <div className="two">
          <div>
            <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
          </div>
        </div>
        <div className="three" />
      </section>
      <div className="container-with-background">
        <div className="trainingform-container">
          <div className="grid-item">
            <CourseForm
              customers={customers}
              type='course'
              onSubmit={onSubmitHandler}
              isSaveLoading={isLoadingCreateCourse} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCourse;
