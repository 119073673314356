import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useQuery } from '@tanstack/react-query';
import { UserApi } from './UserApi';

export function useGetMe(enabled: boolean = true) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const userApi = new UserApi(apiService);

    return useQuery({
        queryKey: queryKeys.me(),
        queryFn: () => userApi.getMe(),
        enabled,
    });
}

export function useGetCustomerUsers(customerId: string | undefined) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const userApi = new UserApi(apiService);

    return useQuery({
        queryKey: queryKeys.list(customerId),

        queryFn: () => userApi.getUsers({
            includeTrainingInfo: true,
            customerId,
        }),
        enabled: !!customerId
    });
}

export function useGetAllUsers() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const userApi = new UserApi(apiService);

    return useQuery({
        queryKey: queryKeys.all,

        queryFn: () => userApi.getUsers({
            includeTrainingInfo: false,
        }),
    });
}

export function useGetUser(customerUserId: string) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const userApi = new UserApi(apiService);

    return useQuery({
        queryKey: queryKeys.detail(customerUserId),
        queryFn: () => userApi.getUser(customerUserId, true),
        enabled: !!customerUserId
    });
}