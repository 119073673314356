import { useState, useEffect, FunctionComponent } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import dayjs from 'dayjs';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import Button from '@murphy-frontend/web-core/components/Button';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';

import { products, roles } from '@murphy-frontend/web-core/constants';
import { CustomerUserTrainingSessionStates, TrainingTypes } from '@murphy-frontend/common/enums';
import { TimeZoneType, getConvertedDate } from '@murphy-frontend/common/services/TimeService'
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useAdminUserPreferences } from '../../contexts/AdminUserPreferencesContext';
import { useCustomer } from '../../contexts/CustomerContext';
import UsersList, { UserListCourseViewModel, UserListTrainingSessionViewModel, UserListViewModel } from '@murphy-frontend/web-core/features/UsersAdmin/components/UsersList';
import { useAddCallingUserToCustomerMutation, useDeleteUserMutation } from './api/mutations';
import { useGetTrainings } from '../CourseAdmin/api/Training/queries';
import { Constant } from '@murphy-frontend/web-core/models/Constant';
import { useGetCustomerUsers } from './api/queries';
import { CreateUserRequest, MurphyUserAdmin } from './api/models';
import { useUser } from '../../contexts/UserContext';

const UsersAdmin: FunctionComponent = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { timeZone, } = useAdminUserPreferences();
  const { currentSize } = useWindowDimensions();
  const { user } = useUser();

  const [filteredUsers, setFilteredUsers] = useState<UserListViewModel[]>([]);
  const [allCourses, setAllCourses] = useState<UserListCourseViewModel[]>([]);
  const [customerProducts, setCustomerProducts] = useState<Constant[]>([]);
  const [showAddMyself, setShowAddMyself] = useState<boolean>(false);

  const [currentSearchText, setCurrentSearchText] = useState('');

  const { customer } = useCustomer();
  const { mutate: mutateDel, isPending: deleteIsLoading } = useDeleteUserMutation();
  const { mutate: addCallingUserToCustomer, isPending: addCallingUserIsLoading } = useAddCallingUserToCustomerMutation();

  const {
    isLoading: isLoadingCourses, isError: isErrorCourses, data: dataCourses, error: errorCourses,
  } = useGetTrainings();

  const {
    isLoading: isLoadingUsers, isError: isErrorUsers, data: dataUsers, error: errorUsers,
  } = useGetCustomerUsers(customer?.Id);

  const mapUsers = (rawDataUsers: MurphyUserAdmin[], tz: TimeZoneType): UserListViewModel[] => rawDataUsers.map((rawUser) => {
    const mappedUserTrainingSessions = rawUser?.UserTrainingSessions?.map(
      (uts) => ({
        id: uts.TrainingId,
        date: getConvertedDate(dayjs.utc(uts.Date), tz),
        trainingTypeId: uts.TrainingTypeId,
        trainingSessionStateId: uts.TrainingSessionStateID,
      } as UserListTrainingSessionViewModel),
    );

    return {
      id: rawUser.CustomerUserId,
      username: rawUser.Username,
      email: rawUser.Email,
      role: roles.filter((r) => r.id === rawUser.Role)[0].value,
      mobile: rawUser.Phonenumber,
      deviceToken: rawUser.DeviceToken,
      permissions: rawUser.Permissions,
      completedCourses: mappedUserTrainingSessions
        ?.filter((row) => row.trainingTypeId === TrainingTypes.Course
          && (row.trainingSessionStateId === CustomerUserTrainingSessionStates.Completed))
        ?? [],
      bookedCourses: mappedUserTrainingSessions
        ?.filter((row) => row.trainingTypeId === TrainingTypes.Course
          && (row.trainingSessionStateId === CustomerUserTrainingSessionStates.Booked))
        ?? [],
      completedExcercises: mappedUserTrainingSessions
        ?.filter((row) => row.trainingTypeId === TrainingTypes.Excersise
          && (row.trainingSessionStateId === CustomerUserTrainingSessionStates.Completed))
        ?? [],
    } as UserListViewModel;
  });

  useEffect(() => {
    if (dataCourses && dataCourses.length > 0) {
      const mappedCourses: UserListCourseViewModel[] = dataCourses.filter((p) => p.TrainingTypeId === TrainingTypes.Course)
        .map((course) => {
          return {
            id: course.Id,
            name: course.Name,
            displayName: course.Name,
          } as UserListCourseViewModel
        });

      setAllCourses(mappedCourses);
    }
  }, [dataCourses]);

  useEffect(() => {
    if (customer?.ActiveProductIds) {
      const customerProducts = products.filter((p) => customer.ActiveProductIds.includes(p.id));
      setCustomerProducts(customerProducts);
    }
  }, [customer]);

  const DeleteConfirmationBody = ({ userRow }) => (
    <>
      <p>
        Delete
        {' '}
        <strong>{userRow.username}</strong>
        {' '}
        ?
      </p>
    </>
  );

  const onClickDeleteUser = (userRow: UserListViewModel) => {
    const modalConf: ModalConfiguration = {
      title: `Delete user ${userRow.username}`,
      body: <DeleteConfirmationBody userRow={userRow} />,
      hideButton: false,
      buttonText: "Delete",
      type: 'warning',

      okCallback: () => mutateDel(userRow.id),
    };

    openModal(modalConf);
  };

  const onClickEditUser = (userRow: UserListViewModel) => {
    const url = `/users/${userRow.id}`;
    navigate(url);
  };

  const onClickNewUser = () => {
    navigate('/usernew');
  };

  const onClickAddMyselfToCustomer = () => {
    addCallingUserToCustomer(customer?.Id);
  }

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  useEffect(() => {
    if (!isLoadingUsers && timeZone && dataUsers) {
      let newFilteredUsers = mapUsers(dataUsers, timeZone);

      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredUsers = newFilteredUsers.filter((p) => {
          const username = p.username ?? '';
          const email = p.email ?? '';
          return username.toUpperCase().startsWith(searchValue) || email.toUpperCase().startsWith(searchValue);
        });
      }

      setFilteredUsers(newFilteredUsers);
    }
  }, [currentSearchText, dataUsers, isLoadingUsers, timeZone]);

  useEffect(() => {
    if (!isLoadingUsers && user && dataUsers) {
      const customerContainsCurrentUser = dataUsers?.map(p => p.UserId).includes(user?.UserId);
      setShowAddMyself(!customerContainsCurrentUser);
    } else {
      setShowAddMyself(false);
    }
  }, [dataUsers, isLoadingUsers, user]);

  if (!customer) {
    return <div>Please select customer</div>
  }

  if (isLoadingCourses || isLoadingUsers || deleteIsLoading || addCallingUserIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            USER ADMIN
          </h4>
        </div>
        <div className="two">
          <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewUser}>
            <span className="btn-with-icon-text">New User</span></Button>
        </div>
        {showAddMyself && <div className="two">
          <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickAddMyselfToCustomer}>
            <span className="btn-with-icon-text">Add myself to customer</span></Button>
        </div>}
        <div className="three">
          <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder={"Search"} />
        </div>
      </section>
      <section className="user-table-container">
        <UsersList
          users={filteredUsers}
          allProducts={customerProducts}
          allCourses={allCourses}
          onClickEditUser={onClickEditUser}
          onClickDeleteUser={onClickDeleteUser}
          currentScreenSize={currentSize}
          isDeleteUserEnabled={true}
          isTrainingEnabled={true}
        />
      </section>
    </div>
  );
}

export default UsersAdmin;
