import { FunctionComponent } from "react";
import { AdminCreateRequirementBlockRequest, AdminRequirementBlockDto, AdminUpdateRequirementBlockRequest } from "../api/models";
import { useCreateRequirementBlock, useUpdateRequirementBlock } from "../api/mutations";
import RequirementBlockForm, { RequirementBlockFormDto } from "./RequirementBlockForm";
import {
    IoLanguage,
} from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';
import Button from "@murphy-frontend/web-core/components/Button";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import FieldLocalizationList from "../../LocalizationAdmin/components/FieldLocalizationList";

interface UpsertRequirementBlockProps {
    onSuccess: () => void,
    requirementBlock?: AdminRequirementBlockDto,
    requirementCategoryId: number,
    level: number,
}

const UpsertRequirementBlock: FunctionComponent<UpsertRequirementBlockProps> = (
    { onSuccess, requirementBlock, requirementCategoryId, level }
) => {
    const { mutate: createRequirementBlock, isPending: createIsLoading } = useCreateRequirementBlock();
    const { mutate: updateRequirementBlock, isPending: updateIsLoading } = useUpdateRequirementBlock(requirementCategoryId);
    const { openModal } = useModal();

    const onSubmitHandler = (dto: RequirementBlockFormDto) => {

        if (!requirementBlock) {
            const reqBlockRequest: AdminCreateRequirementBlockRequest = {
                RequirementCategoryId: requirementCategoryId,
                Name: dto.Name,
                Level: level,
            }

            createRequirementBlock(reqBlockRequest, {
                onSuccess: onSuccess
            });
        } else {
            const reqBlockRequest: AdminUpdateRequirementBlockRequest = {
                Id: requirementBlock.Id,
                Name: dto.Name,
            }

            updateRequirementBlock(reqBlockRequest, {
                onSuccess: onSuccess
            });
        }
    }

    const onClickNameTranslation = (e) => {
        e.preventDefault();
        if (!requirementBlock)
            return;

        const localizationList = <FieldLocalizationList
            fieldName={"Name"}
            entityName={"RequirementBlock"}
            entityId={requirementBlock.Id} />;

        const modalConf: ModalConfiguration = {
            title: 'Translations for Name',
            body: localizationList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const onClickDescriptionTranslation = (e) => {
        e.preventDefault();
        if (!requirementBlock)
            return;

        const localizationList = <FieldLocalizationList
            fieldName={"Description"}
            entityName={"RequirementBlock"}
            entityId={requirementBlock.Id}
            isHtml={true}
        />;

        const modalConf: ModalConfiguration = {
            title: 'Translations for Description',
            body: localizationList,
            buttonText: 'Ok',
            type: 'info',
            disableOverflow: true,
        };
        openModal(modalConf);
    }

    const nameTranslationButton =
        <div className="vertical-aligner btn-no-width gap-10">
            <div>Name</div>
            <Button isIconButton icon={
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoLanguage />
                </IconContext.Provider>
            } onClick={onClickNameTranslation}>
            </Button>
        </div>

    const descriptionTranslationButton =
        <div className="vertical-aligner btn-no-width gap-10">
            <div>Description</div>
            <Button isIconButton icon={
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoLanguage />
                </IconContext.Provider>
            } onClick={onClickDescriptionTranslation}>
            </Button>
        </div>


    const formDto = requirementBlock ? {
        Name: requirementBlock.Name,
        Description: requirementBlock.Description,
        Link: requirementBlock.Link
    } : undefined;

    return (<div>
        <RequirementBlockForm
            isLoading={createIsLoading || updateIsLoading}
            onSubmit={onSubmitHandler}
            requirementBlock={formDto}
            nameTranslationButton={nameTranslationButton}
            descriptionTranslationButton={descriptionTranslationButton} />
    </div>);
}

export default UpsertRequirementBlock;