const queryKeys = {
    all: ['requirements'],
    lists: () => [...queryKeys.all, 'list'],
    list: (filters: any) => [...queryKeys.lists(), { filters }],
    blocks: (requirementCategoryId: number) => [...queryKeys.all, 'blocks', { requirementCategoryId }],
    block: (blockId: number) => [...queryKeys.all, 'block', { blockId }],
    levels: () => [...queryKeys.all, 'levels'],
    level: (level: number) => [...queryKeys.levels(), { level }],
    detail: (requirementId: number) => [...queryKeys.all, 'detail', { requirementId }],
    customerFileTypeRequired: (requirementId: number) => [...queryKeys.all, 'customerFileTypeRequired', { requirementId }],
};

export default queryKeys;
