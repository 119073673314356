import { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@murphy-frontend/web-core/components/Button';
import { useForm } from 'react-hook-form';
import Input from "@murphy-frontend/web-core/components/Input";
import { Chapters, CourseModel, Lessons } from "../api/models";
import ControlledFormSelect, { OptionType } from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import MurphySelect from "@murphy-frontend/web-core/components/MurphySelect/MurphySelect";


const schema = yup.object().shape({
    ExtLessonId: yup.number().required(),
    ExtLessonUrl: yup.string().required()
});

export interface LocalizedLessonFormDto {
    ExtLessonId: number,
    ExtLessonUrl: string,
}

interface LessonsFormProps {
    courses: CourseModel[],
    selectedCourseChapters: Chapters[],
    selectedChapterLessons: Lessons[],
    setSelectedCourseId: (id: number) => void,
    setSelectedChapterId: (id: number) => void,
    selectedCourseId: number | null,
    selectedChapterId: number | null,
    currentLocalizedLesson?: LocalizedLessonFormDto;
    onSubmit: (candidate: LocalizedLessonFormDto) => void,
    isLoading?: boolean,
}

const LessonForm: FunctionComponent<LessonsFormProps> = (
    {
        currentLocalizedLesson,
        onSubmit,
        isLoading,
        courses,
        selectedCourseChapters,
        selectedChapterLessons,
        setSelectedCourseId,
        setSelectedChapterId,
        selectedCourseId,
        selectedChapterId,
    }
) => {

    const { reset, register, watch, control, setValue, handleSubmit, formState: { errors, isDirty } } = useForm<LocalizedLessonFormDto>({
        resolver: yupResolver(schema)
    });

    const watchLessonId = watch('ExtLessonId');
    // const [selectedCourseChapters, setSelectedCourseChapters] = useState<OptionType[]>([]);
    // const [selectedChapterLessons, setSelectedChapterLessons] = useState<OptionType[]>([]);

    const onSubmitHandler = (data: LocalizedLessonFormDto) => {
        onSubmit(data);
    };

    useEffect(() => {
        if (currentLocalizedLesson) {
            reset(currentLocalizedLesson);
        } else {
            reset({
                ExtLessonId: 0,
                ExtLessonUrl: '',
            });
        }
    }, [reset, currentLocalizedLesson, courses]);

    const courseOptions: OptionType[] = courses.map((p) => ({
        value: p.id,
        label: p.name,
    }));

    const onChangeCourse = (selectedOption: OptionType | null) => {
        if (!selectedOption) {
            return;
        }

        setSelectedCourseId(selectedOption.value as number);
    }

    const onChangeChapter = (selectedOption: OptionType | null) => {
        if (!selectedOption) {
            return;
        }

        setSelectedChapterId(selectedOption.value as number);
    }

    // useEffect(() => {
    //     if (selectedCourseId) {
    //         const courseChapters = courses.find(p => p.id === selectedCourseId)?.chapters;
    //         if (courseChapters) {
    //             setSelectedCourseChapters(courseChapters.map((p) => ({
    //                 value: p.Id,
    //                 label: p.Name,
    //             })));
    //         }
    //     }
    // }, [courses, selectedCourseId]);

    // useEffect(() => {
    //     if (selectedChapterId) {
    //         const chapterLessons = courses.find(p => p.id === selectedCourseId)?.chapters.find(c => c.Id === selectedChapterId)?.lessons;
    //         if (chapterLessons) {
    //             setSelectedChapterLessons(chapterLessons.map((p) => ({
    //                 value: p.Id,
    //                 label: p.Name,
    //             })));
    //         }
    //     }
    // }, [courses, selectedCourseId, selectedChapterId]);

    const courseChapters = selectedCourseChapters?.map((p) => ({
        value: p.Id,
        label: p.Name,
    }));

    const chapterLessons = selectedChapterLessons?.map((p) => ({
        value: p.Id,
        label: p.Name,
    }));

    useEffect(() => {
        if (watchLessonId && selectedChapterLessons) {
            const matchingLesson = selectedChapterLessons.find(l => l.Id === watchLessonId);
            if (matchingLesson) {
                setValue('ExtLessonUrl', matchingLesson.Url); // Resetting the value
            }
        }
    }, [selectedChapterLessons, setValue, watchLessonId]);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row">
                <div className="column">
                    <div className="form-section Lessons-form">
                        <MurphySelect
                            placeholder="Course"
                            options={courseOptions}
                            value={courseOptions.find(option => option.value === selectedCourseId) ?? courseOptions[0]}
                            onChange={onChangeCourse}
                        />
                        {courseChapters &&
                            <MurphySelect
                                placeholder="Chapter"
                                options={courseChapters}
                                value={courseChapters.find(option => option.value === selectedChapterId)}
                                onChange={onChangeChapter}
                            />
                        }
                        {
                            chapterLessons &&
                            <ControlledFormSelect
                                control={control}
                                title="Lesson"
                                name="ExtLessonId"
                                label="Lesson"
                                options={chapterLessons}
                                isMulti={false}
                            />
                        }
                        <Input
                            isControlled
                            register={register}
                            name="ExtLessonUrl"
                            isRequired
                            title="Lesson URL"
                            errorMessage={errors.ExtLessonUrl?.message}
                            disabled={true}
                        />
                        <Button
                            isLoading={isLoading}
                            disabled={Object.keys(errors).length > 0}
                            type="submit"
                            buttonStyle="btn--primary"
                            buttonSize="btn--medium"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default LessonForm;