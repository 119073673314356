import { FunctionComponent } from "react";
import { AdminRequirementBlockDto } from "../api/models";
import RequirementBlock from "./RequirementBlock";
import Button from "@murphy-frontend/web-core/components/Button";
import { useNavigate } from "react-router-dom";

interface RequirmentBlockRowProps {
    blocks: AdminRequirementBlockDto[];
    level: number;
    requirementCategoryId: number;
}

const RequirementBlockRow: FunctionComponent<RequirmentBlockRowProps> = ({ blocks, level, requirementCategoryId }) => {
    const navigate = useNavigate();

    const onClickAddRequirementBlock = () => {
        const url = `/requirements/blocks/new?requirementCategoryId=${requirementCategoryId}&level=${level}`
        navigate(url)
    }

    const onClickRequirementBlock = (block: AdminRequirementBlockDto) => {
        const url = `/requirements/blocks/${block.Id}`
        navigate(url)
    }

    return (<div className="requirement-block-row">
        {blocks && <div className="rows">
            {blocks.map((block) => (
                <RequirementBlock key={block.Id} block={block} onClick={onClickRequirementBlock} />
            ))}
        </div>}
        <div className="add">
            <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickAddRequirementBlock} />
        </div>


    </div>);
}

export default RequirementBlockRow;