import { FunctionComponent } from "react";
import { useCreateRequirement, useUpdateRequirement } from "../api/mutations";
import BasicTypeRequirementForm, { BasicTypeRequirementFormDto } from "./BasicTypeRequirementForm";
import { BaseCreateRequirementDTO, BaseRequirementDTO, BaseUpdateRequirementDTO, Discriminators } from "../api/models";

interface UpsertRequirementProps {
    onSuccess: () => void,
    requirementBlockId: number,
    requirement?: BaseRequirementDTO,
}

const UpsertBasicTypeRequirement: FunctionComponent<UpsertRequirementProps> = (
    { requirementBlockId, onSuccess, requirement }
) => {
    const { mutate: createRequirement, isPending: createIsLoading } = useCreateRequirement();
    const { mutate: updateRequirement, isPending: updateIsLoading } = useUpdateRequirement();

    const onSubmitHandler = (dto: BasicTypeRequirementFormDto) => {

        if (!requirement) {

            const reqBlockRequest: BaseCreateRequirementDTO = {
                RequirementBlockId: requirementBlockId,
                Description: dto.Description,
                Name: dto.Name,
                IsPeriodic: false,
                RequirementStandardId: 1,
                Discriminator: Discriminators.BasicTypeRequirement,
            }

            createRequirement(reqBlockRequest, {
                onSuccess: onSuccess
            });
        } else {
            const reqBlockRequest: BaseUpdateRequirementDTO = {
                Id: requirement.Id,
                Description: dto.Description,
                Name: dto.Name,
                Discriminator: Discriminators.BasicTypeRequirement,
            }
            updateRequirement(reqBlockRequest, {
                onSuccess: onSuccess
            });
        }
    }

    const formDto = requirement ? {
        Name: requirement.Name,
        Description: requirement.Description,
    } : undefined;

    return (<div>
        <BasicTypeRequirementForm
            isLoading={createIsLoading || updateIsLoading}
            onSubmit={onSubmitHandler}
            requirement={formDto}
        />
    </div>);
}

export default UpsertBasicTypeRequirement;