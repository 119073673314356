import React, {
  useMemo, useState, useCallback, useEffect, useRef,
} from 'react';
import {
  Editor,
  Transforms,
  createEditor,
  Descendant, Node,
  Element as SlateElement,
  Range,
  Text,
} from 'slate';
import {
  Editable, ReactEditor,
  Slate, withReact, useSlateStatic, useSlate, useSelected,
  useFocused,
} from 'slate-react';
import { withHistory } from 'slate-history';
import { css } from '@emotion/css';
import isUrl from 'is-url';
import { Button, Icon, Toolbar } from './components/components';
import { serialize } from './helpers/serializationHelpers';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n)
      && SlateElement.isElement(n)
      && LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      const selected = useSelected();
      return (
        <a
          {...attributes}
          href={element.url}
          className={
            selected
              ? css`
                  box-shadow: 0 0 0 3px #ddd;
                `
              : ''
          }
        >
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </a>
      );
    case 'youtube':
      return (
        <div {...attributes}>
          <iframe
            contentEditable={false}
            title="Youtube video"
            src={`https://www.youtube.com/embed/${element?.videoId}`}
            frameBorder="0"
          />
          {children}
        </div>
      );
    case 'vimeo':
      return (
        <div {...attributes}>
          <div contentEditable={false}>
            <div
              style={{
                padding: '75% 0 0 0',
                position: 'relative',
              }}
            >
              <iframe
                src={`https://player.vimeo.com/video/${element?.vidId}?title=0&byline=0&portrait=0`}
                frameBorder="0"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          </div>
          {children}
        </div>
      );
    case 'image':
      const sel = useSelected();
      const focused = useFocused();
      return (
        <div {...attributes}>
          {children}
          <img
            src={element.url}
            className={css`
              display: block;
              max-width: 100%;
              max-height: 20em;
              box-shadow: ${sel && focused ? '0 0 0 2px blue;' : 'none'};
            `}
          />
        </div>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  // if (leaf.quote) {
  //   children = <u>{children}</u>;
  // }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const AddLinkButton = () => {
  const editor = useSlate();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <Icon>link</Icon>
    </Button>
  );
};

const RemoveLinkButton = () => {
  const editor = useSlate();

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <Icon>link_off</Icon>
    </Button>
  );
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const wrapLink = (editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: any = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const withInlines = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => ['link', 'button'].includes(element.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={css`
      font-size: 0;
    `}
  >
    $
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

export function RichTextEditor({ contents, onChangeContents }) {
  // require('./rich-text-editor.module.scss'); // here
  const [value, setValue] = useState<Descendant[]>(contents);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  // const [editor] = useState(() => withInlines(withHistory(withReact(createEditor()))));
  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);
  const youtubeRegex = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(?:\S+)?$/;
  const vimeoRegex = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;

  const onChangeText = (val: Descendant[]) => {
    // Save the value to Local Storage.
    const content = val.map((node) => serialize(node)).join('');
    localStorage.setItem('content', content);
    setValue(val);
    if (onChangeContents) {
      onChangeContents(content);
    }
  };

  useEffect(() => {
    onChangeText(contents);
  }, []);

  return (
    <div>
      {/* <div> */}
      <Slate
        editor={editor}
        onChange={onChangeText}
        value={value}
      >
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          {/* <BlockButton format="block-quote" icon="format_quote" /> BUGGED, must be fixed before enabling */}
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <AddLinkButton />
          <RemoveLinkButton />
        </Toolbar>
        <Editable
          autoFocus
          // onPaste={(event) => {
          //   const pastedText = event.clipboardData?.getData('text')?.trim();
          //   const youtubeMatches = pastedText.match(youtubeRegex);
          //   const vimeoMatches = pastedText.match(vimeoRegex);
          //   if (youtubeMatches != null) {
          //     const [_, videoId] = youtubeMatches;
          //     event.preventDefault();
          //     Transforms.insertNodes(editor, [{
          //       type: 'youtube',
          //       videoId,
          //       children: [{
          //         text: '',
          //       }],
          //     }]);
          //   }
          //   if (vimeoMatches != null) {
          //     const vidId = vimeoMatches[4];
          //     event.preventDefault();
          //     Transforms.insertNodes(editor, [{
          //       type: 'vimeo',
          //       vidId,
          //       children: [{
          //         text: '',
          //       }],
          //     }]);
          //   }
          // }}
          renderLeaf={renderLeaf}
          renderElement={renderElement}
        />
      </Slate>
    </div>

  );
}

export default RichTextEditor;
