import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { AxiosResponse } from "axios";
import { LocalizationDTO, UpsertLocalizationDTO } from "./models";

export class LocalizationApi {
    constructor(
        private apiService: IApiService,
    ) { }

    getLocalizations = async (entityType: string, entityId: number): Promise<LocalizationDTO[]> => {
        const url = `api/Localization/${entityType}/${entityId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    upsertLocalization = (dto: UpsertLocalizationDTO): Promise<AxiosResponse<any, any>> => {
        const url = `api/Localization/upsert`;
        return this.apiService.callApi(url, 'POST', dto);
    }
}