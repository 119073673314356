export interface BaseRequirementDTO {
    Id: number;
    Name: string;
    Discriminator: string;
    Description: string;
    IsPeriodic: boolean;
    CronExpression?: string;
    RequirementStandardId: number;
    RequirementBlockId: number;
}

export enum Discriminators {
    CustomerFileTypeRequiredRequirement = "CustomerFileTypeRequiredRequirement",
    CustomerFileTypeReviewedRequirement = "CustomerFileTypeReviewedRequirement",
    BasicTypeRequirement = "BaseRequirement",
}

export interface CustomerFileTypeRequirementDTO extends BaseRequirementDTO {
    CustomerFileTypeId: number;
}

export type CustomerFileTypeRequiredRequirementDTO = CustomerFileTypeRequirementDTO

export type CustomerFileTypeReviewedRequirementDTO = CustomerFileTypeRequirementDTO

export function isCustomerFileTypeRequiredRequirement(dto: BaseRequirementDTO): dto is CustomerFileTypeRequiredRequirementDTO {
    return dto.Discriminator === "CustomerFileTypeRequiredRequirement";
}

export function isCustomerFileTypeReviewedRequirement(dto: BaseRequirementDTO): dto is CustomerFileTypeReviewedRequirementDTO {
    return dto.Discriminator === "CustomerFileTypeReviewedRequirement";
}

export interface BaseCreateRequirementDTO {
    Name: string;
    Description: string;
    IsPeriodic: boolean;
    CronExpression?: string;
    RequirementStandardId: number;
    RequirementBlockId: number;
    Discriminator: string;
}

export interface CreateCustomerFileTypeRequirementDTO extends BaseCreateRequirementDTO {
    CustomerFileTypeId: number;
}

export type CreateCustomerFileTypeRequiredRequirementDTO = CreateCustomerFileTypeRequirementDTO

export type CreateCustomerFileTypeReviewedRequirementDTO = CreateCustomerFileTypeRequirementDTO

export interface BaseUpdateRequirementDTO {
    Id: number;
    Name: string;
    Description: string;
    Discriminator: string;
}

export interface AdminRequirementBlockDto {
    Id: number;
    Name: string;
    Description: string;
    Level: number;
    Link: string;
    RequirementCategoryId: number;
}

export interface AdminCreateRequirementBlockRequest {
    Name: string;
    Level: number;
    RequirementCategoryId: number;
}

export interface AdminUpdateRequirementBlockRequest {
    Id: number;
    Name: string;
}

// public class RequirementLevelDTO
// {
//     public int Id { get; set; }
//     public int Level { get; set; }
//     public int MaxNumberOfBlocks { get; set; }
//     public string Name { get; set; }
//     public string Description { get; set; }
// }

export interface RequirementLevelDTO {
    Id: number;
    Level: number;
    MaxNumberOfBlocks: number;
    Name: string;
    Description: string;
}

// public class UpdateRequirementLevelDTO
// {
//     public int Level { get; set; }
//     public string Name { get; set; }
//     public string Description { get; set; }
// }

export interface UpdateRequirementLevelDTO {
    Level: number;
    Name: string;
    Description: string;
}