import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import UpsertEventFlowTemplate from '../components/UpsertEventFlowTemplate';
import Button from '@murphy-frontend/web-core/components/Button';
import { useCustomer } from '../../../contexts/CustomerContext';

const AddEventFlowTemplatePage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { customer } = useCustomer();

    const onClickBack = () => {
        navigate('/eventflowtemplates');
    };

    const onSuccessHandler = () => {
        navigate("/eventflowtemplates");
    };

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>New Template</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <UpsertEventFlowTemplate
                        onSuccess={onSuccessHandler}
                        customerId={customer?.Id} />
                </div>
            </section>
        </div>
    );
};

export default AddEventFlowTemplatePage;
