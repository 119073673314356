import { FunctionComponent, useState } from "react";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import Button from "@murphy-frontend/web-core/components/Button";
import { useGetEducators } from "../api/Educator/queries";
import { EducatorDto } from "../api/Educator/models";
import EducatorsTable from "../components/Educator/EducatorsTable";

interface EducatorsProps {

}

const Educators: FunctionComponent<EducatorsProps> = () => {
    const { data: educators, isLoading: educatorsIsLoading } = useGetEducators();
    const { currentSize } = useWindowDimensions();
    const navigate = useNavigate();
    // const { mutate: deleteCustomerGroup } = useDeleteCustomerGroup();

    const loadingSpinner = <div className="spinner-container-global-center"><Spinner /></div>;

    if (educatorsIsLoading) {
        return loadingSpinner;
    }

    const onClickNew = () => {
        navigate('/educators/new');
    };

    const onClickEdit = (educator: EducatorDto) => {
        const url = `/educators/${educator.Id}`;
        navigate(url);
    };

    const onClickDelete = (educator: EducatorDto) => {
        // deleteCustomerGroup(customerGroup.ID);
        navigate('/educators');
    };

    return (
        <div className="generalwrapper">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>
                        EDUCATORS
                    </h4>
                </div>
                <div className="two">
                    <Button
                        isIconButton
                        buttonStyle="btn--add"
                        buttonSize="btn-with-icon"
                        onClick={onClickNew}
                    >
                        <span className="btn-with-icon-text">
                            New Educator
                        </span>
                    </Button>
                </div>
            </section>
            <section className="user-table-container">
                <EducatorsTable
                    educators={educators ?? []}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    currentScreenSize={currentSize}
                />
            </section>
        </div>
    );
}

export default Educators;