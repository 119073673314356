import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import UpsertCustomerGroup from '../components/UpsertCustomerGroup';
import Button from '@murphy-frontend/web-core/components/Button';

const AddCustomerGroupPage: FunctionComponent = () => {
    const navigate = useNavigate();

    const onClickBack = () => {
        navigate('/customergroups');
    };

    const onSuccessHandler = () => {
        navigate("/customergroups");
    };

    return (
        <div className="generalwrapper padding-bottom-60">
            <section className="header-container vertical-aligner">
                <div className="one">
                    <h4>New Customer Group</h4>
                </div>
                <div className="two">
                    <div>
                        <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                    </div>
                </div>
                <div className="three" />
            </section>
            <section className="container-with-background">
                <div className="form-container">
                    <UpsertCustomerGroup onSuccess={onSuccessHandler} />
                </div>
            </section>
        </div>
    );
};

export default AddCustomerGroupPage;
